import { useNavigate } from "react-router-dom"
import CarTable from "../../assets/icons/carTable"
import HomeTable from "../../assets/icons/homeTable"
import ItemTable from "../../assets/icons/itemTable"
import JobTable from "../../assets/icons/jobTable"
import ServiceTable from "../../assets/icons/serviceTable"
import "./index.scss"
export const MScrollHeader = () => {
    const navigate = useNavigate()
    return <div>
        <div className="scroll-header-container">

            <div className="scroll-item">
                <div className="scroll-icon-container" onClick={() => navigate("/view-vehicle")}>
                    <CarTable />
                    <div> Vehicles</div>
                </div>
                <div className="vertical-line"></div>
            </div>
            <div className="scroll-item" onClick={() => navigate("/view-home")}>
                <div className="scroll-icon-container">
                    <HomeTable />
                    <div> Home</div>
                </div>
                <div className="vertical-line"></div>
            </div>
            <div className="scroll-item" onClick={() => navigate("/view-job")}>
                <div className="scroll-icon-container">
                    <JobTable />
                    <div> jobs</div>
                </div>
                <div className="vertical-line"></div>
            </div>
            <div className="scroll-item">
                <div className="scroll-icon-container" onClick={() => navigate("/view-service")}>
                    <ServiceTable />
                    <div> Services</div>
                </div>
                <div className="vertical-line"></div>
            </div>
            <div className="scroll-item" onClick={() => navigate("/view-item")}>
                <div className="scroll-icon-container">
                    <ItemTable />
                    <div>  Items</div>
                </div>
            </div>
        </div>
    </div>
}