import './index.scss'
import { MSettingsCard } from '../../MSettingsCard'

export const InActivePost = () => {
    return <div>
        <MSettingsCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' type='inActive' />
        <MSettingsCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' type='inActive' />
        <MSettingsCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' type='inActive' />
        <MSettingsCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' type='inActive' />
    </div>
}