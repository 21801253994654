import * as React from "react"
const AddPlus = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={23}
        height={24}
        fill="none"
        {...props}
    >
        <rect width={23} height={23} y={0.5} fill="#FEECEE" rx={11.5} />
        <path
            fill="#F56476"
            fillRule="evenodd"
            d="M12.251 8.092a.75.75 0 1 0-1.5 0v3.158h-3.16a.75.75 0 0 0 0 1.5h3.16v3.158a.75.75 0 0 0 1.5 0V12.75h3.157a.75.75 0 0 0 0-1.5H12.25V8.092Z"
            clipRule="evenodd"
        />
    </svg>
)
export default AddPlus
