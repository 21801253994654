import React, { forwardRef } from 'react';
import './index.scss'; // Import your SCSS file

const MTextArea = forwardRef(({ title, errorMessage, onChange, placeholder, value }, ref) => {
    return (
        <div className="description-input">
            <div className='select-title-position'>
                <div> {title} </div>
                <div className='select-title-required'>*</div>
            </div>
            <textarea
                id="description"
                className="description-input-textarea"
                onChange={onChange}
                placeholder={placeholder}
                ref={ref}
                value={value}
            />
            <div className='text-area-err-message-container'>
                <div className='message-container-form-text-area'>
                    <p className='error-message'>{errorMessage}</p>
                </div>
            </div>

        </div>
    );
});

export default MTextArea;
