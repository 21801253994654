import { createSlice } from '@reduxjs/toolkit';


// Define slice
const counterSlice = createSlice({
    name: 'counter',
    initialState: {
        count: 0,
        todos: [],
    },

    reducers: {
        increment(state) {
            state.count = state.count + 1;
        },
        decrement(state) {
            state.count -= 1;
        },
        addTodo(state, action) {
            state.todos.push(action.payload);
        },
        removeTodo(state, action) {
            state.todos = state.todos.filter(todo => todo.id !== action.payload);
        },
    },
});

// Export actions
export const { increment, decrement, addTodo, removeTodo } = counterSlice.actions;

// Export reducer
export default counterSlice.reducer;
