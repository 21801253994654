import * as React from "react"
const FirstState = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        {...props}
    >
        <rect width={20} height={20} fill="#F3F3F6" rx={5} />
    </svg>
)
export default FirstState
