import Website from "../../assets/icons/website"
import PhoneNumber from "../../assets/phoneNumber"
import Message from "../../assets/icons/message"
import { MContainer } from "../../components/MContainer"
import { MPersonalInfoTable } from "../../components/MPersonalInfoTable"
import { MPreviewAccountInfo } from "../../components/MPreviewAccountInfo"
import { MSlick } from "../../components/MSlick"
import "./index.scss"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import ImgWarning from "../../assets/icons/imgWarning"
import { loading } from "../../redux/authReducer"
import { Photos } from "../../redux/createPostReducer"
import { useState } from "react"
import { MLoader } from "../../components/MLoader"
import MSuccesModal from "../../components/MSuccesModal"
import { createJob } from "../../services/jobs"



export const PreviewJob = () => {

    const previewData = useSelector(state => state.createPost.previewData)
    console.log(previewData, "previewData")
    const [isSucces, setIsSucces] = useState(false)
    const dispatch = useDispatch()
    const photos = useSelector(state => state.createPost.photo)
    const navigate = useNavigate()


    const onSubmit = async () => {
        dispatch(loading(true))
        try {
            const data = new FormData();
            data.append('general_category_id', 27);
            data.append('category_id', +previewData.selectedItem?.id);
            data.append('subcategory_id', +previewData?.subcategory?.id);
            data.append('location_id', +previewData?.location?.id);
            data.append('title', previewData?.postTitle);
            data.append('description', previewData?.description);
            data.append('price', +previewData?.price);
            data.append('settings[phone]', previewData?.phoneNumber)
            data.append('custom_fields[responsibilities]', previewData?.responsibilities)
            data.append('custom_fields[qualifications]', previewData?.qualifications)
            data.append('custom_fields[email]', previewData?.emailAddress)
            data.append('options[1]', +previewData?.employment);
            data.append('options[2]', +previewData?.priceType ? previewData?.priceType : previewData?.defaultPriceType);
            data.append('options[3]', +previewData?.employment);
            data.append('options[4]', +previewData?.experience);
            data.append('options[5]', +previewData?.selectedjob);
            previewData?.secondPhoneNumber && data.append('custom_fields[secondPhone]', +previewData?.secondPhoneNumber)
            previewData?.socialMedia && data.append('custom_fields[socialMedia]', previewData?.socialMedia)
            previewData?.secondSocialMediaLink && data.append('custom_fields[secondSocialMedia]', previewData?.secondSocialMediaLink)
            for (let i = 0; i < photos.length; i++) {
                data.append('images[1]', photos[i]);
            }
            await createJob(data)
            setIsSucces(true)
            dispatch(Photos([]))
        }
        catch (error) {
            console.log(error)
            //  setErrorMessageType(error.response.data.errors)
        }
        finally {
            dispatch(loading(false))
        }
    }
    const closeModal = () => {
        setIsSucces(!isSucces)
        navigate('/create-post')
    }
    return (
        <MContainer footerShow={false}>
            <div className="preview-filter-container">
                <div className="preview-step">
                    <div className="preview-title">Preview</div>
                    <div className="post-step">Step 2/2</div>
                </div>
                <div className="preview-position">
                    <div>

                        {photos && photos?.length > 0 ? (
                            photos?.length > 1 ? (
                                <MSlick photos={photos} />
                            ) : (
                                <img
                                    src={photos[0] instanceof Blob ? URL.createObjectURL(photos[0]) : require('../../assets/images/emptyImg.png')}
                                    alt={photos[0]?.name ? `Uploaded ${photos[0].name}` : 'Uploaded'}
                                    className="only-one-img"
                                />
                            )
                        ) : (
                            <img src={require('../../assets/images/emptyImg.png')} alt="No  available" />
                        )}
                        {photos && photos?.length === 0 && <div className="img-warning-message-container">
                            <div className="img-wanrnig-icon"><ImgWarning /></div>
                            <div className="img-wanrnig-title">This is a default picture. If you want to use your own images,
                                please click "Back to Form" <br />and upload the photos you prefer.</div>
                        </div>}
                        <div className="preview-post-info-container">
                            <div className="preview-post-info-price"> $ {previewData && previewData['price']}</div>
                            <div className="preview-post-info-title">{previewData && previewData['postTitle']}</div>
                            <div className="preview-post-info-description">
                                {previewData && previewData['description']}
                            </div>
                            <>
                                <div className="job-description-title">Responsibilities</div>
                                <div className="preview-post-info-description">
                                    {previewData && previewData['responsibilities']}
                                </div>
                            </>
                            <>
                                <div className="job-description-title">Qualification</div>
                                <div className="preview-post-info-description">
                                    {previewData && previewData['qualifications']}
                                </div>
                            </>
                        </div>
                        <div className="detailed-information">
                            <div className="preview-detailed-title">Detailed Information</div>
                            <div className="preview-things-information">
                                <div className="preview-things-information-title">Location:</div>
                                <div className="preview-things-info">{previewData && previewData['location'].name}</div>
                            </div>
                            <div className="preview-things-information">
                                <div className="preview-things-information-title">Employment Type:</div>
                                <div className="preview-things-info">{previewData['employmentArray'].find(option => +option.id === +previewData['employment'])?.name}</div>
                            </div>
                            <div className="preview-things-information">
                                <div className="preview-things-information-title">Job Schedule:</div>
                                <div className="preview-things-info">{previewData['jobArray'].find(option => +option.id === +previewData['selectedjob'])?.name}</div>
                            </div>
                            <div className="preview-things-information">
                                <div className="preview-things-information-title">Required Experience:</div>
                                <div className="preview-things-info">{previewData['requiredExperienceArray'].find(option => +option.id === +previewData['experience'])?.name}</div>
                            </div>
                        </div>
                    </div>


                    <div className="personal-info-position">
                        <div>
                            <MPreviewAccountInfo />
                        </div>
                        <div>
                            <MPersonalInfoTable infoTitle={`+1 ${previewData && previewData['phoneNumber']}`} icon={<PhoneNumber />} />
                            {previewData && previewData['secondPhoneNumber']?.length > 0 && <MPersonalInfoTable infoTitle={`+1 ${previewData['secondPhoneNumber']}`} icon={<PhoneNumber />} />}
                            <MPersonalInfoTable infoTitle={previewData && previewData['emailAddress']} icon={<Message />} />
                            <MPersonalInfoTable infoTitle={previewData && previewData['socialMedia']} icon={<Website />} />
                            {previewData && previewData['secondSocialMediaLink']?.length > 0 && <MPersonalInfoTable infoTitle={previewData['secondSocialMediaLink']} icon={<Website />} />}
                        </div>

                    </div>
                </div>
                <div className='preview-buttons-pos'>
                    <button onClick={() => navigate('/create-post', { state: { path: 'Job' } })} type="submit" className="preview-create-button">{'<'} Back to Form</button>
                    <div className="loader-couple">
                        <MLoader size={50} color={'red'} />
                        <button type="submit" className="preview-back-to-form-button " onClick={onSubmit}>Create</button>
                    </div>
                    <MSuccesModal show={isSucces} onClose={closeModal} title={'the post has successfully created'} />
                </div>
            </div>
        </MContainer>
    )
}