import * as React from "react"
const Star = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="none"
        {...props}
    >
        <path
            fill="#FFA070"
            d="m8 13.4 3.88 2.455c.71.45 1.58-.215 1.393-1.057l-1.028-4.617 3.43-3.11c.627-.568.29-1.644-.532-1.713l-4.516-.4L8.86.593a.92.92 0 0 0-1.72 0L5.373 4.947.857 5.35C.035 5.417-.302 6.493.324 7.06l3.431 3.111-1.028 4.618c-.187.84.683 1.506 1.393 1.056L8 13.4Z"
        />
    </svg>
)
export default Star
