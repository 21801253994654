import * as React from "react"
const HomeTable = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={18}
    fill="none"
    {...props}
  >
    <path
      stroke="#32394A"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.3}
      d="M1.416 16.5h1.061m0 0h5.7m-5.7 0V8.654c0-.49 0-.735.062-.964.055-.202.145-.393.266-.566.138-.195.329-.358.71-.68l4.561-3.857c.71-.598 1.063-.897 1.462-1.011a1.957 1.957 0 0 1 1.078 0c.399.114.754.413 1.463 1.013l4.56 3.855c.382.323.573.485.71.68.121.174.211.365.266.566.062.229.062.474.062.964V16.5m-9.5 0h3.8m-3.8 0v-3.671c0-.487.2-.954.557-1.298.356-.345.84-.538 1.343-.538.504 0 .987.193 1.344.538.356.344.556.81.556 1.298V16.5m5.7 0h-5.7m5.7 0h.907"
    />
  </svg>
)
export default HomeTable