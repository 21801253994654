import React from 'react';
import chroma from 'chroma-js';
import './index.scss'

import Select from 'react-select';



export const MPreviewColor = ({ title = 'color', defaultValue, disabledstyle = false }) => {
  const dot = (color = 'transparent') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 15,
      width: 15,
    },
  });

  const colorStyles = {
    control: (styles) => ({
      ...styles, backgroundColor: 'white',
      width: disabledstyle ? "120px" : "80px", padding: "0px",
      marginTop: "0px", borderRadius: "0px",
      "&:hover": {
        borderColor: "white",
        color: "white"
      },
      border: "1px solid white",
      '@media (max-width: 1600px)': {
        width: "500px"
      },
      '@media (max-width: 1250px)': {
        width: "400px"
      },
    }),
    menu: (styles) => ({
      ...styles, backgroundColor: '#F3F3F6',
      display: "none",
      width: "600px", padding: "8px",
      marginTop: "35px", borderRadius: "10px",
      border: "1px solid #F3F3F6",
      '@media (max-width: 1600px)': {
        width: "500px"
      },
      '@media (max-width: 1250px)': {
        width: "400px"
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? data.color
            : isFocused
              ? color.alpha(0.1).css()
              : undefined,
        color: isDisabled
          ? '#ccc'
          : isSelected
            ? chroma.contrast(color, 'white') > 2
              ? 'white'
              : 'black'
            : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : color.alpha(0.3).css()
            : undefined,
        },
      };
    },
    input: (styles) => ({ ...styles, ...dot() }),
    placeholder: (styles) => ({ ...styles, ...dot('#ccc') }),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
  };
  return <div className='preview-color-container'>
    <div className='select-cover'> </div>
    <Select
      options={[]}
      styles={colorStyles}
      onChange={() => { }} // Pass selected value
      value={defaultValue}
      placeholder={'color'}
      components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
    //  defaultValue={{ value: 'red', label: 'Red', color: '#FF0000' }}
    />
  </div>
};