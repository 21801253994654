import api from "./api";

export const homeType = () => {
    return api.get('api/subcategories-from-category?category_id=191')
        .then(response => response.data?.data[0]?.optionTitles[0]?.options)
        .catch(error => {
            console.error('Error profile user:', error);
            throw error;
        });
};
export const homeCategory = (id) => {
    return api.get(`api/get-child-options?parent_id=${id}`)
        .then(response => response.data.data)
        .catch(error => {
            console.error('Error profile user:', error);
            throw error;
        });
};
export const allLocation = (id) => {
    return api.get(`api/locations`)
        .then(response => response.data.data)
        .catch(error => {
            console.error('Error profile user:', error);
            throw error;
        });
};

export const createHome = (data) => {
    return api.post(`api/posts`, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    },)
        .then(response => response.data.data)
        .catch(error => {
            console.error('Error profile user:', error);
            throw error;
        });
};