import { useCallback, useEffect, useState } from "react";
import { MContainer } from "../../components/MContainer";
import { MFilterSelect } from "../../components/MFilterSelect";
import { MTable } from "../../components/MTable";
import "./index.scss";
import { Vehicle } from "../../components/MSortTables/Vehicle";
import { Home } from "../../components/MSortTables/Home";
import { Service } from "../../components/MSortTables/Service";
import { Job } from "../../components/MSortTables/Job";
import { Item } from "../../components/MSortTables/Item";
import { useLocation } from "react-router-dom";
import { JobsCategory } from "../../services/jobs";
import { useSelector } from "react-redux";

export const CreatePost = () => {
    const [componentType, setComponentType] = useState(null);
    const previewData = useSelector(state => state.createPost.previewData)
    const [activeComponent, setActiveComponent] = useState(null);
    const [jobsCategory, setJobsCategory] = useState([]);
    const [openDropdown, setOpenDropdown] = useState(null); // State to track which dropdown is open
    const [selectedJobItem, setSelectedJobItem] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const { state } = useLocation();

    const fetchCategorys = useCallback(async () => {
        try {
            const response = await JobsCategory()
            setJobsCategory(response?.map((item) => ({
                name: item?.name,
                id: item?.id
            })))

        } catch (error) {
            console.error('Failed to fetch profile info:', error.response?.data?.message);
        }
    }, []);
    useEffect(() => {
        fetchCategorys();
    }, [fetchCategorys]);


    const handleButtonClick = useCallback((type) => {
        //    dispatch(PreviewDataHandler(null));
        setComponentType(type);
        setActiveComponent(type);
        setOpenDropdown(null); // Close dropdowns when switching components
    }, []);

    useEffect(() => {
        handleButtonClick(state?.path);
    }, [state, handleButtonClick]);

    const handleDropdownToggle = (dropdown) => {
        setOpenDropdown(prev => (prev === dropdown ? null : dropdown));
    };
    const renderComponent = () => {
        switch (componentType) {
            case 'Vehicle':
                return <Vehicle />;
            case 'Home':
                return <Home />;
            case 'Job':
                return <Job selectedItem={selectedJobItem} />;
            case 'Service':
                return <Service />;
            case 'Item':
                return <Item />;
            default:
                return null;
        }
    };

    return (
        <MContainer footerShow={false}>
            <div className={componentType ? "create-post-filter-container" : "create-post-filter-inActive-container"}>
                <div className="create-post-step">
                    <div className="create-post-title">Create a Post</div>
                    <div className="post-step">Step 1/2</div>
                </div>
                <div className="create-post-filter-container-title">Select Category</div>
                <div className="filter-position">
                    <MTable text={'Vehicle'} handleButtonClick={() => handleButtonClick('Vehicle')} isActive={activeComponent === 'Vehicle'} />
                    <MTable text={'Home'} handleButtonClick={() => handleButtonClick('Home')} isActive={activeComponent === 'Home'} />
                    <MFilterSelect
                        state={state}
                        selectedItem={selectedJobItem}
                        setSelectedItem={setSelectedJobItem}
                        text={'Jobs'}
                        handleButtonClick={() => handleButtonClick('Job')}
                        isActive={activeComponent === 'Job'}
                        items={jobsCategory}
                        isOpen={openDropdown === 'Jobs'}
                        onToggle={() => handleDropdownToggle('Jobs')}
                        setOpenDropdown={setOpenDropdown}
                        previewItem={previewData?.selectedItem}
                    />
                    <MFilterSelect
                        state={state}
                        selectedItem={selectedItem}
                        setSelectedItem={setSelectedItem}
                        text={'Services'}
                        handleButtonClick={() => handleButtonClick('Service')}
                        isActive={activeComponent === 'Service'}
                        items={jobsCategory}
                        isOpen={openDropdown === 'Services'}
                        onToggle={() => handleDropdownToggle('Services')}
                        previewItem={previewData?.selectedItem}
                    />
                    <MFilterSelect
                        selectedItem={selectedItem}
                        setSelectedItem={setSelectedItem}
                        text={'Items'}
                        handleButtonClick={() => handleButtonClick('Item')}
                        isActive={activeComponent === 'Item'}
                        items={jobsCategory}
                        isOpen={openDropdown === 'Items'}
                        onToggle={() => handleDropdownToggle('Items')}
                        state={state}
                        previewItem={previewData?.selectedItem}
                    />
                </div>
                {renderComponent()}
            </div>
        </MContainer>
    );
}
