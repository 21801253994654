import * as React from "react"
const Twitter = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={21}
    fill="none"
    {...props}
  >
    <path
      fill="#32394A"
      d="M26 2.47c-.957.433-1.988.717-3.057.853a5.316 5.316 0 0 0 2.336-2.94A10.78 10.78 0 0 1 21.9 1.68C20.917.618 19.537 0 17.97 0c-2.92 0-5.307 2.372-5.307 5.3 0 .42.05.827.137 1.21A15.186 15.186 0 0 1 1.815.976a5.219 5.219 0 0 0-.721 2.656c0 1.84.932 3.47 2.373 4.397-.882 0-1.702-.247-2.423-.617v.037c0 2.57 1.84 4.719 4.275 5.2a5.277 5.277 0 0 1-2.398.087 5.288 5.288 0 0 0 1.89 2.635 5.34 5.34 0 0 0 3.081 1.046 10.631 10.631 0 0 1-6.624 2.273c-.423 0-.845-.025-1.268-.074A15.16 15.16 0 0 0 8.178 21c9.793 0 15.175-8.079 15.175-15.083 0-.235 0-.457-.013-.692A10.616 10.616 0 0 0 26 2.471Z"
    />
  </svg>
)
export default Twitter
