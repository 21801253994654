import { useForm, Controller } from "react-hook-form"
import { schemaCompanyCreateAccount } from "../../../utils/validation"
import MCheckBox from "../../../components/MCheckBox"
import { useNavigate } from "react-router-dom"
import { yupResolver } from "@hookform/resolvers/yup"
import './index.scss'
import MFileUpload from "../../../components/MFileUpload"
import MAuthInput from "../../../components/MAuthInput"
import { useState } from "react"
import { loading } from "../../../redux/authReducer"
import { useDispatch } from "react-redux"
import { registerCompany } from "../../../services/user"
import MSuccesModal from "../../../components/MSuccesModal"
import { MLoader } from "../../../components/MLoader"

export const Company = () => {
    const [selectedFiles, setSelectedFiles] = useState([])
    const [isSucces, setIsSucces] = useState(false)
    const [errorMessageType, setErrorMessageType] = useState()
    const {
        handleSubmit,
        formState: { errors },
        control
    } = useForm({
        mode: "onChange", resolver: yupResolver(schemaCompanyCreateAccount)
    })
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const onSubmit = async (form) => {
        dispatch(loading(true))
        try {
            const data = new FormData();
            data.append('company_name', form.companyName);
            data.append('email', form.companyEmail);
            data.append('license_code', form.license)
            data.append('password', form.password);
            data.append('password_confirmation', form.confirmPassword)
            for (let i = 0; i < selectedFiles.length; i++) {
                data.append('licenses[1]', selectedFiles[i]);
            }
            await registerCompany(data)
            setIsSucces(true)
        }
        catch (error) {
            setErrorMessageType(error.response.data.errors)
        }
        finally {
            dispatch(loading(false))
        }
    }
    return <div>
        <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
                name="companyName"
                control={control}
                render={({ field }) => (
                    <>
                        <MAuthInput {...field} placeholder='Company Name' errorMessage={errors.companyName?.message} title='Company Name' />
                    </>
                )}
            />
            <Controller
                name="companyEmail"
                control={control}
                render={({ field }) => (
                    <>
                        <MAuthInput {...field} placeholder='Company E-mail' errorMessage={errorMessageType?.email && errorMessageType['email'] ? errorMessageType['email'][0] : errors.email?.message} title='Company E-mail' />
                    </>
                )}
            />
            <Controller
                name="license"
                control={control}
                render={({ field }) => (
                    <>
                        <MAuthInput {...field} placeholder='License Code' errorMessage={errorMessageType?.license_code && errorMessageType['license_code'] ? errorMessageType['license_code'][0] : errors.license?.message} title='License Code' />
                    </>
                )}
            />
            <MFileUpload selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} />
            <div className="file-upload-err-container">
                <p className='error-message'>{errorMessageType?.licenses && errorMessageType['licenses'] && errorMessageType['licenses'][0]}</p>
            </div>


            <div className="passwordContainer">
                <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                        <>
                            <MAuthInput {...field} isPassword={true} placeholder='Password' errorMessage={errorMessageType?.password && errorMessageType['password'] ? errorMessageType['password'][0] : errors.password?.message} title='Password' />
                        </>
                    )}
                />
                <Controller
                    name="confirmPassword"
                    control={control}
                    render={({ field }) => (
                        <>
                            <MAuthInput {...field} isPassword={true} placeholder='Confirm Password' errorMessage={errors.confirmPassword?.message} title='Confirm Password' />
                        </>
                    )}
                />
            </div>
            <div className="check-box-container mt-45">
                <div className="check-box-position">
                    <Controller
                        name="checkBox"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <MCheckBox checked={value} onChange={onChange} />
                        )}
                    />
                </div>
                <div className="terms-condition-container">
                    <div className="terms-text">I agree to the Terms & Conditions </div>
                    <div className="terms-condition" onClick={() => navigate('/')}>Terms & Conditions</div>
                </div>
            </div>
            <div className="login-loader-position">
                <MLoader size={50} color={'red'} />
            </div>
            <button type="submit" className="create-button">Create Account</button>
        </form>

        <div className="account-text-container-center">
            <p className="text-account">Already have an account  ?</p>
            <p className="create-text" onClick={() => navigate('/login')}>Login</p>
        </div>
        <MSuccesModal show={isSucces} onClose={() => setIsSucces(!isSucces)} title={'the company has successfully created'} />
    </div>
}
