import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.scss"; // Your SCSS file for custom styles

export const MSlick = ({ photos }) => {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <div className="slider-container">
            <Slider {...settings}>
                {photos?.map((item, index) => (<div>
                    <img src={URL.createObjectURL(item)} alt={`Uploaded ${item.name}`} />
                </div>))}
            </Slider>
        </div>
    );
};
