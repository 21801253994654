import * as React from "react"
const SelectArrow = ({ isOpen = false }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={15}
        height={9}
        fill="none"
    >
        <path
            stroke="#32394A"
            strokeLinecap="round"
            strokeWidth={1.8}
            d="m1 1 6.5 6L14 1"
            transform={isOpen ? "rotate(180 7.5 4.5)": "rotate(0 0 0)"}
        />
    </svg>
)
export default SelectArrow
