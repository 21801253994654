import React, { useState } from "react"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { schemaForgot } from "../../utils/validation"
import { useDispatch } from "react-redux"
import '../../components/MButton/index.scss'
import { useNavigate } from "react-router-dom"
import "./index.scss"
import { AuthContainer } from "../../components/MAuthContainer"
import Arrow from "../../assets/icons/arrow"
import MButton from "../../components/MButton"
import MAuthInput from "../../components/MAuthInput"
import { loading } from "../../redux/authReducer"
import MSuccesModal from "../../components/MSuccesModal"
import { MLoader } from "../../components/MLoader"
import { forgotPassword } from "../../services/user"



export const ForgotPassword = () => {
    const {
        handleSubmit,
        formState: { errors },
        control
    } = useForm({
        mode: "onChange", resolver: yupResolver(schemaForgot)
    })
    const [checkEmail, setCheckEmail] = useState(false)
    const [errorMessage, setErrorMessage] = useState()
    const [isSucces, setIsSucces] = useState(false)
    const [again, setAgain] = useState()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const onSubmit = async (data) => {
        dispatch(loading(true))
        try {
            await forgotPassword(data)
            setCheckEmail(true)
            setAgain(data)
        }
        catch (error) {
            setErrorMessage(error.response?.data?.message)

        }
        finally {
            dispatch(loading(false))
        }

    }
    const sendAgain = async () => {
        dispatch(loading(true))
        try {
            await forgotPassword(again)
            dispatch(loading(false))
            setIsSucces(true)

        }
        catch (error) {
            setErrorMessage(error.response?.data?.message)
        }
        finally {
            dispatch(loading(false))
        }
    }
    return (
        <AuthContainer>
            <div className="field-position-forgot">
                <div className="field-container">
                    {!checkEmail && <div className="back-button-container" onClick={() => navigate("/login")}>
                        <div className="arrow"><Arrow /></div>
                        <div className="back-button">Back</div>
                    </div>}
                    {checkEmail ? <>
                        <div className="forgot-text">Check Your E-mail</div>
                        <div className="forgot-text-info">We have sent a password recover instructions </div>
                        <div className="forgot-text-info">to your e-mail address.</div>
                    </> : <>
                        <div className="forgot-text">Forgot Password?</div>
                        <div className="forgot-text-info">Don’t warry, it happens sometimes. Enter your</div>
                        <div className="forgot-text-info">e-mail address and we send you instructions.</div>
                    </>}
                    {checkEmail ? <div className="again-button-container"><MButton handleClick={sendAgain} className="create-button button-position" text="Send Instructions Again" /></div> :
                        <form onSubmit={handleSubmit(onSubmit)} className="form-container">
                            <Controller
                                name="email"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <MAuthInput {...field} placeholder='E-mail Address' errorMessage={errorMessage ? errorMessage : errors.email?.message} title={'E-mail Address'} />
                                    </>
                                )}
                            />
                            <div className="login-loader-position">
                                <MLoader size={50} color={'red'} />
                            </div>
                            <button type="submit" className="create-button button-position" >Send Instructions</button>

                        </form>}
                    <MSuccesModal show={isSucces} onClose={() => setIsSucces(!isSucces)} title={'if nothing has changed, please contact our support team'} />
                    {checkEmail && <div className="resend-text-container">
                        <p className="link-text">Didn’t get a link?  </p>
                        <p className="resend-text" onClick={() => navigate('/reset-password')}>Resend</p>
                    </div>}
                </div>
            </div>
        </AuthContainer>

    )
}