import { Controller, useForm } from 'react-hook-form'
import { MColorSelect } from '../../MColorSelect'
import { MSelect } from '../../MSelect'
import * as yup from "yup"
import { MInput } from '../../MInput'
import { yupResolver } from "@hookform/resolvers/yup"
import './index.scss'
import { useNavigate } from 'react-router-dom'
import MTextArea from '../../MTextArea'
import PhotoUpload from '../../MPhotoUpload'
import { MCoupleInput } from '../../MCoupleInput'
import { MAddInput } from '../../MAddInput'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PreviewDataHandler, Photos } from '../../../redux/createPostReducer'
import { allLOcation, allModels, vehicleType } from '../../../services/vehicle'
import { phoneRegExp } from '../../../utils/validation'

export const Vehicle = () => {
    const [showNumber, setShowNumber] = useState(false)
    const [disabled, setDIsabled] = useState(false)
    const [location, setLocation] = useState([])
    const [exteriorColor, setExteriorColor] = useState([])
    const [conditions, setConditions] = useState([])
    const [interiorColor, setInteriorColor] = useState([])
    const [showLink, setShowLink] = useState(false)
    const [selectedValue, setSelectedValue] = useState();
    const [formValues, setFormValues] = useState({})
    const [photos, setPhotos] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [models, setModels] = useState([]);
    const [bodyStyles, setBodyStyles] = useState([]);
    const [headlights, setHeadlights] = useState([])
    const [sunroof, setSunroof] = useState([])
    const [materials, setMaterial] = useState([])
    const [wheelSize, setWheelSize] = useState([])
    const [make, setMake] = useState([])

    const profileInform = useSelector(state => state.auth.profileInfo)
    const fetchVehicle = useCallback(async () => {
        try {
            const response = await vehicleType()
            setVehicles(response)
            const locations = await allLOcation()

            setLocation(locations.map((item, index) => ({
                name: item.state,
                id: item.id
            })))
        } catch (error) {
            console.error('Failed to fetch profile info:', error.response?.data?.message);
        }
    }, []);

    const transformArray = (arr) => {
        return arr.map(item => {
            // Extract the relevant parts from the name string
            const nameString = item.name;

            // Extract the label
            const labelMatch = nameString.match(/label: '([^']+)'/);
            const label = labelMatch ? labelMatch[1] : '';

            // Extract the color
            const colorMatch = nameString.match(/color: '#([^']+)'/);
            const color = colorMatch ? `#${colorMatch[1]}` : '';

            return {
                value: item.id.toString(),
                label,
                color
            };
        });
    }
    const fetchMake = useCallback(async () => {
        try {
            const response = await allModels(+formValues.vehicleType?.id);

            if (response && response.length > 0) {
                const setters = [
                    setMake,
                    setBodyStyles,
                    setHeadlights,
                    setSunroof,
                    setExteriorColor,
                    setInteriorColor,
                    setMaterial,
                    setWheelSize,
                    setConditions
                ];
                response.forEach((item, index) => {
                    if (setters[index]) {
                        setters[index](item.options);
                    }
                });
                setDIsabled(true)
            } else {
                console.error('Unexpected response format:', response);
            }
        } catch (error) {
            console.error('Failed to fetch profile info:', error.response?.data?.message);
        }
    }, [formValues.vehicleType?.id]);

    const fetchModels = useCallback(async () => {
        try {
            const response = await allModels(+formValues.make?.id);
            setModels(response[0].options);
        } catch (error) {
            console.error('Failed to fetch profile info:', error.response?.data?.message);
        }
    }, [formValues.make?.id]);

    useEffect(() => {
        fetchVehicle();
        setValue('emailAddress', profileInform.email)
    }, []);

    useEffect(() => {
        formValues.make && fetchModels();
    }, [formValues.make, fetchModels]);

    useEffect(() => {
        formValues.vehicleType && fetchMake()
    }, [formValues.vehicleType, fetchMake])

    const navigate = useNavigate()
    const previewData = useSelector(state => state.createPost.previewData)
    const photo = useSelector(state => state.createPost.photo)
    const dispatch = useDispatch()
    const handleRadioChange = (event) => {
        setSelectedValue(event.target.value);
    };

    useEffect(() => {
        !previewData?.condition && setSelectedValue(conditions[0]?.id)
    }, [conditions, previewData])
    const getSchema = () => {
        return yup.object().shape({
            vehicleType: yup.object()
                .shape({
                    id: yup.number().required('field is required'),
                })
                .typeError('field is required') // Custom message for type error
                .required('field is required'),
            location: yup.object()
                .shape({
                    id: yup.number().required('field is required'),
                })
                .typeError('field is required') // Custom message for type error
                .required('field is required'),
            postTitle: yup.string().required('field is required'),
            price: yup.number().typeError('Price must be a valid number.').required('field is required'),
            year: yup.number().typeError('Price must be a valid number.').required('field is required'),
            make: yup.object()
                .shape({
                    id: yup.number().required('field is required'),
                })
                .typeError('field is required') // Custom message for type error
                .required('field is required'),
            model: yup.object()
                .shape({
                    id: yup.number().required('field is required'),
                })
                .typeError('field is required') // Custom message for type error
                .required('field is required'),
            mileage: yup.number().typeError('Price must be a valid number.').required('field is required'),
            bodyStyle: yup.object()
                .shape({
                    id: yup.number().required('field is required'),
                })
                .typeError('field is required') // Custom message for type error
                .required('field is required'),
            excellent: yup.boolean().oneOf([true], 'You must agree to the terms and conditions'),
            good: yup.boolean().oneOf([true], 'You must agree to the terms and conditions'),
            fair: yup.boolean().oneOf([true], 'You must agree to the terms and conditions'),
            poor: yup.boolean().oneOf([true], 'You must agree to the terms and conditions'),
            description: yup.string()
                .min(32, 'Description must be at least 32 characters long')
                .required('Description is required'),
            phoneNumber: yup.string()
                .required('field is required')
                .matches(phoneRegExp, 'Phone number is not valid'),
            secondPhoneNumber: showNumber ? yup.string()
                .required('field is required')
                .matches(phoneRegExp, 'Phone number is not valid') : yup.string(),
            socialMedia: profileInform.company_name ? yup.string() : yup.string(),
            emailAddress: yup.string().email('Invalid email format').max(50, 'Email must be at most 50 characters'),
            secondSocialMediaLink: showLink ? yup.string() : yup.string(),
        });
    };
    const {
        handleSubmit,
        formState: { errors },
        control,
        setValue,
        watch
    } = useForm({
        mode: "onChange", resolver: yupResolver(getSchema())
    })
    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            setFormValues(value);
        });
        return () => subscription.unsubscribe();
    }, [watch]);
    useEffect(() => {
        previewData && Object.keys(previewData).forEach(key => {
            setValue(key, previewData[key])
        });
        setPhotos(photo)
        setSelectedValue(previewData?.condition)
    }, [setValue, previewData, photo])
    const onSubmit = (data) => {
        dispatch(PreviewDataHandler({ ...data, condition: selectedValue, allCondition: conditions }))
        dispatch(Photos(photos))
        navigate('/preview-vehicle', { state: { photos: photos } })
    }

    return <div className='vehicle-container'>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className='general-information-container'>
                <div className='general-information-title-vehicle'> General Information</div>
                <div className='general-information-position'>
                    <div className='general-information-couple'>
                        <Controller
                            name="vehicleType" // Unique identifier for the input
                            control={control}
                            defaultValue="" // Initial value of the input
                            render={({ field: { onChange, value } }) => (
                                <MSelect title="Vehicle Type" value={value} onChange={onChange} errorMessage={errors.vehicleType?.message} items={vehicles} required={true} disabled={true} />
                            )}
                        />
                        <Controller
                            name="location" // Unique identifier for the input
                            control={control}
                            defaultValue="" // Initial value of the input
                            render={({ field: { onChange, value } }) => (
                                <MSelect title="location" value={value} onChange={onChange} errorMessage={errors.location?.message} items={location} required={true} disabled={true} />
                            )}
                        />
                    </div>
                    <div className='general-information-couple '>
                        <Controller
                            name="postTitle"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <MInput {...field} errorMessage={errors.postTitle?.message} title={'Post Title'} required={true} />
                                </>
                            )}
                        />
                        <Controller
                            name="price"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <MCoupleInput {...field} errorMessage={errors.price?.message} title={'Price'} currency={'$'} />
                                </>
                            )}
                        />
                    </div>
                </div>

            </div>
            <div className='general-information-container'>
                <div className='general-information-title'> Detailed Information</div>
                <div className='general-information-position'>
                    <div className='general-information-couple'>
                        <Controller
                            name="make" // Unique identifier for the input
                            control={control}
                            defaultValue="" // Initial value of the input
                            render={({ field: { onChange, value } }) => (
                                <MSelect title="Make" value={value} onChange={onChange} errorMessage={errors.make?.message} items={make} required={true} disabled={disabled} />
                            )}
                        />
                        <Controller
                            name="bodyStyle" // Unique identifier for the input
                            control={control}
                            defaultValue="" // Initial value of the input
                            render={({ field: { onChange, value } }) => (
                                <MSelect title="Body Style" value={value} onChange={onChange} errorMessage={errors.bodyStyle?.message} items={bodyStyles} required={true} disabled={disabled} />
                            )}
                        />
                        <Controller
                            name="year"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <MInput {...field} errorMessage={errors.year?.message} title={'Year'} required={true} />
                                </>
                            )}
                        />
                    </div>
                    <div className='general-information-couple'>
                        <Controller
                            name="model" // Unique identifier for the input
                            control={control}
                            defaultValue="" // Initial value of the input
                            render={({ field: { onChange, value } }) => (
                                <MSelect title="Model" value={value} onChange={onChange} errorMessage={errors.model?.message} items={models} required={true} disabled={formValues.make} />
                            )}
                        />

                        <Controller
                            name="mileage"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <MInput {...field} errorMessage={errors.mileage?.message} title={'Mileage'} required={true} />
                                </>
                            )}
                        />

                    </div>
                </div>

            </div>
            <div>
                {conditions.length > 0 && <div className='check-box-information-title'>
                    <div className='information-title-required-mode'>
                        <div>Condition</div>
                        <div className='select-title-required'>*</div>
                    </div>
                </div>}
                <div>
                    <div className='radio-group-pos'>
                        {conditions.map((item) =>
                            <div className="radio-custom">
                                <input
                                    type="radio"
                                    value={item.id}
                                    checked={+selectedValue === item.id}
                                    onChange={handleRadioChange}
                                    style={{ color: "red" }}
                                />
                                {item.name}
                            </div>)}
                    </div>
                </div>

            </div>
            <div className='general-information-container'>
                <div className='general-information-title-vehicle'> Exterior</div>
                <div className='general-information-position'>
                    <div className='general-information-couple'>
                        <Controller
                            name="color"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <MColorSelect
                                    options={transformArray(exteriorColor)}
                                    onChange={onChange}
                                    errorMessage={errors.color?.message}
                                    value={value}
                                    isDisabled={disabled}
                                />
                            )}
                        />
                        <Controller
                            name="headlights" // Unique identifier for the input
                            control={control}
                            defaultValue="" // Initial value of the input
                            render={({ field: { onChange, value } }) => (
                                <MSelect title={'Headlights'} value={value} onChange={onChange} errorMessage={errors.headlights?.message} items={headlights} disabled={formValues.make} />
                            )}
                        />
                    </div>
                    <div className='general-information-couple'>
                        <Controller
                            name="wheelSize" // Unique identifier for the input
                            control={control}
                            defaultValue="" // Initial value of the input
                            render={({ field: { onChange, value } }) => (
                                <MSelect title={'Wheel Size'} value={value} onChange={onChange} errorMessage={errors.wheelSize?.message} items={wheelSize} disabled={formValues.make} />
                            )}
                        />
                    </div>
                </div>

            </div>
            <div className='general-information-container'>
                <div className='general-information-title'> Interior</div>
                <div className='general-information-position'>
                    <div className='general-information-couple'>
                        <Controller
                            name="interiorColor"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <MColorSelect
                                    options={transformArray(interiorColor)}
                                    onChange={onChange}
                                    errorMessage={errors.color?.message}
                                    value={value}
                                    isDisabled={formValues.make}
                                />
                            )}
                        />

                        <Controller
                            name="sunroof" // Unique identifier for the input
                            control={control}
                            defaultValue="" // Initial value of the input
                            render={({ field: { onChange, value } }) => (
                                <MSelect title="Sunroof" value={value} onChange={onChange} errorMessage={errors.sunroof?.message} items={sunroof} disabled={formValues.make} />
                            )}
                        />
                    </div>
                    <div className='general-information-couple'>
                        <Controller
                            name="material" // Unique identifier for the input
                            control={control}
                            defaultValue="" // Initial value of the input
                            render={({ field: { onChange, value } }) => (
                                <MSelect title="Material" value={value} onChange={onChange} errorMessage={errors.material?.message} items={materials} disabled={formValues.make} />
                            )}
                        />
                    </div>
                </div>

            </div>
            <div className='general-information-container'>
                <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                        <div className='mt-30'>
                            <MTextArea  {...field}
                                placeholder={'Describe your business / service / specialist'} title={'Description'}
                                errorMessage={errors.description?.message}
                            />
                        </div>
                    )}
                />

            </div>
            <div className='general-information-container mb-50'>
                <PhotoUpload photos={photos} setPhotos={setPhotos} />
            </div>
            <div className='general-information-container'>
                <div className='general-information-title-vehicle'> Contact Information</div>
                <div className='general-information-position'>
                    <div className='general-information-couple'>
                        <Controller
                            name="phoneNumber"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <MCoupleInput {...field} errorMessage={errors.phoneNumber?.message} title={'Phone Number'} currency={'+1'} placeholder={'Phone Number'} />
                                </>
                            )}
                        />
                        {profileInform.company_name && <Controller
                            name="socialMedia"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <MCoupleInput {...field} errorMessage={errors.socialMedia?.message} title={'Websites & Social Networks'} placeholder={'Social media link'} icon={true} />
                                </>
                            )}
                        />}
                        <Controller
                            name="emailAddress"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <MInput {...field} errorMessage={errors.emailAddress?.message} title={'E-mail Address'} />
                                </>
                            )}
                        />
                    </div>
                    <div className='general-information-couple mt-3'>
                        <Controller
                            name="secondPhoneNumber"
                            control={control}
                            rules={{
                                required: showNumber ? 'this field is required' : false
                            }}
                            render={({ field }) => (
                                <>
                                    <MAddInput {...field} errorMessage={errors.secondPhoneNumber?.message} title={'Phone Number'} addTitle='Add new phone number' show={showNumber} setShow={setShowNumber} placeholder={'Phone Number'} currency={'+1'} />
                                </>
                            )}
                        />
                        {profileInform.company_name && <Controller
                            name="secondSocialMediaLink"
                            control={control}
                            rules={{
                                required: showLink ? 'this field is required' : false
                            }}
                            render={({ field }) => (
                                <>
                                    <MAddInput {...field} errorMessage={errors.secondSocialMediaLink?.message} title={'Websites & Social Networks'} addTitle='Add new link' placeholder={'Social media link'} show={showLink} setShow={setShowLink} icon={true} />
                                </>
                            )}
                        />}
                    </div>
                </div>

            </div>
            <div className='preview-button'>
                <button type="submit" className="button-pos-prev">Go to Preview {'>'}</button>
            </div>
        </form>
    </div>
}