import * as React from "react"
const Selected = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={12}
    fill="none"
    {...props}
  >
    <path
      stroke="#F56476"
      strokeLinecap="round"
      strokeWidth={2}
      d="M1 5.648 5.563 10 15 1"
    />
  </svg>
)
export default Selected
