import { createSlice } from '@reduxjs/toolkit';


// Define slice
const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        access_token: null,
        userId: null,
        timedOut: false,
        agentId: null,
        branchId: null,
        activeTab: false,
        loader: false,
        showTable: true,
        activePost: false,
        profileInfo: null,
        isAuth: false,
        logOut: false,
        showScrollHeader: false,
        admin: false,
        isUpdated:false
    },

    reducers: {
        login(state, action) {
            state.user = action.payload;
        },
        update(state, action) {
            state.isUpdated = action.payload;
        },
        adminAction(state, action) {
            state.admin = action.payload;
        }, Header(state, action) {
            state.showScrollHeader = action.payload;
        },
        logOutAction(state, action) {
            state.logOut = action.payload;
        },
        profileInformation(state, action) {
            state.profileInfo = action.payload;
        },
        isAuthAction(state, action) {
            state.isAuth = action.payload;
        },

        verifyUser(state, action) {
            // state.access_token = action.payload.access_token,
            //     state.userId = action.payload.userId,
            //     state.branchId = action.payload.branchId ? action.payload.branchId : action.state.branchId,
            //     state.timedOut = false
        },
        ChangeAccessToken(state, action) {
            state.access_token = action.payload
        },
        ActiveTab(state, action) {
            state.activeTab = action.payload
        },
        ActivePost(state, action) {
            state.activePost = action.payload
        },
        loading(state, action) {
            state.loader = action.payload
        },
        showNavigation(state, action) {
            state.showTable = action.payload
        },
    },
});

// Export actions
export const { login, verifyUser, ChangeAccessToken, ActiveTab, loading, showNavigation, ActivePost, profileInformation, isAuthAction, logOutAction, showHeader, adminAction,update } = authSlice.actions;

// Export reducer
export default authSlice.reducer;

