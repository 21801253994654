import { MSettingsCard } from '../../MSettingsCard'
import './index.scss'


export const ActivePost = () => {
    return <div>
        <MSettingsCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama'  type='active'/>
        <MSettingsCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama'  type='active' />
        <MSettingsCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' type='active' />
        <MSettingsCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' type='active' />
    </div>
}