import * as React from "react"
const Edit = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={45}
    height={44}
    fill="none"
    {...props}
  >
    <rect width={45} height={44} fill="#F3F3F6" rx={10} />
    <path
      fill="#32394A"
      d="M14.937 30a.982.982 0 0 1-.376-.075.934.934 0 0 1-.51-.522.82.82 0 0 1-.047-.36l.228-2.366L24.79 16.74l3.3 3.105-10.556 9.935-2.513.216a.96.96 0 0 1-.085.003Zm13.81-10.774L25.45 16.12l1.979-1.862a.938.938 0 0 1 .303-.191.983.983 0 0 1 1.017.19l1.978 1.863a.877.877 0 0 1 .203.285.835.835 0 0 1-.203.958l-1.977 1.862h-.001Z"
    />
  </svg>
)
export default Edit
