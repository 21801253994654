import Website from "../../assets/icons/website"
import PhoneNumber from "../../assets/phoneNumber"
import Message from "../../assets/icons/message"
import { MContainer } from "../../components/MContainer"
import { MPersonalInfoTable } from "../../components/MPersonalInfoTable"
import { MPreviewAccountInfo } from "../../components/MPreviewAccountInfo"
import { MSlick } from "../../components/MSlick"
import "./index.scss"
import { MPreviewColor } from "../../components/MPreviewColor"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import ImgWarning from "../../assets/icons/imgWarning"
import { loading } from "../../redux/authReducer"
import { createCampaign } from "../../services/vehicle"
import { MLoader } from "../../components/MLoader"
import MSuccesModal from "../../components/MSuccesModal"
import { useState } from "react"
import { Photos } from "../../redux/createPostReducer"



export const Preview = () => {
    const [isSucces, setIsSucces] = useState(false)
    const [errorMessage, setErrorMessageType] = useState('')
    console.log(errorMessage, "errorMessage")
    const previewData = useSelector(state => state.createPost.previewData)
    const dispatch = useDispatch()
    const photos = useSelector(state => state.createPost.photo)
    const navigate = useNavigate()
    const profileInform = useSelector(state => state.auth.profileInfo)
    const conditionName = previewData?.allCondition.filter((item) => +item.id === +previewData.condition)[0]?.name

    const onSubmit = async () => {
        dispatch(loading(true))
        try {
            const data = new FormData();
            data.append('general_category_id', 24);
            data.append('category_id', 190);
            data.append('subcategory_id', 897);
            data.append('price_type_id', "");
            data.append('location_id', +previewData?.location?.id);
            data.append('title', previewData?.postTitle);
            data.append('description', previewData?.description);
            data.append('price', +previewData?.price);
            data.append('settings[phone]', previewData?.phoneNumber)
            previewData?.wheelSize && data.append('options[0]', +previewData?.wheelSize?.id);
            previewData?.bodyStyle && data.append('options[1]', +previewData?.bodyStyle?.id)
            data.append('options[2]', +previewData.condition)
            previewData?.headlights && data.append('options[3]', +previewData?.headlights?.id)
            data.append('options[4]', + previewData?.make?.id)
            previewData?.color && data.append('options[5]', +previewData?.color?.value)
            previewData?.interiorColor && data.append('options[7]', +previewData?.interiorColor?.value)
            previewData?.year && data.append('custom_fields[year]', +previewData?.year)
            previewData?.secondPhoneNumber && data.append('custom_fields[secondPhone]', +previewData?.secondPhoneNumber)
            previewData?.socialMedia && data.append('custom_fields[socialMedia]', previewData?.socialMedia)
            previewData?.secondSocialMediaLink && data.append('custom_fields[secondSocialMedia]', previewData?.secondSocialMediaLink)
            previewData?.material && data.append('options[6]', +previewData?.material?.id)
            previewData?.emailAdress && data.append('custom_fields[email]', +previewData?.emailAdress)
            previewData?.mileage && data.append('custom_fields[mileage]', +previewData?.mileage)
            previewData?.model && data.append('options[8]', +previewData?.model?.id)
            previewData?.sunroof && data.append('options[9]', +previewData?.sunroof?.id)
            previewData?.vehicleType && data.append('options[10]', +previewData?.vehicleType?.id)
            previewData?.model && data.append('options[11]', +previewData?.model?.id)
            for (let i = 0; i < photos.length; i++) {
                data.append('images[1]', photos[i]);
            }
            await createCampaign(data)
            setIsSucces(true)
            dispatch(Photos([]))
        }
        catch (error) {
            console.log(error)
            setErrorMessageType(error.response.data.errors)
        }
        finally {
            dispatch(loading(false))
        }
    }

    const closeModal = () => {
        setIsSucces(!isSucces)
        navigate('/create-post')
    }
    return (
        <MContainer footerShow={false}>
            {previewData &&
                <div className="preview-filter-container">
                    <div className="preview-step">
                        <div className="preview-title">Preview</div>
                        <div className="post-step">Step 2/2</div>
                    </div>
                    <div className="preview-position">
                        <div>

                            {photos && photos.length > 0 ? (
                                photos.length > 1 ? (
                                    <MSlick photos={photos} />
                                ) : (
                                    <img
                                        src={photos[0] instanceof Blob ? URL.createObjectURL(photos[0]) : require('../../assets/images/emptyImg.png')}
                                        alt={photos[0]?.name ? `Uploaded ${photos[0].name}` : 'Uploaded'}
                                        className="only-one-img"
                                    />
                                )
                            ) : (
                                <img src={require('../../assets/images/emptyImg.png')} alt="No  available" />
                            )}
                            {photos?.length === 0 && <div className="img-warning-message-container">
                                <div className="img-wanrnig-icon"><ImgWarning /></div>
                                <div className="img-wanrnig-title">This is a default picture. If you want to use your own images,
                                    please click "Back to Form" <br />and upload the photos you prefer.</div>
                            </div>}
                            <div className="preview-post-info-container">
                                {previewData['price'] ? <div className="preview-post-info-price"> $ {previewData['price']}</div> :
                                    <div className="no-price-to-show"> No price to show</div>}
                                <div className="preview-post-info-title">{previewData && previewData['postTitle']}</div>
                                <div className="preview-post-info-description">
                                    {previewData && previewData['description']}
                                </div>
                            </div>
                            <div className="detailed-information">
                                <div className="preview-detailed-title">Detailed Information</div>
                                <div className="preview-things-information">
                                    <div className="preview-things-information-title">Make:</div>
                                    <div className="preview-things-info">{previewData && previewData['make'].name}</div>
                                </div>
                                <div className="preview-things-information">
                                    <div className="preview-things-information-title">Model:</div>
                                    <div className="preview-things-info">{previewData && previewData['model'].name}</div>
                                </div>
                                <div className="preview-things-information">
                                    <div className="preview-things-information-title">Body Type:</div>
                                    <div className="preview-things-info">{previewData && previewData['bodyStyle'].name}</div>
                                </div>
                                <div className="preview-things-information">
                                    <div className="preview-things-information-title">Year:</div>
                                    <div className="preview-things-info">{previewData && previewData['year']}</div>
                                </div>
                                <div className="preview-things-information">
                                    <div className="preview-things-information-title">Condition:</div>
                                    <div className="preview-things-info">{previewData && previewData['condition'] && conditionName}</div>
                                </div>
                            </div>
                            <div className="detailed-exterior-information">
                                <div className="preview-detailed-title">Exterior</div>
                                {previewData['color'] && <div className="preview-things-information">
                                    <div className="preview-things-information-title-color">Color:</div>
                                    <div className="preview-things-info-color">
                                        <MPreviewColor defaultValue={previewData && previewData['color']} disabledstyle={true} />
                                    </div>
                                </div>}
                                {previewData && previewData['wheelSize'] && <div className="preview-things-information">
                                    <div className="preview-things-information-title">Wheel Size:</div>
                                    <div className="preview-things-info">{previewData['wheelSize'].name}</div>
                                </div>}
                                {previewData && previewData['headlights'] &&
                                    <div className="preview-things-information">
                                        <div className="preview-things-information-title">Headlights:</div>
                                        <div className="preview-things-info">{previewData['headlights'].name}</div>
                                    </div>}
                            </div>
                            <div className="detailed-interior-information">
                                <div className="preview-detailed-title">Interior</div>
                                {previewData['interiorColor'] && <div className="preview-things-information">
                                    <div className="preview-things-information-title-color">Color:</div>
                                    <div className="preview-things-info-color">
                                        <MPreviewColor defaultValue={previewData && previewData['interiorColor']} disabledstyle={true} />
                                    </div>
                                </div>}
                                {previewData['material'] && <div className="preview-things-information">
                                    <div className="preview-things-information-title">Materials:</div>
                                    <div className="preview-things-info">{previewData && previewData['material'].name}</div>
                                </div>}
                                {previewData['sunroof'] && <div className="preview-things-information">
                                    <div className="preview-things-information-title">Sunroof:</div>
                                    <div className="preview-things-info">{previewData && previewData['sunroof'].name}</div>
                                </div>}
                            </div>
                        </div>


                        <div className="personal-info-position">
                            <div>
                                <MPreviewAccountInfo />
                            </div>
                            <div>
                                <MPersonalInfoTable infoTitle={`+1 ${previewData && previewData['phoneNumber']}`} icon={<PhoneNumber />} />
                                {previewData && previewData['secondPhoneNumber']?.length > 0 && <MPersonalInfoTable infoTitle={`+1 ${previewData['secondPhoneNumber']}`} icon={<PhoneNumber />} />}
                                {previewData['emailAddress'] && <MPersonalInfoTable infoTitle={previewData && previewData['emailAddress']} icon={<Message />} />}
                                {profileInform.company_name && previewData['socialMedia'] && <MPersonalInfoTable infoTitle={previewData && previewData['socialMedia']} icon={<Website />} />}
                                {previewData && previewData['secondSocialMediaLink']?.length > 0 && <MPersonalInfoTable infoTitle={previewData['secondSocialMediaLink']} icon={<Website />} />}
                            </div>

                        </div>
                    </div>
                    <div className='preview-buttons-pos'>
                        <button onClick={() => navigate('/create-post', { state: { path: 'Vehicle' } })} type="submit" className="preview-create-button">{'<'} Back to Form</button>
                        <div className="loader-couple">
                            <MLoader size={50} color={'red'} />
                            <button type="submit" className="preview-back-to-form-button " onClick={onSubmit}>Create</button>
                        </div>
                        <MSuccesModal show={isSucces} onClose={closeModal} title={'the post has successfully created'} />
                    </div>
                </div>}
        </MContainer>
    )
}