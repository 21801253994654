import * as React from "react"
const MyPost = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={13}
        height={13}
        fill="none"
        {...props}
    >
        <path
            fill="#32394A"
            fillRule="evenodd"
            d="M2 1h1.952a1 1 0 0 1 1 1v2.07a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1ZM0 2a2 2 0 0 1 2-2h1.952a2 2 0 0 1 2 2v2.07a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2Zm2 5.93h1.952a1 1 0 0 1 1 1V11a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V8.93a1 1 0 0 1 1-1Zm-2 1a2 2 0 0 1 2-2h1.952a2 2 0 0 1 2 2V11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8.93ZM11 1H9.048a1 1 0 0 0-1 1v2.07a1 1 0 0 0 1 1H11a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1ZM9.048 0a2 2 0 0 0-2 2v2.07a2 2 0 0 0 2 2H11a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H9.048Zm0 7.93H11a1 1 0 0 1 1 1V11a1 1 0 0 1-1 1H9.048a1 1 0 0 1-1-1V8.93a1 1 0 0 1 1-1Zm-2 1a2 2 0 0 1 2-2H11a2 2 0 0 1 2 2V11a2 2 0 0 1-2 2H9.048a2 2 0 0 1-2-2V8.93Z"
            clipRule="evenodd"
        />
    </svg>
)
export default MyPost
