import * as React from "react"
const RedArrow = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={8}
        height={11}
        fill="none"
        {...props}
    >
        <path
            stroke="#F56476"
            strokeLinecap="round"
            strokeWidth={1.5}
            d="M1.5 10 6 5.5 1.5 1"
        />
    </svg>
)
export default RedArrow
