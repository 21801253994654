import * as React from "react"
const Message = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={15}
        fill="none"
        {...props}
    >
        <rect
            width={18}
            height={13}
            x={1}
            y={1}
            stroke="#F56476"
            strokeLinejoin="round"
            strokeWidth={0.9}
            rx={2}
        />
        <path
            stroke="#F56476"
            strokeLinejoin="round"
            strokeWidth={0.9}
            d="m2 1.5 6.599 6.48a2 2 0 0 0 2.803 0L18 1.5M1.5 13l6-5.965M18.5 13.035l-6-6"
        />
    </svg>
)
export default Message
