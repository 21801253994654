import * as React from "react"
const ItemTable = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={21}
        fill="none"
        {...props}
    >
        <path
            stroke="#32394A"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.4}
            d="M4.887 6.9h10.226c1.279 0 2.344 1.23 2.557 2.46l1.278 7.38c.213 1.23-1.278 2.46-2.556 2.46H3.608c-1.278 0-2.77-1.23-2.556-2.46L2.33 9.36C2.543 8.13 3.608 6.9 4.887 6.9ZM6.55 8.267V5.533M13.45 5.533v2.734M1.722 15.1h16.557M13.45 5.533c0-1.366-.668-2.823-1.732-3.552-1.063-.73-2.373-.73-3.436 0-1.063.73-1.731 2.186-1.731 3.552"
        />
    </svg>
)
export default ItemTable
