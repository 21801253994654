import * as React from "react"
const ViewAllLetter = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={30}
        height={29}
        fill="none"
        {...props}
    >
        <rect width={30} height={29} fill="#FEECEE" rx={7} />
        <path
            fill="#F56476"
            fillRule="evenodd"
            d="M20.184 10.4H9.82l4.818 4.25a.55.55 0 0 0 .728 0l4.82-4.25Zm-11.114.54a.608.608 0 0 0-.003.06v6.958l3.521-3.915L9.07 10.94ZM20.3 18.6H9.7l3.563-3.962.78.688a1.45 1.45 0 0 0 1.918 0l.777-.687 3.563 3.961Zm.633-.643V11c0-.02 0-.039-.003-.058l-3.517 3.102 3.52 3.913ZM8.167 11a1.5 1.5 0 0 1 1.5-1.5h10.666a1.5 1.5 0 0 1 1.5 1.5v7a1.5 1.5 0 0 1-1.5 1.5H9.667a1.5 1.5 0 0 1-1.5-1.5v-7Z"
            clipRule="evenodd"
        />
    </svg>
)
export default ViewAllLetter
