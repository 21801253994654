import * as React from "react"
const SecondState = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        {...props}
    >
        <rect width={20} height={20} fill="#F56476" rx={5} />
        <path stroke="#fff" strokeLinecap="round" d="m5 10 3 3 7-7" />
    </svg>
)
export default SecondState
