import './index.scss'
import SecondState from '../../assets/icons/like/secondState';
import FirstState from '../../assets/icons/like/firstState';
import { useState } from 'react';
import Star from '../../assets/icons/star';
import ViewAllLetter from '../../assets/icons/viewAllLetter';
import ViewAllCall from '../../assets/icons/viewAllCall';
import Effect from '../../assets/icons/effect';
import Edit from '../../assets/edit';
import Deactivate from '../../assets/icons/deactivate';
import MModal from '../MModal';
import Activate from '../../assets/activate';

export const MSettingsCard = ({ imgName, price, region, title,type}) => {
    const [like, setLike] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const toggleModal = () => {
        setShowModal(!showModal);
      };
    
      const handleConfirm = () => {
        alert('Confirmed!');
        setShowModal(false);
      };
    return <div className='settings-card-container'>
        <div>
            <img src={require(`../../assets/images/${imgName}.png`)} className="settings-image" alt="fireSpot" />
        </div>
        <div className='settings-card-info-container'>
            <div className='settings-title-container'>
                <div className='settings-card-title'>{title}</div>
                {type === 'active'?<div className='settings-like-position'>
                    <div className='settings-card-active-posts'>
                        <div><Effect/></div>
                        <div><Edit/></div>
                        <div onClick={toggleModal}><Deactivate/></div>
                    </div>
                </div>:type === 'inReview'?<></>:type === 'inActive'?<div className='settings-like-position' onClick={toggleModal}>
                  <div><Activate/></div>
                </div>: type ==='Rejected'?<div className='settings-like-position'>
                <div><Edit/></div>
                </div>:<div className='settings-like-position'>
                    {like ? <div ><SecondState onClick={() => setLike(false)} /></div> : <div onClick={() => setLike(true)}><FirstState /></div>}
                </div>}
            </div>
            <div className='settings-card-region'>{region}</div>
            <div className='settings-card-view'>
                <div><Star /></div>
                <div>4,5</div>
                <div>(750)</div>
            </div>
            <div className='settings-contact'>
                <div className='settings-contact-couple-container'>
                    <div className='settings-contact-couple'>
                        <div><ViewAllLetter /></div>
                        <div className='settings-contact-couple-title'>alice.smith@volo.global</div>
                    </div>
                    <div className='settings-contact-couple mr-l-35'>
                        <div><ViewAllCall /></div>
                        <div className='settings-contact-couple-title'>+1 415 5552671</div>
                    </div>
                </div>

                <div className='settings-card-price'>$ {price}</div>
            </div>

        </div>
        <MModal show={showModal} onClose={toggleModal} title={type==="active"?"Are you sure you want to deactivate this post?":"Are you sure you want to activate this post?"} onConfirm={handleConfirm} buttonName={'Deactivate'}/>
    </div>
}