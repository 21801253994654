import React, { useState, forwardRef, useImperativeHandle, useEffect, useRef } from 'react';
import SelectArrow from '../../assets/icons/selectArrow';
import './index.scss';
import Selected from '../../assets/icons/selected';


export const MSelect = forwardRef(({ title, value, onChange, errorMessage, items, required, disabled }, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState()
  const [selectedItem, setSelectedItem] = useState(value || 'Select');
  const selectRef = useRef(null);
  const toggleDropdown = () => {
    disabled && setIsOpen(!isOpen);
  };
  useEffect(() => {
    // Update selectedItem when value changes
    if (value) {
      setSelectedItem(value);
      setName(value.name)
    }
  }, [value]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  const handleItemClick = (index, item) => {
    setName(item.name)
    setSelectedItem(index);
    setIsOpen(false); // Close the dropdown after selecting an item
    onChange(item); // Update the form field value
  };

  const truncateText = (text, maxLength) => {
    return text?.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
  };

  // Expose methods using useImperativeHandle
  useImperativeHandle(ref, () => ({
    toggleDropdown,
  }));

  return (
    <div className='select-size'  ref={selectRef}>
      <div className='select-title-position'>
        <div>{title}</div>
        {required && <div className='select-title-required'>*</div>}
      </div>
      <div className={`select-container`}>
        <div className='select-position' onClick={toggleDropdown}>
          <div className='selected-name-title'>
            {value ? <div className='select-name'>{name}</div> : <div className='select-name'>{selectedItem !== 'Select' ? truncateText('test', 18) : 'Select'}</div>}
          </div>
          <div className='select-arrow-style'>
            <SelectArrow isOpen={isOpen} />
          </div>
        </div>
      </div>
      <div className='message-select-form'>
        <p className='error-message'>{errorMessage}</p>
      </div>
      {isOpen && (
        <div className='selected-items'>
          {items?.map((item, index) => (
            <div key={index} className={`select-item ${selectedItem === index ? 'selected' : ''}`} onClick={() => handleItemClick(index, item)}>
              {item?.name}
              {selectedItem === index && <div className='select-choose-row'><Selected /></div>}
            </div>
          ))}
        </div>
      )}

    </div>
  );
});
