import React, { useState, forwardRef } from 'react';
import './index.scss'
import OpenEye from '../../assets/icons/openEye';
import ClosedEye from '../../assets/icons/closedEye';
import { useNavigate } from 'react-router-dom';

const MAuthInput = forwardRef(({ onChange, placeholder, forgot = false, errorMessage, isPassword = false, half = false, title }, ref) => {
    const [showPass, setShowPass] = useState(false)
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (e) => {
        const { value } = e.target;
        setInputValue(value);
        if (onChange) {
            onChange(e);
        }
    };
    const navigate = useNavigate()
    return (
        <div className='input-container'>
            <div className='input-position'>
                <div className='auth-input-container'>
                    {inputValue ? <div className='auth-input-title'>{title}</div> : <div className='auth-input-title-none'>.</div>}
                    <input
                        className={half ? 'half-input' : 'input'}
                        type={isPassword && !showPass ? 'password' : 'text'}
                        onChange={handleInputChange}
                        placeholder={placeholder}
                        ref={ref} // Forward the ref here
                    />
                    {isPassword && <div className='icon-position' onClick={() => setShowPass(!showPass)}>
                        {showPass ? <ClosedEye /> : <OpenEye />}
                    </div>}
                </div>
                <div className='message-container'>
                    <p className='error-message'>{errorMessage}</p>
                    {forgot && <p className='forgot-pass' onClick={() => navigate('/forgot-password')}>Forgot Password ?</p>}
                </div>
            </div>

        </div>
    );
});

export default MAuthInput;
