import { useSelector } from 'react-redux'
import Star from '../../assets/icons/star'
import './index.scss'
export const MPreviewAccountInfo = ({ imgPath }) => {
    const profileInfo = useSelector(state => state.auth.profileInfo)
    return <div className='preview-account-info-container'>
        {/* <div>
            {imgPath ? <> IMG</> : <div className="preview-account-circle">
                <div>AC</div>
            </div>}
        </div> */}
        {profileInfo?.first_name ? <div className="preview-account-circle">
            <div>{profileInfo?.first_name[0]} {profileInfo?.last_name[0]}</div>
        </div> : <div className="preview-account-circle">
            <div>{profileInfo?.company_name[0]}</div>
        </div>}
        {/* <div className="preview-account-name">Alice Hayrapetyan</div> */}
        <div className="profile-settings-info">
            {profileInfo?.first_name ? <div className="preview-account-name">{profileInfo?.first_name} {profileInfo?.last_name}</div> : <div className="preview-account-name">{profileInfo?.company_name}</div>}
        </div>
        <div className="preview-account-rating">
            <div><Star /></div>
            <div className='preview-account-rating-number'>{profileInfo.rate ? profileInfo.rate : "x(xxx)"}</div>
        </div>
        <div><button className='preview-account-button'>Rate</button></div>
    </div>
}