import React from 'react';
import './App.scss';
import Navigation from './routes';
import { store } from './redux';
import { Provider } from 'react-redux';

function App() {
  return (
    <div className='font-family'>
      <Provider store={store}>
        <Navigation />
      </Provider>
    </div>
  );
}

export default App;
