import * as React from "react"
const Youtube = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={17}
    fill="none"
    {...props}
  >
    <path
      fill="#32394A"
      d="M10 12.143 16.488 8.5 10 4.857v7.286Zm14.45-9.508c.163.57.275 1.336.35 2.307.087.972.125 1.81.125 2.538L25 8.5c0 2.66-.2 4.614-.55 5.865-.313 1.093-1.037 1.797-2.162 2.1-.588.159-1.663.268-3.313.34a85.754 85.754 0 0 1-4.488.122L12.5 17c-5.237 0-8.5-.194-9.787-.534-1.126-.304-1.85-1.008-2.163-2.101-.163-.57-.275-1.336-.35-2.307A28.39 28.39 0 0 1 .075 9.52L0 8.5c0-2.66.2-4.614.55-5.865C.863 1.542 1.587.838 2.712.535 3.3.375 4.375.266 6.025.194a85.756 85.756 0 0 1 4.487-.122L12.5 0c5.238 0 8.5.194 9.788.534 1.125.304 1.85 1.008 2.162 2.101Z"
    />
  </svg>
)
export default Youtube
