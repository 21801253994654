import * as React from "react"
const Facebook = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13}
    height={25}
    fill="none"
    {...props}
  >
    <path
      fill="#32394A"
      d="M8.667 14.375h3.095l1.238-5H8.667v-2.5c0-1.287 0-2.5 2.476-2.5H13v-4.2C12.596.121 11.072 0 9.463 0 6.1 0 3.714 2.071 3.714 5.875v3.5H0v5h3.714V25h4.953V14.375Z"
    />
  </svg>
)
export default Facebook
