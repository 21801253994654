import "./index.scss"
import * as yup from "yup"
import { MInput } from '../../MInput'
import { yupResolver } from "@hookform/resolvers/yup"
import { Controller, useForm } from "react-hook-form"
import { useEffect, useState } from "react"
import MFileUpload from "../../MFileUpload"
import { useDispatch, useSelector } from "react-redux"
import { loading, profileInformation } from "../../../redux/authReducer"
import { MLoader } from "../../MLoader"
import MSuccesModal from "../../MSuccesModal"
import { updatedCompanyInfo, updatedPersonalInfo, profileInfo } from "../../../services/user"

export const ProfileSetting = () => {
    const [selectedFiles, setSelectedFiles] = useState([])
    const [errorMessageType, setErrorMessageType] = useState()
    console.log(errorMessageType, "errorMessageType")
    const [isSucces, setIsSucces] = useState(false)
    const profileInform = useSelector(state => state.auth.profileInfo)
    const dispatch = useDispatch()
    const getSchemaCompany = () => {
        return yup.object().shape({
            company_name: yup.string().required('field is required'),
            license_code: yup.number().required('field is required'),
            email: yup.string().required('Email field is required').email('Invalid email format').max(50, 'Email must be at most 50 characters'),
        });
    };
    const getSchemaIndividual = () => {
        return yup.object().shape({
            first_name: yup.string().required('field is required'),
            last_name: yup.string().required('field is required'),
            email: yup.string().required('Email field is required').email('Invalid email format').max(50, 'Email must be at most 50 characters'),
        });
    };
    const {
        handleSubmit,
        formState: { errors },
        control,
        setValue
    } = useForm({
        mode: "onChange", resolver: yupResolver(profileInform.company_name ? getSchemaCompany() : getSchemaIndividual())
    })
    useEffect(() => {
        profileInform && Object.keys(profileInform).forEach(key => {
            setValue(key, profileInform[key]);
        });
        profileInform.company_name && setSelectedFiles(profileInform.licenses)
    }, [setValue, profileInform])
    const onSubmitCompany = async (form) => {
        dispatch(loading(true))
        try {
            const data = new FormData();
            data.append('company_name', form.company_name);
            data.append('email', form.email);
            data.append('license_code', form.license_code)
            for (let i = 0; i < selectedFiles.length; i++) {
                data.append('licenses[0]', selectedFiles[i]);
            }
            const updatedData = await updatedCompanyInfo(data)
            const infoResponse = updatedData && await profileInfo();
            dispatch(profileInformation(infoResponse.data));
            setIsSucces(true)

        }
        catch (error) {
            setErrorMessageType(error.response.data.errors)
        }
        finally {
            dispatch(loading(false))
        }
    }
    const onSubmitIndividual = async (form) => {
        const data = {
            email: form.email,
            first_name: form.first_name,
            last_name: form.last_name
        }
        dispatch(loading(true))
        try {
            const updatedData = await updatedPersonalInfo(data)
            const infoResponse = updatedData && await profileInfo();
            dispatch(profileInformation(infoResponse.data));
            setIsSucces(true)
        }
        catch (error) {
            setErrorMessageType(error.response.data.errors)
        }
        finally {
            dispatch(loading(false))
        }
    }
    return <div className="profile-settings-container">
        <div className="profile-settings-container-pos">
            <div className="profile-title-info">Edit General Information</div>
            <form onSubmit={handleSubmit(profileInform.company_name ? onSubmitCompany : onSubmitIndividual)}>
                <>
                    {profileInform.company_name ? <div>

                        <div className="profile-settings-couple">
                            <div>
                                <Controller
                                    name="company_name"
                                    control={control}
                                    render={({ field }) => (
                                        <>
                                            <MInput {...field} errorMessage={errors.company_name?.message} title={'Company Name'} />
                                        </>
                                    )}
                                />
                            </div>
                            <div className="ml-30">
                                <Controller
                                    name="email"
                                    control={control}
                                    render={({ field }) => (
                                        <>
                                            <MInput {...field} errorMessage={errors.email?.message} title={'Company E-mail '} />
                                        </>
                                    )}
                                />
                            </div>
                        </div>
                        <div>
                            <div className="license-information-title">License Information</div>
                            <div className="profile-license-couple">
                                <div>
                                    <Controller
                                        name="license_code"
                                        control={control}
                                        render={({ field }) => (
                                            <>
                                                <MInput {...field} errorMessage={errors.license_code?.message} title={'license Code'} />
                                            </>
                                        )}
                                    />
                                </div>
                                <div className="mt-30">
                                    <MFileUpload selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} settings={true} />
                                </div>
                            </div>

                        </div>
                    </div> :
                        <div>
                            <div className="profile-settings-couple">
                                <div>
                                    <Controller
                                        name="first_name"
                                        control={control}
                                        render={({ field }) => (
                                            <>
                                                <MInput {...field} errorMessage={errors.first_name?.message} title={'First Name'} />
                                            </>
                                        )}
                                    />
                                </div>
                                <div className="ml-30">
                                    <Controller
                                        name="last_name"
                                        control={control}
                                        render={({ field }) => (
                                            <>
                                                <MInput {...field} errorMessage={errors.last_name?.message} title={'Last Name'} />
                                            </>
                                        )}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="profile-license-couple">
                                    <div>
                                        <Controller
                                            name="email"
                                            control={control}
                                            render={({ field }) => (
                                                <>
                                                    <MInput {...field} errorMessage={errors.email?.message} title={'E-mail Address'} />
                                                </>
                                            )}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>}
                </>
                <div className="login-loader-position">
                    <MLoader size={50} color={'red'} />
                </div>
                <div className="save-profile-button-pos">
                    <button className="save-profile-button" type="submit">Save Changes</button>
                </div>
            </form>
        </div>
        <MSuccesModal show={isSucces} onClose={() => setIsSucces(!isSucces)} title={'company information has been successfully updated'} />
    </div>
}