import axios from 'axios';


export const api = axios.create({
  baseURL: 'https://dev-api.sell-ect.com/', // Replace with your actual base URL
  headers: {
    'Content-Type': 'application/json',
  },
  // You can add more configurations if needed
  timeout: 30000, // Example: Set a timeout of 1000ms
  //withCredentials: true, // Example: Send cookies with cross-site requests
});

api.interceptors.request.use(
  async function (options) {
    try {
      const token = await localStorage.getItem('@access_token');
      if (token) {
        options.headers['Authorization'] = `Bearer ${token}`;
      }
    } catch (err) { }

    return options;
  },
  (error) => Promise.reject(error),
);


export default api;
