import { Controller, useForm } from 'react-hook-form'
import { MPayInput } from '../../MPayInput'
import { MSelect } from '../../MSelect'
import * as yup from "yup"
import { MInput } from '../../MInput'
import { yupResolver } from "@hookform/resolvers/yup"
import './index.scss'
import { useNavigate } from 'react-router-dom'
import MTextArea from '../../MTextArea'
import PhotoUpload from '../../MPhotoUpload'
import { MCoupleInput } from '../../MCoupleInput'
import { MAddInput } from '../../MAddInput'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PreviewDataHandler, Photos, employmentAction } from '../../../redux/createPostReducer'
import { phoneRegExp } from '../../../utils/validation'
import { allLocation, jobsFieldsType, jobFieldsData } from '../../../services/jobs'

export const Job = ({ selectedItem }) => {
    const [showNumber, setShowNumber] = useState(false)
    const [jobSchedule, setJobSchedule] = useState([]);
    const [requiredExperience, setRequiredExperience] = useState([]);
    const [priceType, setPriceType] = useState('');
    const [response, setResponse] = useState([])
    const [fields, setFields] = useState([])
    const [formValues, setFormValues] = useState({})
    const [subCtegory, setSubCategory] = useState([])
    const [selectedPriceType, setSelectedPriceType] = useState();
    const [disabled, setDIsabled] = useState(false)
    const [location, setLocation] = useState([])
    const [showLink, setShowLink] = useState(false)
    const [selectedEmployment, setSelectedEmployment] = useState();
    const [selectedJob, setSelectedJob] = useState('');
    const [selectedExperience, setSelectedExperience] = useState('');
    const [photos, setPhotos] = useState([]);
    const navigate = useNavigate()
    const previewData = useSelector(state => state.createPost.previewData)
    const chosedSubCategory = useSelector(state => state.createPost.employmentType)
    const profileInform = useSelector(state => state.auth.profileInfo)
    const photo = useSelector(state => state.createPost.photo)
    const dispatch = useDispatch()

    const fetchHomefields = useCallback(async () => {
        try {
            const locations = await allLocation()
            setLocation(locations?.map((item, index) => ({
                name: item.state,
                id: item.id
            })))
            const data = await jobsFieldsType(+selectedItem?.id);
            if (data.length > 0) {
                setResponse(data)
                setSubCategory(data.map((item) => ({
                    name: item.name,
                    id: item.id
                })))
                dispatch(employmentAction(data))

            } else {
                console.error('Unexpected response format:', response);
            }
        } catch (error) {
            console.error('Failed to fetch profile info:', error.response?.data?.message);
        }
    }, [selectedItem?.id]);

    const fetchFieldsData = useCallback(async () => {
        try {
            const response = await jobFieldsData(+selectedEmployment);
            if (response && response.length > 0) {
                const setters = [
                    setJobSchedule,
                    setRequiredExperience,
                    setPriceType,
                ];
                response.forEach((item, index) => {
                    if (setters[index]) {
                        setters[index](item.options);
                    }
                });
                setDIsabled(true)
            } else {
                console.error('Unexpected response format:', response);
            }
        } catch (error) {
            console.error('Failed to fetch profile info:', error.response?.data?.message);
        }
    }, [selectedEmployment]);

    useEffect(() => {
        setFields(response.length > 0 ? response : chosedSubCategory?.filter((item) => +item.id === +formValues?.subcategory?.id))
    }, [formValues?.subcategory?.id])


    const handleEmploymentChange = (event) => {
        setSelectedEmployment(event.target.value);
    };
    const handleJobChange = (event) => {
        setSelectedJob(event.target.value);
    };
    const handleExperinceChange = (event) => {
        setSelectedExperience(event.target.value);
    };
    const getSchema = () => {
        return yup.object().shape({
            subcategory: yup.object()
                .shape({
                    id: yup.number().required('field is required'),
                })
                .typeError('field is required') // Custom message for type error
                .required('field is required'),
            location: yup.object()
                .shape({
                    id: yup.number().required('field is required'),
                })
                .typeError('field is required') // Custom message for type error
                .required('field is required'),
            postTitle: yup.string().required('field is required'),
            price: yup.number().typeError('Price must be a valid number.'),
            responsibilities: yup.string()
                .min(32, 'Description must be at least 32 characters long')
                .required('Description is required'),
            description: yup.string()
                .min(32, 'Description must be at least 32 characters long')
                .required('Description is required'),
            qualifications: yup.string()
                .min(32, 'Description must be at least 32 characters long')
                .required('Description is required'),
            phoneNumber: yup.string()
                .required('field is required')
                .matches(phoneRegExp, 'Phone number is not valid'),
            secondPhoneNumber: showNumber ? yup.string()
                .required('field is required')
                .matches(phoneRegExp, 'Phone number is not valid') : yup.string(),
            socialMedia: profileInform.company_name ? yup.string() : yup.string(),
            emailAddress: yup.string().email('Invalid email format').max(50, 'Email must be at most 50 characters'),
            secondSocialMediaLink: showLink ? yup.string().required('field is required') : yup.string(),
        });
    };
    const {
        handleSubmit,
        formState: { errors },
        control,
        setValue,
        watch
    } = useForm({
        mode: "onChange", resolver: yupResolver(getSchema())
    })
    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            setFormValues(value);
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        setValue('emailAddress', profileInform.email)
        fetchHomefields()
    }, [selectedItem?.id, fetchHomefields])

    useEffect(() => {
        selectedEmployment && fetchFieldsData()
    }, [selectedEmployment, fetchFieldsData])

    useEffect(() => {
        previewData && Object.keys(previewData).forEach(key => {
            setValue(key, previewData[key]);
        });
        console.log(previewData)
        setSelectedEmployment(previewData?.employment)
        setSelectedJob(previewData?.selectedjob)
        setSelectedExperience(previewData?.experience)
        setPhotos(photo)
    }, [setValue, previewData, photo])

    const onSubmit = (data) => {
        dispatch(PreviewDataHandler({
            ...data, employment: selectedEmployment,
            selectedjob: selectedJob,
            experience: selectedExperience,
            defaultPriceType: priceType?.[0]?.id,
            priceType: selectedPriceType, selectedItem: selectedItem,
            employmentArray: fields[0]?.optionTitles[0]?.options,
            jobArray: jobSchedule,
            requiredExperienceArray: requiredExperience

        }))
        dispatch(Photos(photos))
        navigate('/preview-job', { state: { path: 'Job' } })
    }

    return <div className='vehicle-container'>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className='general-information-container'>
                <div className='general-information-title-job'> General Information</div>
                <div className='general-information-position'>
                    <div className='general-information-couple'>
                        <Controller
                            name="subcategory" // Unique identifier for the input
                            control={control}
                            defaultValue="" // Initial value of the input
                            render={({ field: { onChange, value } }) => (
                                <MSelect title="Subcategory" value={value} onChange={onChange} errorMessage={errors.subcategory?.message} items={subCtegory} required={true} disabled={true} />
                            )}
                        />
                        <Controller
                            name="location" // Unique identifier for the input
                            control={control}
                            defaultValue="" // Initial value of the input
                            render={({ field: { onChange, value } }) => (
                                <MSelect title="location" value={value} onChange={onChange} errorMessage={errors.location?.message} items={location} required={true} disabled={true} />
                            )}
                        />
                    </div>
                    <div className='general-information-couple'>
                        <Controller
                            name="postTitle"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <MInput {...field} errorMessage={errors.postTitle?.message} title={'Post Title'} required={true} />
                                </>
                            )}
                        />

                        <Controller
                            name="price"
                            control={control}
                            render={({ field }) => (
                                <div style={{ marginTop: 1 }}>
                                    <MPayInput {...field} errorMessage={errors.price?.message} currency="$" title={'Price'} items={priceType} setSelectedPriceType={setSelectedPriceType} disabled={disabled} />
                                </div>
                            )}
                        />
                    </div>
                </div>

            </div>
            <div className='general-information-container'>
                {fields[0]?.optionTitles[0]?.options && <div className='general-information-title-job'> Detailed Information</div>}
                {fields[0]?.optionTitles[0]?.options && <div>
                    <div className='check-box-information-title-job'>
                        <div className='information-title-required-mode'>
                            <div>Employment Type</div>
                            <div className='select-title-required'>*</div>
                        </div>
                    </div>
                    <div>
                        <div className='radio-group-pos-job'>
                            {fields[0]?.optionTitles[0]?.options?.map((item, index) =>
                                <div className="radio-custom" key={index}>
                                    <input
                                        type="radio"
                                        value={item.id}
                                        checked={+selectedEmployment === +item.id}
                                        onChange={handleEmploymentChange}
                                    />
                                    {item?.name}
                                </div>)}
                        </div>
                    </div>

                </div>}

            </div>
            {jobSchedule?.length > 0 && <div className='general-information-container'>
                <div>
                    <div className='check-box-information-title-job'>
                        <div className='information-title-required-mode'>
                            <div>Job Schedule</div>
                            <div className='select-title-required'>*</div>
                        </div>
                    </div>
                    <div>
                        <div className='radio-group-pos-job'>
                            {jobSchedule?.map((item, index) =>
                                <div className="radio-custom" key={index}>
                                    <input
                                        type="radio"
                                        value={item.id}
                                        checked={+selectedJob === +item.id}
                                        onChange={handleJobChange}
                                    />
                                    {item?.name}
                                </div>)}
                        </div>
                    </div>

                </div>

            </div>}
            {requiredExperience.length > 0 && <div className='general-information-container'>
                <div>
                    <div className='check-box-information-title-job'>
                        <div className='information-title-required-mode'>
                            <div>Required Experience</div>
                            <div className='select-title-required'>*</div>
                        </div>
                    </div>
                    <div>
                        <div className='radio-group-pos-job'>
                            {requiredExperience?.map((item, index) =>
                                <div className="radio-custom" key={index}>
                                    <input
                                        type="radio"
                                        value={item.id}
                                        checked={+selectedExperience === +item.id}
                                        onChange={handleExperinceChange}
                                    />
                                    {item.name}
                                </div>)}
                        </div>
                    </div>

                </div>

            </div>}

            <div className='general-information-container'>
                <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                        <div className='mt-30'>
                            <MTextArea  {...field}
                                placeholder={'Describe your business / service / specialist'} title={'Description'}
                                errorMessage={errors.description?.message}
                            />
                        </div>
                    )}
                />

            </div>
            <div className='general-information-container'>
                <Controller
                    name="responsibilities"
                    control={control}
                    render={({ field }) => (
                        <div className='mt-30'>
                            <MTextArea  {...field}
                                placeholder={'Describe here'} title={'Responsibilities'}
                                errorMessage={errors.responsibilities?.message}
                            />
                        </div>
                    )}
                />

            </div>
            <div className='general-information-container'>
                <Controller
                    name="qualifications"
                    control={control}
                    render={({ field }) => (
                        <div className='mt-30'>
                            <MTextArea  {...field}
                                placeholder={'Describe  here'} title={'Qualifications'}
                                errorMessage={errors.qualifications?.message}
                            />
                        </div>
                    )}
                />

            </div>
            <div className='general-information-container-job'>
                <PhotoUpload photos={photos} setPhotos={setPhotos} />
            </div>
            <div className='general-information-container'>
                <div className='general-information-title-job mt-50'> Contact Information</div>
                <div className='general-information-position'>
                    <div className='general-information-couple'>
                        <Controller
                            name="phoneNumber"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <MCoupleInput {...field} errorMessage={errors.phoneNumber?.message} title={'Phone Number'} currency={'+1'} placeholder={'Phone Number'} icon={false} />
                                </>
                            )}
                        />
                        {profileInform.company_name && <Controller
                            name="socialMedia"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <MCoupleInput {...field} errorMessage={errors.socialMedia?.message} title={'Websites & Social Networks'} placeholder={'Social media link'} icon={true} />
                                </>
                            )}
                        />}
                        <Controller
                            name="emailAddress"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <MInput {...field} errorMessage={errors.emailAddress?.message} title={'E-mail Address'} />
                                </>
                            )}
                        />
                    </div>
                    <div className='general-information-couple mt-3'>
                        <Controller
                            name="secondPhoneNumber"
                            control={control}
                            rules={{
                                required: showNumber ? 'this field is required' : false
                            }}
                            render={({ field }) => (
                                <>
                                    <MAddInput {...field} errorMessage={errors.secondPhoneNumber?.message} title={'Phone Number'} addTitle='Add new phone number' show={showNumber} setShow={setShowNumber} placeholder={'Phone Number'} currency={'+1'} />
                                </>
                            )}
                        />
                        {profileInform.company_name && <Controller
                            name="secondSocialMediaLink"
                            control={control}
                            rules={{
                                required: showLink ? 'this field is required' : false
                            }}
                            render={({ field }) => (
                                <>
                                    <MAddInput {...field} errorMessage={errors.secondSocialMediaLink?.message} title={'Websites & Social Networks'} addTitle='Add new link' placeholder={'Social media link'} show={showLink} setShow={setShowLink} icon={true} />
                                </>
                            )}
                        />}
                    </div>
                </div>

            </div>
            <div className='preview-button'>
                <button type="submit" className="button-pos-prev">Go to Preview {'>'}</button>
            </div>
        </form>
    </div>
}