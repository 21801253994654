import * as React from "react"
const Push = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={38}
        height={38}
        fill="none"
        {...props}
    >
        <rect width={38} height={38} fill="#FEECEE" rx={19} />
        <path
            fill="#F56476"
            fillRule="evenodd"
            d="M19.999 9.999a1 1 0 1 0-2 .002l.003 2.272 2-.003L20 9.999Zm-6.84 7.543c.448-4.636 3.722-5.27 5.85-5.27h.531c1.595-.013 5.31 1.054 5.317 5.27.008 4.475 1.345 6.29 1.91 7.058.1.137.177.24.217.32.177.351-1.063 1.054-7.444 1.054-6.38 0-8.86-.703-8.507-1.054.531-.527 1.567-1.581 2.127-7.378Zm2.913 8.431c-.01.096-.016.193-.016.291 0 1.512 1.32 2.738 2.947 2.738 1.628 0 2.947-1.226 2.947-2.738 0-.084-.004-.167-.012-.249-.38.011-.782.02-1.207.025.013.074.02.15.02.224 0 .768-.698 1.538-1.748 1.538s-1.747-.77-1.747-1.538c0-.083.008-.166.024-.248a67.546 67.546 0 0 1-1.208-.043Z"
            clipRule="evenodd"
        />
    </svg>
)
export default Push
