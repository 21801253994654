import { useState } from "react"
import SelectArrow from "../../assets/icons/selectArrow"
import "./index.scss"
import MyPost from "../../assets/icons/mypost"
import FavoritePost from "../../assets/icons/favoritepost"
import Settings from "../../assets/icons/settings"
import LogOut from "../../assets/icons/logOut"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { adminAction, isAuthAction, logOutAction, profileInformation } from "../../redux/authReducer"

export const MAvatar = () => {
    const [isOpen, setIsOpen] = useState(false)
    const admin = useSelector(state => state.auth.admin)
    const profileInfo = useSelector(state => state.auth.profileInfo)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const logOut = () => {
        localStorage.setItem('isAuth', JSON.stringify(false))
        localStorage.removeItem("@access_token")
        dispatch(logOutAction(true))
        dispatch(adminAction(false))
        dispatch(profileInformation(null))
        dispatch(isAuthAction(false))
        navigate('/')
    }
    return <div className="avatar-container">
        {profileInfo?.first_name ? <div className="circle">
            <div>{profileInfo?.first_name[0]} {profileInfo?.last_name[0]}</div>
        </div> : <div className="circle">
            <div>{profileInfo?.company_name[0]}</div>
        </div>}
        <div className="avatar-img-position" onClick={() => setIsOpen(!isOpen)}>
            {profileInfo?.first_name ? <div>{profileInfo?.first_name} {profileInfo?.last_name}</div> : <div>{profileInfo?.company_name}</div>}
            <div className="avatar-arrow-position" ><SelectArrow isOpen={isOpen} /></div>
        </div>
        {isOpen && <div className="profile-settings">
            <div>
                <div className="profile-mail-pos">
                    <div>
                        {profileInfo?.first_name ? <div className="circle">
                            <div>{profileInfo?.first_name[0]} {profileInfo?.last_name[0]}</div>
                        </div> : <div className="circle">
                            <div>{profileInfo?.company_name[0]}</div>
                        </div>}
                    </div>
                    <div className="profile-settings-info">
                        {profileInfo?.first_name ? <div className="profile-settings-info-name">{profileInfo?.first_name} {profileInfo?.last_name}</div> : <div className="profile-settings-info-name">{profileInfo?.company_name}</div>}
                        <div className="profile-settings-mail">{profileInfo?.email}</div>
                    </div>
                </div>
                <div className="line-menu-style"></div>
                {!admin &&
                    <div className="profile-settings-menu">

                        <div className="profile-settings-menu-couple" onClick={() => navigate('/my-post')}>
                            <div>  <MyPost /></div>
                            <div> My Posts</div>
                        </div>
                        <div className="profile-settings-menu-couple" onClick={() => navigate('/favorite-post')}>
                            <div>  <FavoritePost /></div>
                            <div> Favorite Posts</div>
                        </div>
                        <div className="profile-settings-menu-couple" onClick={() => navigate('/setting')}>
                            <div>  <Settings /></div>
                            <div> Settings</div>
                        </div>
                    </div>}
                <div className="line-menu-style"></div>
                <div className="profile-settings-menu-couple" onClick={logOut}>
                    <div><LogOut /></div>
                    <div>Log out</div>
                </div>
            </div>
        </div>}
    </div>
}


