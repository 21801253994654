import "./index.scss"
import * as yup from "yup"
import { MInput } from '../../MInput'
import { yupResolver } from "@hookform/resolvers/yup"
import { Controller, useForm } from "react-hook-form"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { loading } from "../../../redux/authReducer"
import { MLoader } from "../../MLoader"
import MSuccesModal from "../../MSuccesModal"
import { changePassword } from "../../../services/user"

export const AccountSetting = () => {
    const [errorMessageType, setErrorMessageType] = useState()
    console.log(errorMessageType, "errorMessageType")
    const [isSucces, setIsSucces] = useState(false)
    //const profileInfo = useSelector(state => state.auth.profileInfo)
    const dispatch = useDispatch()
    const getSchema = () => {
        return yup.object().shape({
            password: yup.string().required('field is required'),
            new_password: yup.string().required('field is required'),
            new_password_confirmation: yup.string()
                .required('Confirm password field is required')
                .oneOf([yup.ref('new_password'), null], 'Passwords must match'),
        });
    };
    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm({
        mode: "onChange", resolver: yupResolver(getSchema())
    })

    const onSubmit = async (form) => {
        dispatch(loading(true))
        try {
            await changePassword(form)
            setIsSucces(true)
        }
        catch (error) {
            setErrorMessageType(error.response.data.errors)
        }
        finally {
            dispatch(loading(false))
        }
    }
    const deactivateUser = async () => {
        dispatch(loading(true))
        try {
            await deactivateUser()
            setIsSucces(true)
        }
        catch (error) {
            setErrorMessageType(error.response.data.errors)
        }
        finally {
            dispatch(loading(false))
        }
    }
    return <> <div className="account-settings-container">
        <div className="profile-settings-container-pos">
            <div className="profile-title-info">Change Password</div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="account-settings-couple">
                    <div>
                        <Controller
                            name="password"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <MInput {...field} errorMessage={errorMessageType ? errorMessageType['field'] : errors.password?.message} title={'Old Password'} />
                                </>
                            )}
                        />
                    </div>
                    <div className="ml-30">
                        <Controller
                            name="new_password"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <MInput {...field} errorMessage={errorMessageType ? errorMessageType['new_password'] : errors.new_password?.message} title={'New Password '} />
                                </>
                            )}
                        />
                    </div>
                </div>
                <div>
                    <div className="profile-license-couple">
                        <div>
                            <Controller
                                name="new_password_confirmation"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <MInput {...field} errorMessage={errors.new_password_confirmation?.message} title={'Confirm New Password'} />
                                    </>
                                )}
                            />
                        </div>
                    </div>

                </div>
                <div className="login-loader-position">
                    <MLoader size={50} color={'red'} />
                </div>
                <div className="save-profile-button-pos">
                    <button className="save-profile-button" type="submit">Save Changes</button>
                </div>
            </form>
        </div>
        <MSuccesModal show={isSucces} onClose={() => setIsSucces(!isSucces)} title={'company information has been successfully updated'} />
    </div>
        <div className="deactivate-container">
            <div className="deactivate-pos">
                <div className="deactivate-title">Deactivate Account</div>
                <div className="deactivate-button-container">
                    <div className="deactivate-information">
                        If you do not plan to use Marketplace, you can permanently deactivate your account.
                        Your ads and all other information will  be deleted and you will not be able to use this
                        email address on Marketplace again.
                    </div>
                    <div>
                        <button className="deactivate-button" onClick={deactivateUser}>Deactivate</button>
                    </div>
                </div>
            </div>
        </div>
    </>
}