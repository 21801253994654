import { useForm, Controller } from "react-hook-form"
import { schemaIndividualCreateAccount } from "../../../utils/validation"
import MCheckBox from "../../../components/MCheckBox"
import MGoogleButton from "../../../components/MGoogleButton"
import MFacebookButton from "../../../components/MFacebookButton"
import { useNavigate } from "react-router-dom"
import { yupResolver } from "@hookform/resolvers/yup"
import './index.scss'
import MAuthInput from "../../../components/MAuthInput"
import { useDispatch } from "react-redux"
import { registerUser } from "../../../services/user"
import { loading } from "../../../redux/authReducer"
import { MLoader } from "../../../components/MLoader"
import { useState } from "react"
import MSuccesModal from "../../../components/MSuccesModal"

export const Individual = () => {
    const [errorMessageType, setErrorMessageType] = useState()
    const [isSucces, setIsSucces] = useState(false)
    const dispatch = useDispatch()
    const {
        handleSubmit,
        formState: { errors },
        control
    } = useForm({
        mode: "onChange", resolver: yupResolver(schemaIndividualCreateAccount)
    })
    const navigate = useNavigate()
    const onSubmit = async (data) => {
        dispatch(loading(true))
        try {
            delete data.checkBox;
            await registerUser(data)
            setIsSucces(true)
        }
        catch (error) {
            setErrorMessageType(error.response.data.errors)
        }
        finally {
            dispatch(loading(false))
        }
    }

    return <div>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="info-container">
                <Controller
                    name="first_name"
                    control={control}
                    render={({ field }) => (
                        <>
                            <MAuthInput {...field} placeholder='First Name' errorMessage={errors.first_name?.message} half={true} title='First Name' />
                        </>
                    )}
                />
                <div className="last-name-container">
                    <Controller
                        name="last_name"
                        control={control}
                        render={({ field }) => (
                            <>
                                <MAuthInput {...field} placeholder='Last Name' errorMessage={errors.last_name?.message} half={true} title='Last Name' />
                            </>
                        )}
                    />
                </div>

            </div>
            <Controller
                name="email"
                control={control}
                render={({ field }) => (
                    <>
                        <MAuthInput {...field} placeholder='E-mail Address' errorMessage={errorMessageType?.email && errorMessageType['email'] ? errorMessageType['email'][0] : errors.email?.message} title='E-mail Address' />
                    </>
                )}
            />
            <div className="passwordContainer">
                <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                        <>
                            <MAuthInput {...field} isPassword={true} placeholder='Password' errorMessage={errorMessageType?.password && errorMessageType['password'] ? errorMessageType['password'][0] : errors.password?.message} title='Password' />
                        </>
                    )}
                />
                <Controller
                    name="password_confirmation"
                    control={control}
                    render={({ field }) => (
                        <>
                            <MAuthInput {...field} isPassword={true} placeholder='Confirm Password' errorMessage={errors.password_confirmation?.message} title='Confirm Password' />
                        </>
                    )}
                />
            </div>
            <div className="check-box-container mt-45">
                <div className="check-box-position">
                    <Controller
                        name="checkBox"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <MCheckBox checked={value} onChange={onChange} />
                        )}
                    />

                </div>
                <div className="terms-condition-container">
                    <div className="terms-text">I agree to the Terms & Conditions </div>
                    <div className="terms-condition" onClick={() => navigate('/')}>Terms & Conditions</div>
                </div>
            </div>
            <div className="login-loader-position">
                <MLoader size={50} color={'red'} />
            </div>
            <button type="submit" className="create-button">Create Account</button>
        </form>

        <div className="account-text-container">
            <p className="text-account">Already have an account ? </p>
            <p className="create-text" onClick={() => navigate('/login')}>Login</p>
        </div>
        <div className="line-with-text">
            <div className="text-or">or</div>
        </div>
        <div>
        </div>
        <div className="button-container-position">
            <MGoogleButton />
            <MFacebookButton />
        </div>
        <MSuccesModal show={isSucces} onClose={() => setIsSucces(!isSucces)} title={'user created successfully'} />
    </div>
}
