import * as React from "react"
const ViewAllCall = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={30}
        height={29}
        fill="none"
        {...props}
    >
        <rect width={30} height={29} fill="#FEECEE" rx={7} />
        <path
            fill="#F56476"
            stroke="#F56476"
            strokeWidth={0.4}
            d="M11.432 16.477a12.772 12.772 0 0 0 4.25 3.317c.622.293 1.453.641 2.379.701.057.002.112.005.17.005.621 0 1.12-.214 1.527-.654a.052.052 0 0 0 .01-.012c.145-.174.31-.331.482-.498.117-.112.237-.228.352-.348.532-.552.532-1.253-.005-1.788l-1.5-1.494c-.255-.264-.56-.403-.879-.403-.32 0-.626.14-.888.4l-.894.89a4.612 4.612 0 0 0-.247-.129c-.1-.05-.192-.097-.274-.149-.814-.515-1.553-1.186-2.26-2.049-.356-.45-.596-.828-.763-1.213.235-.212.454-.433.666-.65l.23-.23c.27-.269.414-.58.414-.896 0-.315-.142-.626-.414-.895l-.744-.74-.255-.26c-.164-.168-.336-.342-.506-.5-.257-.25-.56-.382-.879-.382-.317 0-.621.132-.888.385l-.934.93c-.34.338-.531.749-.571 1.224-.048.594.062 1.226.347 1.989.436 1.181 1.095 2.278 2.074 3.449ZM9.62 11.09c.03-.33.157-.607.397-.845l.928-.925c.145-.14.304-.212.46-.212.152 0 .306.072.449.217.167.154.324.315.494.487l.26.263.743.741c.155.155.235.311.235.465 0 .155-.08.311-.235.465l-.232.234c-.232.234-.45.455-.689.667l-.012.012c-.207.206-.175.403-.125.552l.007.02c.192.46.46.898.876 1.42.75.92 1.538 1.633 2.406 2.183.108.07.223.124.33.179.1.05.192.097.274.15l.028.014a.523.523 0 0 0 .242.062c.2 0 .33-.127.372-.169l.934-.93c.144-.144.302-.221.456-.221.19 0 .345.117.442.221l1.505 1.497c.3.298.297.622-.007.937a8.232 8.232 0 0 1-.332.331c-.175.17-.357.343-.522.54-.287.308-.629.452-1.07.452-.043 0-.088-.002-.13-.005-.82-.052-1.58-.37-2.152-.641a12.133 12.133 0 0 1-4.046-3.158c-.931-1.117-1.558-2.156-1.972-3.27-.257-.684-.354-1.233-.314-1.733Z"
        />
    </svg>
)
export default ViewAllCall
