import React from 'react';
import './index.scss';
import GoogleIcon from '../../assets/icons/google';

const MGoogleButton = () => {
    return (
        <div className="button-with-icon" onClick={() => console.log('hello')}>
            <div className="icon-google"><GoogleIcon /></div>
            <div className="text-google">Google</div>
        </div>
    );
};

export default MGoogleButton;
