import Arrow from '../../assets/icons/arrow'
import { MContainer } from '../../components/MContainer'
import { MViewAllCard } from '../../components/MViewAllCard'
import Select from "react-select";
import './index.scss'
import { useState } from 'react';
import { MPriceInput } from '../../components/MPriceInput';
import { useLocation } from 'react-router-dom';


export const ViewAllJob = () => {
    const [location, setLocation] = useState()
    console.log(location)
    const [services, setServices] = useState([]);
    const { state } = useLocation();
    const handleServiceChange = (event) => {
        const { value, checked } = event.target;
        setServices(prevServices =>
            checked ? [...prevServices, value] : prevServices.filter(service => service !== value)
        );
    };

    const customStyles = {
        control: (provided) => ({
            ...provided,
            border: "none",
            boxShadow: "none",
            backgroundColor: "#F3F3F6",
            borderRadius: "8px" // Background color of the control
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: "#e0e0e0" // Background color of the dropdown menu
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "#d0d0d0" : state.isFocused ? "#F3F3F6" : "#F3F3F6",
            color: state.isSelected ? "#000" : "#333"
        }),
        container: (provided) => ({
            ...provided,
        })
    };
    const options = [
        { value: 0, label: "0 degrees" },
        { value: 90, label: "90 degrees" },
        { value: 180, label: "180 degrees" },
        { value: 270, label: "270 degrees" }
    ];

    return <MContainer footerShow={true} >
        <div className='view-all-container-position'>

            <div className='view-all-filter-position'>
                <div className='view-all-navigation-button'>
                    <div>Job <Arrow rotation={180} /> </div>
                    <div className='view-all-path-name'>
                        {state?.path}
                    </div>
                </div>
                <div className='view-all-filter-container'>
                    <div className='view-all-filter-title-home'>Filters</div>
                    <div className='view-select-container'>
                        <Select
                            options={options} onChange={setLocation}
                            components={{ IndicatorSeparator: () => null }}
                            styles={customStyles}
                        />
                    </div>
                    <div className='line'></div>
                    <div className='view-all-filter-title-home'>Price</div>
                    <div className='price-inputs-container'>
                        <MPriceInput placeholder={'from'} />
                        <div className='price-line'>-</div>
                        <MPriceInput placeholder={'to'} />
                    </div>
                    <div>
                        <div className='line'></div>
                        <div>
                            <div className='view-all-filter-title-home'> Employment Type</div>
                            <div className='checkbox-group-pos'>
                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="Permanent"
                                        checked={services.includes('Permanent')}
                                        onChange={handleServiceChange}
                                        id="Permanent"
                                    />
                                    <label htmlFor="Permanent">Permanent</label>
                                </div>
                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="Temporary"
                                        checked={services.includes('Temporary')}
                                        onChange={handleServiceChange}
                                        id="Temporary"
                                    />
                                    <label htmlFor="Temporary">Temporary</label>
                                </div>
                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="Internship"
                                        checked={services.includes('Internship')}
                                        onChange={handleServiceChange}
                                        id="Internship"
                                    />
                                    <label htmlFor="Internship">Internship</label>
                                </div>
                            </div>
                        </div>
                        <div className='line'></div>
                        <div>
                            <div className='view-all-filter-title-home'> Job Schedule</div>
                            <div className='checkbox-group-pos'>
                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="Full Time"
                                        checked={services.includes('Full Time')}
                                        onChange={handleServiceChange}
                                        id="Full Time"
                                    />
                                    <label htmlFor="Full Time">Full Time</label>
                                </div>

                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="Part Time"
                                        checked={services.includes('Part Time')}
                                        onChange={handleServiceChange}
                                        id="Part Time"
                                    />
                                    <label htmlFor="Part Time">Part Time</label>
                                </div>
                            </div>
                        </div>
                        <div className='line'></div>
                        <div>
                            <div className='view-all-filter-title-home'> Required Experience</div>
                            <div className='checkbox-group-pos'>
                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="Without Experience"
                                        checked={services.includes('Without Experience')}
                                        onChange={handleServiceChange}
                                        id="Without Experience"
                                    />
                                    <label htmlFor="Without Experience">Without Experience</label>
                                </div>
                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="Less than a year"
                                        checked={services.includes('Less than a year')}
                                        onChange={handleServiceChange}
                                        id="Less than a year"
                                    />
                                    <label htmlFor="Less than a year">Less than a year</label>
                                </div>
                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="1-3 years"
                                        checked={services.includes('1-3 years')}
                                        onChange={handleServiceChange}
                                        id="1-3 years"
                                    />
                                    <label htmlFor="1-3 years">1-3 years</label>
                                </div>
                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="3-5 years"
                                        checked={services.includes('3-5 years')}
                                        onChange={handleServiceChange}
                                        id="3-5 years"
                                    />
                                    <label htmlFor="3-5 years">3-5 years</label>
                                </div>
                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="More than 5 years"
                                        checked={services.includes('More than 5 years')}
                                        onChange={handleServiceChange}
                                        id="More than 5 years"
                                    />
                                    <label htmlFor="More than 5 years">More than 5 years</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='line'></div>
                    <div className='view-all-filter-title'>Categories</div>
                    <div className='view-all-filter-second-title'>Real Estate</div>
                    <div className='view-second-select-container'>
                        <Select
                            options={options} onChange={setLocation}
                            components={{ IndicatorSeparator: () => null }}
                            styles={customStyles}
                        />
                    </div>
                    <div className='view-second-select-container'>
                        <Select
                            options={options} onChange={setLocation}
                            components={{ IndicatorSeparator: () => null }}
                            styles={customStyles}
                        />
                    </div>
                    <div className='view-second-select-container'>
                        <Select
                            options={options} onChange={setLocation}
                            components={{ IndicatorSeparator: () => null }}
                            styles={customStyles}
                        />
                    </div>
                    <div className='view-second-select-container'>
                        <Select
                            options={options} onChange={setLocation}
                            components={{ IndicatorSeparator: () => null }}
                            styles={customStyles}
                        />
                    </div>
                    <div className='view-second-select-container'>
                        <Select
                            options={options} onChange={setLocation}
                            components={{ IndicatorSeparator: () => null }}
                            styles={customStyles}
                        />
                    </div>
                </div>

            </div>

            <div className='view-all-card-container-pos'>
                <MViewAllCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
                <MViewAllCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
                <MViewAllCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
                <MViewAllCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
                <MViewAllCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
                <MViewAllCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
            </div>
        </div>
    </MContainer>
}