import * as React from "react"
const Search = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={15}
        height={16}
        fill="none"
        {...props}
    >
        <path
            fill="#F56476"
            d="m14.79 14.235-2.781-2.76a6.755 6.755 0 0 0-.76-9.252 6.743 6.743 0 0 0-9.273.254A6.754 6.754 0 0 0 6.193 13.98a6.743 6.743 0 0 0 4.774-1.462l2.758 2.76a.75.75 0 0 0 1.064 0 .75.75 0 0 0 0-1.043Zm-8.028-1.717a5.244 5.244 0 0 1-4.848-3.242 5.255 5.255 0 0 1 3.824-7.16 5.243 5.243 0 0 1 5.386 2.234 5.254 5.254 0 0 1-.652 6.63 5.245 5.245 0 0 1-3.71 1.538Z"
        />
    </svg>
)
export default Search
