import React, { useEffect, useRef } from 'react';
import SelectArrow from '../../assets/icons/selectArrow';
import './index.scss';
import Selected from '../../assets/icons/selected';
import { PreviewDataHandler } from '../../redux/createPostReducer';
import { useDispatch } from 'react-redux';

export const MFilterSelect = ({ previewItem, isActive, handleButtonClick, text, items, isOpen, onToggle, state, selectedItem, setSelectedItem }) => {
  const dispatch = useDispatch()
  const selectRef = useRef(null);
  useEffect(() => {
    setSelectedItem(
      previewItem
    )
  }, [state, previewItem, setSelectedItem])
  const handleItemClick = (index, item) => {
    dispatch(PreviewDataHandler(null))
    setSelectedItem(item);
    handleButtonClick();
    onToggle(); // Close the dropdown after selecting an item
  };

  const truncateText = (text, maxLength) => {
    return text?.length > maxLength ? `${text?.substring(0, maxLength)}...` : text;
  };

  return (
    <div className='filter-select-pos' ref={selectRef}>
      <div className={`select-filter-container ${isActive ? 'active-select-filter-container' : ''}`}>
        <div className={isActive ? 'select-filter-position-active' : 'select-filter-position'} onClick={onToggle}>
          <div className='selected-name-title'>
            <div className='select-filter-name'> {text}</div>
            <div className='active-title'>{selectedItem !== null && isActive ? truncateText(selectedItem?.name, 18) : ''}</div>
          </div>
          <div className='select-arrow-style'>
            <SelectArrow isOpen={isOpen} />
          </div>
        </div>
      </div>
      {isOpen && (
        <div className='select-items'>
          {items?.map((item, index) => (
            <div key={index} className={`select-item ${selectedItem?.id === item?.id && isActive ? 'selected' : ''}`} onClick={() => handleItemClick(index, item)}>
              {item.name}
              {selectedItem?.id === item?.id && isActive && <div className='select-choose-row'><Selected /></div>}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
