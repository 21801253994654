import * as React from "react"
const CarTable = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={18}
        fill="none"
        {...props}
    >
        <circle cx={6} cy={10.5} r={1} fill="#32394A" />
        <path
            stroke="#32394A"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.3}
            d="m19 4.5-2 2-1.5-3.7a2 2 0 0 0-1.854-1.3H6.4a2 2 0 0 0-1.903 1.257L3 6.5l-2-2M17 6.5H3a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2ZM5 14.5v2m10-2v2"
        />
        <circle cx={14} cy={10.5} r={1} fill="#32394A" />
    </svg>
)
export default CarTable
