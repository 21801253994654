import React from 'react';
import chroma from 'chroma-js';
import './index.scss'

import Select from 'react-select';
import SelectArrow from '../../assets/icons/selectArrow';

const dot = (color = 'transparent') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 15,
    width: 15,
  },
});

const colorStyles = {
  control: (styles) => ({
    ...styles, backgroundColor: '#F3F3F6',
    width: "525px", padding: "9px",
    marginTop: "5px", borderRadius: "10px",
    //boxShadow: " 0 2px 6px rgba(0, 0, 0, 0.1)",
    border: "1px solid #F3F3F6",
    '@media (max-width: 1600px)': {
      width: "525px"
    },
    '@media (max-width: 1250px)': {
      width: "400px"
    },
    '@media (max-width: 900px)': {
      width: "300px"
    },
    '@media (max-width: 600px)': {
      width: "250px"
    },
  }),

  menu: (styles) => ({
    ...styles, backgroundColor: '#F3F3F6',
    width: "525px", padding: "8px",
    marginTop: "35px", borderRadius: "10px",
    border: "1px solid #F3F3F6",
    '@media (max-width: 1600px)': {
      width: "525px"
    },
    '@media (max-width: 1250px)': {
      width: "400px"
    },
    '@media (max-width: 900px)': {
      width: "300px"
    },
    '@media (max-width: 600px)': {
      width: "250px"
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
          ? data.color
          : isFocused
            ? color.alpha(0.1).css()
            : undefined,
      color: isDisabled
        ? '#ccc'
        : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : color.alpha(0.3).css()
          : undefined,
      },
    };
  },
  input: (styles) => ({ ...styles, ...dot() }),
  placeholder: (styles) => ({ ...styles, ...dot('#ccc') }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};

export const MColorSelect = ({ title = 'color', onChange, options, errorMessage, value, isDisabled }) => {
  return <div className='color-select'>
    <div className='color-title'> {title} </div>
    <Select
      options={options}
      styles={colorStyles}
      onChange={onChange} // Pass selected value
      value={value}
      placeholder={'color'}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator: () => <div style={{ marginRight: 20 }}><SelectArrow /></div>
      }}
      isDisabled={!isDisabled}
    />
    {errorMessage && <span className="error-message">{errorMessage}</span>}
  </div>
};