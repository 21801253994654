import { Controller, useForm } from 'react-hook-form'
import { MPayInput } from '../../MPayInput'
import { MSelect } from '../../MSelect'
import * as yup from "yup"
import { MInput } from '../../MInput'
import { yupResolver } from "@hookform/resolvers/yup"
import './index.scss'
import { useNavigate } from 'react-router-dom'
import MTextArea from '../../MTextArea'
import PhotoUpload from '../../MPhotoUpload'
import { MCoupleInput } from '../../MCoupleInput'
import { MAddInput } from '../../MAddInput'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PreviewDataHandler, Photos } from '../../../redux/createPostReducer'
import { phoneRegExp } from '../../../utils/validation'
import { allLocation, homeCategory, homeType } from '../../../services/home'

export const Home = () => {

    const [showNumber, setShowNumber] = useState(false)
    const [showLink, setShowLink] = useState(false)
    const [selectedValue, setSelectedValue] = useState();
    const [disabled, setDIsabled] = useState(false)
    const [photos, setPhotos] = useState([]);
    const [location, setLocation] = useState([])
    const [homeFor, setHomeFor] = useState([])
    const [priceType, setPriceType] = useState([]);
    const [selectedPriceType, setSelectedPriceType] = useState();
    const [propertyType, setPropertyType] = useState([])
    const [laundryType, setLaundryType] = useState([])
    const [parkingType, setParkingType] = useState([])
    const [airType, setAirType] = useState([])
    const [heatingType, setHeatingType] = useState([])
    const profileInform = useSelector(state => state.auth.profileInfo)
    const navigate = useNavigate()
    const previewData = useSelector(state => state.createPost.previewData)
    const photo = useSelector(state => state.createPost.photo)
    const dispatch = useDispatch()
    const handleRadioChange = (event) => {
        setSelectedValue(event.target.value);
    };


    const getSchema = () => {
        return yup.object().shape({
            propertyType: yup.object()
                .shape({
                    id: yup.number().required('field is required'),
                })
                .typeError('field is required') // Custom message for type error
                .required('field is required'),
            adress: yup.string().required(' field is required'),
            location: yup.object()
                .shape({
                    id: yup.number().required('field is required'),
                })
                .typeError('field is required') // Custom message for type error
                .required('field is required'),
            postTitle: yup.string().required('field is required'),
            price: yup.number().typeError('Price must be a valid number.'),
            squareFeet: yup.number().typeError('Price must be a valid number.').required('field is required'),
            bedrooms: yup.number().typeError('field is required').required('field is required'),
            bathroooms: yup.number().typeError('field is required').required('field is required'),
            laundryType: yup.object()
                .shape({
                    id: yup.number()
                })
                .typeError('field') // Custom message for type error
            ,
            parkingType: yup.object()
                .shape({
                    id: yup.number()
                })
                .typeError('field'), // Custom message for type error
            conditioningType: yup.object()
                .shape({
                    id: yup.number()
                })
                .typeError('field ') // Custom message for type error
            ,
            heatingType: yup.object()
                .shape({
                    id: yup.number()
                })
                .typeError('field is required'), // Custom message for type error,
            description: yup.string()
                .min(32, 'Description must be at least 32 characters long')
                .required('Description is required'),
            phoneNumber: yup.string()
                .required('field is required')
                .matches(phoneRegExp, 'Phone number is not valid'),
            secondPhoneNumber: showNumber ? yup.string()
                .required('field is required')
                .matches(phoneRegExp, 'Phone number is not valid') : yup.string(),
            socialMedia: profileInform.company_name ? yup.string() : yup.string(),
            emailAddress: yup.string().email('Invalid email format').max(50, 'Email must be at most 50 characters'),
            secondSocialMediaLink: showLink ? yup.string().required('field is required') : yup.string(),
        });
    };
    const {
        handleSubmit,
        formState: { errors },
        control,
        setValue,
    } = useForm({
        mode: "onChange", resolver: yupResolver(getSchema())
    })


    useEffect(() => {
        previewData && Object.keys(previewData).forEach(key => {
            setValue(key, previewData[key]);
        });
        setPhotos(photo)
        setSelectedValue(previewData?.homeType)
    }, [setValue, previewData, photo])

    const fetchVehicle = useCallback(async () => {
        try {
            const response = await homeType()
            setHomeFor(response)
            const locations = await allLocation()
            setLocation(locations.map((item, index) => ({
                name: item.state,
                id: item.id
            })))
        } catch (error) {
            console.error('Failed to fetch profile info:', error.response?.data?.message);
        }
    }, []);

    const fetchHomefields = useCallback(async () => {
        try {
            const response = await homeCategory(+selectedValue);
            if (response && response.length > 0) {
                const setters = [
                    setPriceType,
                    setPropertyType,
                    setLaundryType,
                    setParkingType,
                    setAirType,
                    setHeatingType,
                ];
                response.forEach((item, index) => {
                    if (setters[index]) {
                        setters[index](item.options);
                    }
                });
                setDIsabled(true)
            } else {
                console.error('Unexpected response format:', response);
            }
        } catch (error) {
            console.error('Failed to fetch profile info:', error.response?.data?.message);
        }
    }, [selectedValue]);


    useEffect(() => {
        fetchVehicle();
        setValue('emailAddress', profileInform.email)
    }, []);


    useEffect(() => {
        selectedValue && fetchHomefields()
    }, [selectedValue, fetchHomefields])


    const onSubmit = (data) => {
        dispatch(PreviewDataHandler({ ...data, homeType: selectedValue, allTypes: homeFor, priceType: selectedPriceType, defaultPriceType: priceType?.[0]?.id }))
        dispatch(Photos(photos))
        navigate('/preview-home', { state: { path: 'Home' } })
    }

    return <div className='vehicle-container'>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div>
                <div className='general-information-title-home'> General Information</div>
                <div className='check-box-information-title-home'>
                    <div className='information-title-required-mode'>
                        <div>Home for Sale or Rent</div>
                        <div className='select-title-required'>*</div>
                    </div>
                </div>
                <div>
                    <div className='radio-group-pos-home'>
                        {homeFor?.map((item, index) =>
                            <div className="radio-custom" key={index}>
                                <input
                                    type="radio"
                                    value={item.id}
                                    checked={+selectedValue === +item.id}
                                    onChange={handleRadioChange}
                                    style={{ color: "red" }}
                                />
                                {item.name}
                            </div>)}
                    </div>
                </div>

            </div>
            <div className='general-information-container'>
                <div className='general-information-position'>
                    <div className='general-information-couple'>
                        <Controller
                            name="propertyType" // Unique identifier for the input
                            control={control}
                            defaultValue="" // Initial value of the input
                            render={({ field: { onChange, value } }) => (
                                <MSelect title="Property Type" value={value} onChange={onChange} errorMessage={errors.propertyType?.message} items={propertyType} disabled={disabled} required={true} />
                            )}
                        />
                        <Controller
                            name="location" // Unique identifier for the input
                            control={control}
                            defaultValue="" // Initial value of the input
                            render={({ field: { onChange, value } }) => (
                                <MSelect title="location" value={value} onChange={onChange} errorMessage={errors.location?.message} items={location} required={true} disabled={true} />
                            )}
                        />
                        <Controller
                            name="price"
                            control={control}
                            render={({ field }) => (
                                <div style={{ marginTop: 1 }}>
                                    <MPayInput {...field} errorMessage={errors.price?.message} currency="$" title={'Price'} items={priceType} setSelectedPriceType={setSelectedPriceType} disabled={disabled} />
                                </div>
                            )}
                        />
                    </div>
                    <div className='general-information-couple'>
                        <Controller
                            name="postTitle"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <MInput {...field} required={true} errorMessage={errors.postTitle?.message} title={'Post Title'} />
                                </>
                            )}
                        />

                        <Controller
                            name="adress"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <MInput {...field} required={true} errorMessage={errors.adress?.message} title={'Adress'} />
                                </>
                            )}
                        />
                    </div>
                </div>

            </div>
            <div className='general-information-container'>
                <div className='general-information-title'> Detailed Information</div>
                <div className='general-information-position'>
                    <div className='general-information-couple'>
                        <Controller
                            name="bedrooms"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <MInput required={true} {...field} errorMessage={errors.bedrooms?.message} title={'Bedrooms'} />
                                </>
                            )}
                        />
                        <Controller
                            name="squareFeet"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <MInput required={true} {...field} errorMessage={errors.squareFeet?.message} title={'Property Square Feet'} />
                                </>
                            )}
                        />
                    </div>
                    <div className='general-information-couple'>
                        <Controller
                            name="bathroooms"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <MInput required={true} {...field} errorMessage={errors.bathroooms?.message} title={'Bathroooms'} />
                                </>
                            )}
                        />
                        <Controller
                            name="laundryType" // Unique identifier for the input
                            control={control}
                            defaultValue="" // Initial value of the input
                            render={({ field: { onChange, value } }) => (
                                <MSelect title="Laundry Type" value={value} onChange={onChange} errorMessage={errors.laundryType?.message} items={laundryType} disabled={disabled} />
                            )}
                        />
                    </div>
                </div>

            </div>

            <div className='general-information-container'>
                <div className='general-information-title'> Exterior</div>
                <div className='general-information-position'>
                    <div className='general-information-couple'>
                        <Controller
                            name="parkingType" // Unique identifier for the input
                            control={control}
                            defaultValue="" // Initial value of the input
                            render={({ field: { onChange, value } }) => (
                                <MSelect title={'Parking Type'} value={value} onChange={onChange} errorMessage={errors.parkingType?.message} items={parkingType} disabled={disabled} />
                            )}
                        />
                        <Controller
                            name="heatingType" // Unique identifier for the input
                            control={control}
                            defaultValue="" // Initial value of the input
                            render={({ field: { onChange, value } }) => (
                                <MSelect title={'Heating Type'} value={value} onChange={onChange} errorMessage={errors.heatingType?.message} items={heatingType} disabled={disabled} />
                            )}
                        />

                    </div>
                    <div className='general-information-couple'>
                        <Controller
                            name="conditioningType" // Unique identifier for the input
                            control={control}
                            defaultValue="" // Initial value of the input
                            render={({ field: { onChange, value } }) => (
                                <MSelect title={'Air Conditioning Type'} value={value} onChange={onChange} errorMessage={errors.conditioningType?.message} items={airType} disabled={disabled} />
                            )}
                        />
                    </div>
                </div>

            </div>
            <div className='general-information-container'>
                <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                        <div className='mt-30'>
                            <MTextArea  {...field}
                                placeholder={'Describe your business / service / specialist'} title={'Description'}
                                errorMessage={errors.description?.message}
                            />
                        </div>
                    )}
                />

            </div>
            <div className='general-information-container'>
                <PhotoUpload photos={photos} setPhotos={setPhotos} />
            </div>
            <div className='general-information-container'>
                <div className='general-information-title mt-50'> Contact Information</div>
                <div className='general-information-position'>
                    <div className='general-information-couple'>
                        <Controller
                            name="phoneNumber"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <MCoupleInput {...field} errorMessage={errors.phoneNumber?.message} title={'Phone Number'} currency={'+1'} placeholder={'Phone Number'} />
                                </>
                            )}
                        />
                        {profileInform.company_name && <Controller
                            name="socialMedia"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <MCoupleInput {...field} errorMessage={errors.socialMedia?.message} title={'Websites & Social Networks'} placeholder={'Social media link'} icon={true} />
                                </>
                            )}
                        />}
                        <Controller
                            name="emailAddress"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <MInput {...field} errorMessage={errors.emailAddress?.message} title={'E-mail Address'} />
                                </>
                            )}
                        />
                    </div>
                    <div className='general-information-couple mt-3'>
                        <Controller
                            name="secondPhoneNumber"
                            control={control}
                            rules={{
                                required: showNumber ? 'this field is required' : false
                            }}
                            render={({ field }) => (
                                <>
                                    <MAddInput {...field} errorMessage={errors.secondPhoneNumber?.message} title={'Phone Number'} addTitle='Add new phone number' show={showNumber} setShow={setShowNumber} placeholder={'Phone Number'} currency={'+1'} />
                                </>
                            )}
                        />
                        {profileInform.company_name && <Controller
                            name="secondSocialMediaLink"
                            control={control}
                            rules={{
                                required: showLink ? 'this field is required' : false
                            }}
                            render={({ field }) => (
                                <>
                                    <MAddInput {...field} errorMessage={errors.secondSocialMediaLink?.message} title={'Websites & Social Networks'} addTitle='Add new link' placeholder={'Social media link'} show={showLink} setShow={setShowLink} icon={true} />
                                </>
                            )}
                        />}
                    </div>
                </div>

            </div>
            <div className='preview-button'>
                <button type="submit" className="button-pos-prev">Go to Preview {'>'}</button>
            </div>
        </form>
    </div>
}