//import { useNavigate } from "react-router-dom"
import { MContainer } from "../../components/MContainer"
import MPostMode from "../../components/MPostMode"
//import { MSettingsCard } from "../../components/MSettingsCard"
//import { MViewAllCard } from "../../components/MViewAllCard"
import "./index.scss"
//import { ActivePost } from "../../components/MPosts/Active"
import { InReviewPost } from "../../components/MPosts/InReview"
import { RejectedPost } from "../../components/MPosts/Rejected"
import { useSelector } from "react-redux"
import { AprovedPost } from "./posts/approved"

export const Admin = () => {
    // const navigate = useNavigate()
    const activeTab = useSelector(state => state.auth.activePost);
    //const dispatch = useDispatch()

    const renderComponent = () => {
        switch (activeTab) {
            case 0:
                return <InReviewPost />;
            case 1:
                return <AprovedPost />;
            case 2:
                return <RejectedPost />;
            default:
                return null;
        }
    };
    return <MContainer footerShow={false}>
        <div className="my-post-container">
            <div className="post-mode-container">
                <MPostMode
                    title1={'In Review'}
                    title2={'Approved'}
                    title3={'Rejected'}
                    count1={4}
                    count2={4}
                    count3={4}
                />
            </div>
            <div className='settings-card-container-pos'>
                {renderComponent()}
            </div>
        </div>

    </MContainer>
}