import * as React from "react"
const Warning = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="none"
        {...props}
    >
        <path
            fill="#FFA070"
            d="M8 0C3.584 0 0 3.584 0 8s3.584 8 8 8 8-3.584 8-8-3.584-8-8-8Zm0 12c-.44 0-.8-.36-.8-.8V8c0-.44.36-.8.8-.8.44 0 .8.36.8.8v3.2c0 .44-.36.8-.8.8Zm.8-6.4H7.2V4h1.6v1.6Z"
        />
    </svg>
)
export default Warning
