import * as yup from "yup"
//eslint-disable-next-line
export const phoneRegExp = /^(\+1|1)?\s*\-?\s*\(?\d{3}\)?\s*\-?\s*\d{3}\s*\-?\s*\d{4}$/;


export const schemaLogin = yup.object({
    email: yup.string().required('Email field is required').email('Invalid email format').max(50, 'Email must be at most 50 characters'),
    password: yup.string().required('Password field is required').max(20, 'Password must be at most 20 characters'),
});
export const schemaResetPassword = yup.object({
    confirmPassword: yup.string()
        .required('Confirm password field is required')
        .oneOf([yup.ref('password'), null], 'Passwords must match'),
    password: yup.string().required('Password field is required').max(20, 'Password must be at most 20 characters')
})
export const schemaForgot = yup.object({
    email: yup.string().required('Email field is required').email('Invalid email format').max(50, 'Email must be at most 50 characters'),
})
export const schemaIndividualCreateAccount = yup.object({
    email: yup.string().required('Email field is required').email('Invalid email format').max(50, 'Email must be at most 50 characters'),
    password: yup.string().required('Password field is required').max(20, 'Password must be at most 20 characters'),
    password_confirmation: yup.string()
        .required('Confirm password field is required')
        .oneOf([yup.ref('password'), null], 'Passwords must match'),
    first_name: yup.string().required('First name field is required').max(10, 'First name must be at most 10 characters').min(4, ' must be at least 3 characters.'),
    last_name: yup.string().required('Last name field is required').max(10, 'Last name must be at most 10 characters'),
    checkBox: yup.boolean().oneOf([true], 'You must agree to the terms and conditions')
})
export const schemaCompanyCreateAccount = yup.object({
    companyEmail: yup.string().required('Email field is required').email('Invalid email format').max(50, 'Email must be at most 50 characters'),
    companyName: yup.string().required('field is required'),
    license: yup.string().required('field is required'),
    password: yup.string().required('field is required'),
    confirmPassword: yup.string()
        .required('Confirm password field is required')
        .oneOf([yup.ref('password'), null], 'Passwords must match'),
    checkBox: yup.boolean().oneOf([true], 'You must agree to the terms and conditions')
})
export const schemaVehicle = yup.object({
    phoneNumber: yup.string().required('field is required'),
    secondPhoneNumber: yup.string(),
    socialMedia: yup.string().required('field is required'),
    emailAddress: yup.string().required('Email field is required').email('Invalid email format').max(50, 'Email must be at most 50 characters'),
    secondSocialMediaLink: yup.string(),

})
export const getSchema = () => {
    return yup.object().shape({
        phoneNumber: yup.string().required('field is required'),
        secondPhoneNumber: yup.string(),
        socialMedia: yup.string().required('field is required'),
        emailAddress: yup.string().required('Email field is required').email('Invalid email format').max(50, 'Email must be at most 50 characters'),
        secondSocialMediaLink: yup.string(),
    });
};
