import { useNavigate } from "react-router-dom"
import { MContainer } from "../../components/MContainer"
//import MPostMode from "../../components/MPostMode"
import { MSettingsCard } from "../../components/MSettingsCard"
//import { MViewAllCard } from "../../components/MViewAllCard"
import "./index.scss"
import { useDispatch } from "react-redux"
import { ActivePost } from "../../redux/authReducer"

export const FavoritePost = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleTableClick = (path) => {
        navigate(path)
        dispatch(ActivePost(null));
    }
    // const renderComponent = () => {
    //     switch (componentType) {
    //         case '0':
    //             return <Vehicle />;
    //         case '1':
    //             return <Home />;
    //         case '2':
    //             return <Job />;
    //         case '3':
    //             return <Service />;
    //         case '4':
    //             return <Item />;
    //         default:
    //             return null;
    //     }
    // };
    return <MContainer footerShow={true}>
        <div className="my-post-container">
            <div className="my-post-table-container">
                <div className="my-post-in-active-table" onClick={() => handleTableClick('/my-post')}>My Post</div>
                <div className="my-post-active-table" onClick={() => handleTableClick('/favorite-post')}>Favorite Post</div>
                <div className="my-post-in-active-table" onClick={() => handleTableClick('/setting')}> Settings</div>
            </div>
            <div className="post-mode-container">
                {/* <MPostMode
                    title1={'Active Posts'}
                    title2={'InReview'}
                    title3={'Inactive Posts'}
                    title4={'Rejected'}
                    count1={4}
                    count2={4}
                    count3={4}
                    count4={4}
                /> */}
            </div>
            <div className='settings-card-container-pos'>
                <MSettingsCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
                <MSettingsCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
                <MSettingsCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
                <MSettingsCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />

            </div>
        </div>

    </MContainer>
}