import React from "react"
import { useSelector } from "react-redux"
import '../../components/MButton/index.scss'
import "./index.scss"
import { AuthContainer } from "../../components/MAuthContainer"
import MTab from "../../components/MTab"
import { Individual } from "./individual"
import { Company } from "./company"



export const CreateAccount = () => {
    const activeTab = useSelector(state => state.auth.activeTab)
    return (
        <AuthContainer containerMode={true}>
            <div className="field-position-create">
                <div className="individual-fields-container">
                    <div className="greeting-text">Create an Account</div>
                    <div className="text-info">Enter your credentials to access your account</div>
                    <MTab />
                    {!activeTab ? <Individual /> : <Company />}
                </div>
            </div>
        </AuthContainer>

    )
}