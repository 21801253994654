import React, { useRef } from 'react';
import './index.scss';
import FileUpload from '../../assets/icons/fileUpload';
import Warning from '../../assets/icons/warning';
import Folder from '../../assets/icons/folder';
import RecycleBin from '../../assets/icons/recycleBin';

const MFileUpload = ({ selectedFiles, setSelectedFiles, settings = false }) => {
    const truncateText = (text, maxLength) => {
        return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
    };
    const handleButtonClick = (index) => () => {
        // Trigger click event on the file input element
        const fileInputRef = fileInputRefs[index];
        fileInputRef.current.click();
    };

    const handleFileInputChange = (index) => (event) => {
        const files = event.target.files;
        const updatedFiles = [...selectedFiles];
        updatedFiles[index] = files[0];
        setSelectedFiles(updatedFiles);
    };

    const handleRemoveFile = (index) => () => {
        const updatedFiles = [...selectedFiles];
        updatedFiles[index] = null;
        setSelectedFiles(updatedFiles);
    };

    const fileInputRefs = [
        useRef(null),
        useRef(null),
        useRef(null)
    ];

    return (
        <div className={settings ? 'file-container-settings' : 'file-container'}>
            {fileInputRefs.map((ref, index) => (
                <input
                    key={index}
                    type="file"
                    ref={ref}
                    onChange={handleFileInputChange(index)}
                    className='input-file'
                    accept="image/jpeg, image/png, image/jpg, application/pdf"
                />
            ))}
            {selectedFiles.map((file, index) => (
                file && (
                    <div className='selected-file' key={index}>
                        <div className='folder'>
                            <div className='folder-icon'>
                                <Folder />
                            </div>
                            <div className='folder-name'>{truncateText(file.name, 38)}</div>
                        </div>
                        <div className='recycle-bin' onClick={handleRemoveFile(index)}><RecycleBin /></div>
                    </div>
                )
            ))}
            {!selectedFiles[0] && (
                <div className='upload-container' onClick={handleButtonClick(0)}>
                    <FileUpload />
                    <div className='upload-text'>
                        <div className='drag-drop-text'>Drag and drop your license file here or </div>
                        <div className='browse-text'>browse</div>
                    </div>
                </div>
            )}
            {!selectedFiles[0] && !selectedFiles[1] && !selectedFiles[2] && (
                <div className='warning-container'>
                    <div>
                        <Warning />
                    </div>
                    <div className='warning-text'>
                        Once you submit your license document, your company will be officially registered and verified within the system.
                    </div>
                </div>
            )}
            {selectedFiles[0] && (
                <div onClick={selectedFiles[1] ? handleButtonClick(2) : handleButtonClick(1)}
                    className={selectedFiles[0] && selectedFiles[1] && selectedFiles[2] ? 'disable-add-file' : 'add-file'}>+ add new file</div>
            )}
        </div>
    );
};

export default MFileUpload;
