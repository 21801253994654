import React, { forwardRef } from 'react';
import './index.scss';

export const MInput = forwardRef(({ title, errorMessage, onChange, placeholder, value, required }, ref) => {
  return (
    <div className='input-size'>
      <div className='input-title-position'>
        <div> {title} </div>
     {required &&   <div className='input-title-required'>*</div>}
      </div>
      <div className='input-position' >
        <input
          className={`simple-input-container`}
          onChange={onChange}
          placeholder={placeholder}
          ref={ref}
          value={value}
        />
        <div className='message-container-form'>
          <p className='error-message'>{errorMessage}</p>
        </div>
      </div>
    </div>
  );
});
