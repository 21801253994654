import React, { useState } from "react"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { schemaLogin } from "../../utils/validation"
import { useDispatch } from "react-redux"
import '../../components/MButton/index.scss'
import { useNavigate } from "react-router-dom"
import "./index.scss"
import MGoogleButton from "../../components/MGoogleButton"
import MFacebookButton from "../../components/MFacebookButton"
import { AuthContainer } from "../../components/MAuthContainer"
import { MLoader } from "../../components/MLoader"
import { adminAction, isAuthAction, loading, profileInformation } from "../../redux/authReducer"
import { loginUser, profileInfo } from "../../services/user"
import MAuthInput from "../../components/MAuthInput"



export const Login = () => {
    const {
        handleSubmit,
        formState: { errors },
        control
    } = useForm({
        mode: "onChange", resolver: yupResolver(schemaLogin)
    })
    const [errorMessage, setErrorMessage] = useState()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const onSubmit = async (data) => {
        dispatch(loading(true))
        try {
            const body = {
                grant_type: "password",
                client_id: "1",
                client_secret: "aBDFgKqt2nmulPzy2Z9Z3z7zJzh0VQxtbosE0Jwn",
                username: data.email,
                password: data.password,
                scope: '*'

            }
            const response = await loginUser(body)
            localStorage.setItem('@access_token', response.access_token);
            const infoResponse = await profileInfo()
            infoResponse.data.is_admin && dispatch(adminAction(true))
            dispatch(profileInformation(infoResponse.data))
            localStorage.setItem('isAuth', JSON.stringify(true))
            dispatch(isAuthAction(true))
            navigate('/')
        }
        catch (error) {
            setErrorMessage(error.response?.data?.message)
        }
        finally {
            dispatch(loading(false))
        }
    }

    return (
        <AuthContainer>
            <div className="field-position-login">
                <div className="field-container-login">
                    <div className="greeting-text">Hello! Welcome Back</div>
                    <div className="text-info">Enter your credentials to access your account</div>
                    <form onSubmit={handleSubmit(onSubmit)} className="form-container">
                        <Controller
                            name="email"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <MAuthInput {...field} placeholder='E-mail Address' errorMessage={errors.email?.message} title={"E-mail Address"} />
                                </>
                            )}
                        />
                        <div className="password-container">
                            <Controller
                                name="password"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <MAuthInput {...field} isPassword={true} placeholder='Password' errorMessage={errorMessage ? errorMessage : errors.password?.message} forgot={true} title={"Password"} />
                                    </>
                                )}
                            />
                        </div>
                        <div className="login-button-container">
                            <button type="submit" className="create-button">Login</button>
                        </div>

                    </form>
                    <div className="login-loader-position">
                        <MLoader size={50} color={'red'} />
                    </div>
                    <div className="account-text-container">
                        <p className="text-account">Don’t have an account ? </p>
                        <p className="create-text" onClick={() => navigate('/create-account')}>Create</p>
                    </div>
                    <div className="line-with-text">
                        <div className="text-or">or</div>
                    </div>
                    <div>
                    </div>
                    <div className="button-container-login">
                        <MGoogleButton />
                        <MFacebookButton />
                    </div>

                </div>
            </div>
        </AuthContainer>

    )
}