import api from "./api";

export const JobsCategory = () => {
    return api.get('api/categories-from-general?general_category_id=27')
        .then(response => response.data.data)
        .catch(error => {
            console.error('Error profile user:', error);
            throw error;
        });
};
export const jobsFieldsType = (id) => {
    return api.get(`api/subcategories-from-category?category_id=${id}`)
        .then(response => response.data?.data)
        .catch(error => {
            console.error('Error profile user:', error);
            throw error;
        });
};
export const jobFieldsData = (id) => {
    return api.get(`api/get-child-options?parent_id=${id}`)
        .then(response => response.data.data)
        .catch(error => {
            console.error('Error profile user:', error);
            throw error;
        });
};
export const allLocation = (id) => {
    return api.get(`api/locations`)
        .then(response => response.data.data)
        .catch(error => {
            console.error('Error profile user:', error);
            throw error;
        });
};

export const createJob = (data) => {
    return api.post(`api/posts`, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    },)
        .then(response => response.data.data)
        .catch(error => {
            console.error('Error profile user:', error);
            throw error;
        });
};