
import { Controller, useForm } from 'react-hook-form'
import { MPayInput } from '../../MPayInput'
import { MSelect } from '../../MSelect'
import * as yup from "yup"
import { MInput } from '../../MInput'
import { yupResolver } from "@hookform/resolvers/yup"
import './index.scss'
import { useNavigate } from 'react-router-dom'
import MTextArea from '../../MTextArea'
import PhotoUpload from '../../MPhotoUpload'
import { MCoupleInput } from '../../MCoupleInput'
import { MAddInput } from '../../MAddInput'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PreviewDataHandler, Photos } from '../../../redux/createPostReducer'


export const Item = () => {
    const [showNumber, setShowNumber] = useState(false)
    const [showLink, setShowLink] = useState(false)
    const [selectedValue, setSelectedValue] = useState('New');
    const [photos, setPhotos] = useState([]);
    const navigate = useNavigate()
    const previewData = useSelector(state => state.createPost.previewData)
    const photo = useSelector(state => state.createPost.photo)
    const dispatch = useDispatch()
    const handleRadioChange = (event) => {
        setSelectedValue(event.target.value);
    };

    const getSchema = () => {
        return yup.object().shape({
            subcategory: yup.string().required(' field is required'),
            location: yup.string().required(' field is required'),
            post_title: yup.string().required('field is required'),
            price: yup.number(),
            description: yup.string(),
            phoneNumber: yup.string().required('field is required'),
            secondPhoneNumber: showNumber ? yup.string().required('field is required') : yup.string(),
            socialMedia: yup.string().required('field is required'),
            emailAddress: yup.string().required('Email field is required').email('Invalid email format').max(50, 'Email must be at most 50 characters'),
            secondSocialMediaLink: showLink ? yup.string().required('field is required') : yup.string(),
        });
    };
    const {
        handleSubmit,
        formState: { errors },
        control,
        setValue,
    } = useForm({
        mode: "onChange", resolver: yupResolver(getSchema())
    })
    useEffect(() => {
        previewData && Object.keys(previewData).forEach(key => {
            setValue(key, previewData[key]);
        });
        setSelectedValue('New')
        setPhotos(photo)
    }, [setValue, previewData, photo])
    const onSubmit = (data) => {
        dispatch(PreviewDataHandler({ ...data, condition: selectedValue }))
        dispatch(Photos(photos))
        navigate('/preview-item', { state: { path: 'Item' } })
    }

    return <div className='vehicle-container'>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className='general-information-container'>
                <div className='general-information-title-for-service'> General Information</div>
                <div className='general-information-position'>
                    <div className='general-information-couple'>
                        <Controller
                            name="subcategory" // Unique identifier for the input
                            control={control}
                            defaultValue="" // Initial value of the input
                            render={({ field: { onChange, value } }) => (
                                <MSelect title="Subcategory" value={value} onChange={onChange} errorMessage={errors.subcategory?.message} />
                            )}
                        />
                        <Controller
                            name="location" // Unique identifier for the input
                            control={control}
                            defaultValue="" // Initial value of the input
                            render={({ field: { onChange, value } }) => (
                                <MSelect title="Location" value={value} onChange={onChange} errorMessage={errors.location?.message} />
                            )}
                        />

                    </div>
                    <div className='general-information-couple'>
                        <Controller
                            name="post_title"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <MInput {...field} errorMessage={errors.post_title?.message} title={'Post Title'} />
                                </>
                            )}
                        />

                        <Controller
                            name="price"
                            control={control}
                            render={({ field }) => (
                                <div style={{ marginTop: 1 }}>
                                    <MPayInput {...field} errorMessage={errors.price?.message} placeholder="Price or Range" title={'Price'} />
                                </div>
                            )}
                        />
                    </div>
                </div>

            </div>
            <div className='ml-15'>
                <div className='general-information-title-service'> Detailed Information</div>
                <div className='check-box-information-title-service'>
                    <div className='information-title-required-mode-service'>
                        <div>Condition </div>
                        <div className='select-title-required'>*</div>
                    </div>
                </div>
                <div>
                    <div className='radio-group-pos-guide'>
                        <div className="radio-custom">
                            <input
                                type="radio"
                                value="New"
                                checked={selectedValue === 'New'}
                                onChange={handleRadioChange}
                            />
                            New
                        </div>
                        <div className="radio-custom">
                            <input
                                type="radio"
                                value="Used_new"
                                checked={selectedValue === 'Used_new'}
                                onChange={handleRadioChange}
                            />
                            Used (Like new)
                        </div>
                        <div className="radio-custom">
                            <input
                                type="radio"
                                value="Used_good"
                                checked={selectedValue === 'Used_good'}
                                onChange={handleRadioChange}
                            />
                            Used (Good)
                        </div>
                        <div className="radio-custom">
                            <input
                                type="radio"
                                value="Used_fair"
                                checked={selectedValue === 'Used_fair'}
                                onChange={handleRadioChange}
                            />
                            Used (Fair)
                        </div>

                    </div>
                </div>

            </div>
            <div className='general-information-container'>
                <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                        <div className='mt-30'>
                            <MTextArea  {...field}
                                placeholder={'Describe your business / service / specialist'} title={'Description'}
                                errorMessage={errors.description?.message}
                            />
                        </div>
                    )}
                />

            </div>
            <div className='general-information-container'>
                <PhotoUpload photos={photos} setPhotos={setPhotos} />
            </div>
            <div className='general-information-container'>
                <div className='general-information-title'> Interior</div>
                <div className='general-information-position'>
                    <div className='general-information-couple'>
                        <Controller
                            name="phoneNumber"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <MCoupleInput {...field} errorMessage={errors.phoneNumber?.message} title={'Phone Number'} currency={'+1'} placeholder={'Phone Number'} />
                                </>
                            )}
                        />
                        <Controller
                            name="socialMedia"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <MCoupleInput {...field} errorMessage={errors.socialMedia?.message} title={'Websites & Social Networks'} placeholder={'Social media link'} />
                                </>
                            )}
                        />
                        <Controller
                            name="emailAddress"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <MInput {...field} errorMessage={errors.emailAddress?.message} title={'E-mail Address'} />
                                </>
                            )}
                        />
                    </div>
                    <div className='general-information-couple mt-3'>
                        <Controller
                            name="secondPhoneNumber"
                            control={control}
                            rules={{
                                required: showNumber ? 'this field is required' : false
                            }}
                            render={({ field }) => (
                                <>
                                    <MAddInput {...field} errorMessage={errors.secondPhoneNumber?.message} title={'Phone Number'} addTitle='Add new phone number' show={showNumber} setShow={setShowNumber} placeholder={'Phone Number'} currency={'+1'} />
                                </>
                            )}
                        />
                        <Controller
                            name="secondSocialMediaLink"
                            control={control}
                            rules={{
                                required: showLink ? 'this field is required' : false
                            }}
                            render={({ field }) => (
                                <>
                                    <MAddInput {...field} errorMessage={errors.secondSocialMediaLink?.message} title={'Websites & Social Networks'} addTitle='Add new link' placeholder={'Social media link'} show={showLink} setShow={setShowLink} />
                                </>
                            )}
                        />
                    </div>
                </div>

            </div>
            <div className='preview-button'>
                <button type="submit" className="button-pos-prev">Go to Preview {'>'}</button>
            </div>
        </form>
    </div>
}