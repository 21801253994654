import * as React from "react"
const Folder = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={25}
        height={21}
        fill="none"
        {...props}
    >
        <path
            fill="#F56476"
            stroke="#F56476"
            strokeLinecap="round"
            d="M1.76 2.133v4.72h20.689V5.39c0-1.208-.3-2.132-1.566-2.132l-9.382-.001c-.793 0-1.07-.644-1.07-1.124 0-.906-.096-1.133-.746-1.133H2.977c-.974 0-1.217.755-1.217 1.133Z"
        />
        <path
            fill="#fff"
            d="M3.107 5.02a.5.5 0 0 1 .5-.5h16.976a.5.5 0 0 1 .5.5v2.548a.5.5 0 0 1-.5.5H3.607a.5.5 0 0 1-.5-.5V5.02Z"
        />
        <path
            fill="#F56476"
            stroke="#F56476"
            d="M.688 8.327a1.5 1.5 0 0 1 1.494-1.63h19.846a1.5 1.5 0 0 1 1.495 1.63L22.58 19.13a1.5 1.5 0 0 1-1.495 1.37H3.124a1.5 1.5 0 0 1-1.494-1.37L.688 8.327Z"
        />
    </svg>
)
export default Folder
