import React from 'react';
import './index.scss';

const MSuccesModal = ({ show, onClose, title, onConfirm, children,buttonName }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <h3>{title}</h3>
        </div>
        <div className="modal-footer">
          <button className="close-button" onClick={onClose}>
           ok
          </button>
          {/* <button className="close-button" onClick={onConfirm}>
            {buttonName}
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default MSuccesModal;
