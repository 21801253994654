import React, { useState } from "react"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { schemaResetPassword } from "../../utils/validation"
import { useDispatch } from "react-redux"
import '../../components/MButton/index.scss'
//import { useNavigate } from "react-router-dom"
import "./index.scss"
import { AuthContainer } from "../../components/MAuthContainer"
import MAuthInput from "../../components/MAuthInput"
import { MLoader } from "../../components/MLoader"
import MSuccesModal from "../../components/MSuccesModal"
import { loading } from "../../redux/authReducer"
import { resetPassword } from "../../services/user"



export const ResetPassword = () => {
    const [errorMessage, setErrorMessage] = useState()
    const [isSucces, setIsSucces] = useState(false)
    const {
        handleSubmit,
        formState: { errors },
        control
    } = useForm({
        mode: "onChange", resolver: yupResolver(schemaResetPassword)
    })
    // const count = useSelector(state => state.toolkit.count)
    const dispatch = useDispatch()
    //const navigate = useNavigate()
    const onSubmit = async (data) => {
        dispatch(loading(true))
        try {
            await resetPassword(data)
            setIsSucces(true)
        }
        catch (error) {
            setErrorMessage(error.response?.data?.message)

        }
        finally {
            dispatch(loading(false))
        }

    }

    return (
        <AuthContainer>
            <div className="field-position-reset">
                <div className="field-container">
                    <div className="greeting-text">Reset Password</div>
                    <div className="text-info-reset">Your new password must be different from<br />previous used passwords.</div>
                    <form onSubmit={handleSubmit(onSubmit)} className="form-container">
                        <Controller
                            name="password"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <MAuthInput {...field} isPassword={true} placeholder='Password' errorMessage={errors.password?.message} title="Password" />
                                </>
                            )}
                        />
                        <Controller
                            name="confirmPassword"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <MAuthInput {...field} isPassword={true} placeholder='Confirm Password' errorMessage={errorMessage ? errorMessage : errors.password?.message} title="Confirm Password" />
                                </>
                            )}
                        />
                        <MSuccesModal show={isSucces} onClose={() => setIsSucces(!isSucces)} title={'check your email, you have successfully reset your password'} />
                        <div className="login-loader-position">
                            <MLoader size={50} color={'red'} />
                        </div>
                        <button type="submit" className="create-button">Reset Password</button>
                    </form>
                </div>
            </div>
        </AuthContainer >

    )
}