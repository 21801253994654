import Call from "../../assets/call"
import Facebook from "../../assets/icons/facebookFooter"
import Instagram from "../../assets/icons/instagram"
import Letter from "../../assets/icons/letter"
import Twitter from "../../assets/icons/twitter"
import Youtube from "../../assets/icons/youtube"
import "./index.scss"

export const MFooter = () => {
    return <div className="footer-container">
        <div >
            <div className="footer-table-position">
                <div className="footer-colum-pos">
                    <div className="service-container">
                        <div className="service-title">Service</div>
                        <div className="service-item">
                            <div>Items</div>
                            <div>Vehicles</div>
                            <div>Home Sale/Rent</div>
                            <div>Services</div>
                        </div>
                    </div>
                    <div className="market-info-container">
                        <div className="market-info-title">Get in Touch</div>
                        <div className="web-site-container">
                            <div className="market-mail-container">
                                <div><Letter /></div>
                                <div className="footer-touch-info">info@marketplace.com</div>
                            </div>
                            <div className="market-phone-container">
                                <div><Call /></div>
                                <div className="footer-touch-info">+1 386-688-3295</div>
                            </div>
                            <div className="market-site-container">
                                <div><Instagram /></div>
                                <div><Twitter /></div>
                                <div><Youtube /></div>
                                <div><Facebook /></div>
                            </div>
                        </div>

                    </div>
                    <div className="market-company-container">
                        <div className="market-info-title">Company</div>
                        <div className="about-us">About us</div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom-position">
                <div className="footer-line"></div>
                <div className="terms-conditions-pos">
                    <div>Market Place. All rights reserved</div>
                    <div className="terms-conditions-title">
                        <div>Terms and Conditions</div>
                        <div> Privacy Policy</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}