import * as React from "react"
const Read = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={12}
        height={9}
        fill="none"
        {...props}
    >
        <path
            stroke="#F56476"
            strokeLinecap="round"
            strokeWidth={1.2}
            d="m1 4 3.5 3.5L11 1"
        />
    </svg>
)
export default Read
