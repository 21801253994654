import { combineReducers, configureStore } from "@reduxjs/toolkit";
import counterSlice from "./reducer";
import authSlice from "./authReducer";
import createPostSlice from "./createPostReducer"


const rootReducer = combineReducers({
    toolkit: counterSlice,
    auth: authSlice,
    createPost: createPostSlice
})


export const store = configureStore({
    reducer: rootReducer
})