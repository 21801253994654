import Arrow from '../../assets/icons/arrow'
import { MContainer } from '../../components/MContainer'
import { MViewAllCard } from '../../components/MViewAllCard'
import Select from "react-select";
import './index.scss'
import { useState } from 'react';
import { MPriceInput } from '../../components/MPriceInput';
import { useLocation } from 'react-router-dom';

export const ViewAll = () => {
    const [location, setLocation] = useState()
    console.log(location)
    const [service, setService] = useState('');
    const [place, setPlace] = useState('');
    const { state } = useLocation();
    const handleServiceChange = (event) => {
        setService(event.target.value);
    };
    const handlePlaceChange = (event) => {
        setPlace(event.target.value);
    };

    const customStyles = {
        control: (provided) => ({
            ...provided,
            border: "none",
            boxShadow: "none",
            backgroundColor: "#f0f0f0" // Background color of the control
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: "#e0e0e0" // Background color of the dropdown menu
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "#d0d0d0" : state.isFocused ? "#c0c0c0" : "#e0e0e0",
            color: state.isSelected ? "#000" : "#333"
        }),
        container: (provided) => ({
            ...provided,
        })
    };
    const options = [
        { value: 0, label: "0 degrees" },
        { value: 90, label: "90 degrees" },
        { value: 180, label: "180 degrees" },
        { value: 270, label: "270 degrees" }
    ];

    return <MContainer footerShow={true} >
        <div className='view-all-container-position'>

            <div className='view-all-filter-position'>
                <div className='view-all-navigation-button'>
                    <div>Home <Arrow rotation={180} /> </div>
                    <div className='view-all-path-name'>
                        {state?.path}
                    </div>
                </div>
                <div className='view-all-filter-container'>
                    <div className='view-all-filter-title'>Filters</div>
                    <div className='view-select-container'>
                        <Select
                            options={options} onChange={setLocation}
                            components={{ IndicatorSeparator: () => null }}
                            styles={customStyles}
                        />
                    </div>
                    <div className='line'></div>
                    <div className='view-all-filter-title'>Price</div>
                    <div className='price-inputs-container'>
                        <MPriceInput placeholder={'from'} />
                        <div className='price-line'>-</div>
                        <MPriceInput placeholder={'to'} />
                    </div>
                    <div className='line'></div>
                    <div>
                        <div className='view-all-filter-title'>The Service is for</div>
                        <div className='radio-group-pos'>
                            <div className="radio-custom">
                                <input
                                    type="radio"
                                    value="Woman"
                                    checked={service === 'Woman'}
                                    onChange={handleServiceChange}
                                    style={{ color: "red" }}
                                />
                                Woman
                            </div>
                            <div className="radio-custom">
                                <input
                                    type="radio"
                                    value="Men"
                                    checked={service === 'Men'}
                                    onChange={handleServiceChange}
                                />
                                Men
                            </div>
                            <div className="radio-custom">
                                <input
                                    type="radio"
                                    value="Children"
                                    checked={service === 'Children'}
                                    onChange={handleServiceChange}
                                />
                                Children
                            </div>

                        </div>
                    </div>
                    <div className='line'></div>
                    <div>
                        <div className='view-all-filter-title'>Place of Service</div>
                        <div className='radio-group-pos'>
                            <div className="radio-custom">
                                <input
                                    type="radio"
                                    value="At the Client"
                                    checked={place === 'At the Client'}
                                    onChange={handlePlaceChange}
                                    style={{ color: "red" }}
                                />
                                At the Client
                            </div>
                            <div className="radio-custom">
                                <input
                                    type="radio"
                                    value="At the Specialist"
                                    checked={place === 'At the Specialist'}
                                    onChange={handlePlaceChange}
                                />
                                At the Specialist
                            </div>
                            <div className="radio-custom">
                                <input
                                    type="radio"
                                    value="At the Salon"
                                    checked={place === 'At the Salon'}
                                    onChange={handlePlaceChange}
                                />
                                At the Salon
                            </div>

                        </div>
                    </div>
                    <div className='line'></div>
                    <div className='view-all-filter-title'>Categories</div>
                    <div className='view-all-filter-second-title'>Real Estate</div>
                    <div className='view-second-select-container'>
                        <Select
                            options={options} onChange={setLocation}
                            components={{ IndicatorSeparator: () => null }}
                            styles={customStyles}
                        />
                    </div>
                    <div className='view-second-select-container'>
                        <Select
                            options={options} onChange={setLocation}
                            components={{ IndicatorSeparator: () => null }}
                            styles={customStyles}
                        />
                    </div>
                    <div className='view-second-select-container'>
                        <Select
                            options={options} onChange={setLocation}
                            components={{ IndicatorSeparator: () => null }}
                            styles={customStyles}
                        />
                    </div>
                    <div className='view-second-select-container'>
                        <Select
                            options={options} onChange={setLocation}
                            components={{ IndicatorSeparator: () => null }}
                            styles={customStyles}
                        />
                    </div>
                </div>

            </div>

            <div className='view-all-card-container-pos'>
                <MViewAllCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
                <MViewAllCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
                <MViewAllCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
                <MViewAllCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
                <MViewAllCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
                <MViewAllCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
            </div>
        </div>
    </MContainer>
}