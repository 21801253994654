import * as React from "react"
const SecondState = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={42}
        height={42}
        fill="none"
        {...props}
    >
        <g filter="url(#a)">
            <rect width={30} height={30} x={6} y={4} fill="#fff" rx={15} />
        </g>
        <path
            fill="#F56476"
            stroke="#F56476"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M24.029 14C22 14 21 16 21 16s-1-2-3.029-2c-1.648 0-2.954 1.38-2.97 3.025-.035 3.417 2.71 5.847 5.718 7.888a.5.5 0 0 0 .562 0c3.008-2.041 5.753-4.471 5.719-7.888C26.983 15.38 25.677 14 24.029 14Z"
        />
        <defs>
            <filter
                id="a"
                width={42}
                height={42}
                x={0}
                y={0}
                colorInterpolationFilters="sRGB"
                filterUnits="userSpaceOnUse"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    result="hardAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset dy={2} />
                <feGaussianBlur stdDeviation={3} />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0.62412 0 0 0 0 0.62412 0 0 0 0 0.677778 0 0 0 0.3 0" />
                <feBlend
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_865_11423"
                />
                <feBlend
                    in="SourceGraphic"
                    in2="effect1_dropShadow_865_11423"
                    result="shape"
                />
            </filter>
        </defs>
    </svg>
)
export default SecondState
