import DotLoader from "react-spinners/ClipLoader";
import "./index.scss"
import { useSelector } from "react-redux";
const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};


export const MLoader = ({ color, size }) => {
  const loading = useSelector(state => state.auth.loader)
  return <div className="loader-container">
    <DotLoader
      color={color}
      loading={loading}
      css={override}
      size={size}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  </div>
}