import * as React from "react"
const AddMinus = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={23}
        height={23}
        fill="none"
        {...props}
    >
        <rect width={23} height={23} fill="#FEECEE" rx={11.5} />
        <path
            fill="#F56476"
            fillRule="evenodd"
            d="M6.842 11.5a.75.75 0 0 1 .75-.75h7.816a.75.75 0 0 1 0 1.5H7.592a.75.75 0 0 1-.75-.75Z"
            clipRule="evenodd"
        />
    </svg>
)
export default AddMinus
