import * as React from "react"
const RecycleBin = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={34}
        height={34}
        fill="none"
        {...props}
    >
        <rect width={34} height={34} fill="#F3F3F6" rx={6} />
        <path
            fill="#32394A"
            fillOpacity={0.7}
            d="M15.41 11.667h3.18c0-.442-.167-.866-.465-1.179A1.555 1.555 0 0 0 17 10c-.422 0-.827.176-1.125.488a1.707 1.707 0 0 0-.466 1.179Zm-.956 0c0-.708.269-1.386.746-1.886A2.488 2.488 0 0 1 17 9c.675 0 1.323.281 1.8.781.477.5.745 1.178.745 1.886h3.978c.126 0 .248.052.337.146a.513.513 0 0 1 0 .707.466.466 0 0 1-.337.147h-.834l-.774 9.735a2.886 2.886 0 0 1-.864 1.85c-.5.48-1.153.748-1.831.748h-4.44a2.638 2.638 0 0 1-1.83-.748 2.886 2.886 0 0 1-.865-1.85l-.774-9.735h-.834a.466.466 0 0 1-.337-.147.513.513 0 0 1 0-.707.466.466 0 0 1 .337-.146h3.977Zm-1.417 10.652c.036.458.236.885.558 1.197.323.311.746.484 1.185.484h4.44c.439 0 .861-.173 1.184-.484.323-.312.523-.74.56-1.197l.768-9.652h-9.463l.768 9.652ZM15.568 15c.127 0 .248.053.338.146.09.094.14.221.14.354v5.667c0 .132-.05.26-.14.353a.466.466 0 0 1-.338.147.466.466 0 0 1-.337-.147.512.512 0 0 1-.14-.353V15.5c0-.133.05-.26.14-.354a.467.467 0 0 1 .337-.146Zm3.341.5c0-.133-.05-.26-.14-.354a.467.467 0 0 0-.337-.146.467.467 0 0 0-.338.146.513.513 0 0 0-.14.354v5.667c0 .132.05.26.14.353.09.094.211.147.338.147a.466.466 0 0 0 .337-.147c.09-.093.14-.22.14-.353V15.5Z"
        />
    </svg>
)
export default RecycleBin
