import React, { forwardRef } from 'react';
import './index.scss';
import AddPlus from '../../assets/icons/addPlus';
import AddMinus from '../../assets/icons/addMinus';
import SocialMedia from '../../assets/icons/socialmedia';



export const MAddInput = forwardRef(({ currency, title, placeholder, onChange, addTitle, show, setShow, errorMessage, value,icon }, ref) => {
    return (
        <div >
            <div className='add-new-input-container'>
                {show || value ? <div className='add-size'> {/* Fix className typo */}
                    <div className='add-title-position'>
                        <div className='add-title-position'>
                            <div> {title} </div>
                            <div className='add-title-required'>*</div>
                        </div>
                        <div className='add-input-minus' onClick={() => setShow(false)}><AddMinus /></div>
                    </div>

                    <div className='add-input-container'>
                        <div className='add-position' >
                            {icon ? <div className='inupt-icon'><SocialMedia/></div>:
                             <div className='select-currency'>{currency}</div>} 
                            <input className={`add-containers`} ref={ref} placeholder={placeholder} onChange={onChange} value={value} /> {/* Pass ref to input element */}
                        </div>
                        <div className='add-input-err-message-pos'>
                            <div className='error-message'>{errorMessage}</div>
                        </div>
                    </div>

                </div> :
                    <div onClick={() => setShow(true)} className='add-new-input-button'>
                        <div className='add-plus-icon-pos'><AddPlus /></div>
                        <div> {addTitle}</div>
                    </div>}
            </div>
        </div>
    );
})
