import React, { forwardRef, useState } from 'react';
import SelectArrow from '../../assets/icons/selectArrow';
import './index.scss';




export const MPayInput = forwardRef(({ currency, title, setSelectedPriceType, placeholder, onChange, errorMessage, value, required = false, items = [], disabled }, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const toggleDropdown = () => {
    disabled && setIsOpen(!isOpen);
  };

  const handleItemClick = (index, item) => {
    setSelectedItem(item);
    setSelectedPriceType(item?.id)
    setIsOpen(false); // Close the dropdown after selecting an item
  };

  return (<>
    <div className='pay-size'>
      <div className='pay-title-position'>
        <div> {title} </div>
        {required && <div className='pay-title-required'>*</div>}
      </div>
      <div >
        <div className='pay-position' >
          <div className='select-currency'>{currency}</div>
          <input className={`pay-container ${isOpen ? 'active-pay-container' : ''}`} placeholder={placeholder} onChange={onChange} ref={ref} value={value} disabled={!disabled} />
          <div className='pay-arrow-style'>
            <div className="vertical-line-select"></div>
            <div className='right-title-pos'>{selectedItem !== null ? selectedItem?.name : items[0]?.name}</div>
            <div onClick={toggleDropdown}><SelectArrow isOpen={isOpen} /></div>
          </div>
        </div>

      </div>

      {isOpen && (<div className='pay-items-right-container'>
        <div className='selected-items-right'>
          {items?.map((item, index) => (
            <div key={index} className={`pay-item ${selectedItem === index ? 'selected' : ''}`} onClick={() => handleItemClick(index, item)}>
              {item.name}
            </div>
          ))}
        </div>
      </div>
      )}

    </div>
    <div className='pay-err-message-conatiner'>
      <div className='message-container-form-pay-input'>
        <p className='error-message'>{errorMessage}</p>
      </div>
    </div>

  </>
  );
});
