import React, { forwardRef } from 'react';
import './index.scss';

export const MFilterInput = forwardRef(({ title, errorMessage, onChange, placeholder, value }, ref) => {
  return (
    <div className='input-filter-size'>
      <div className='input-position' >
        <input
          className={`simple-filter-input-container`}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          ref={ref}
          value={value}
        />
        <div className='message-container-form'>
          <p className='error-message'>{errorMessage}</p>
        </div>
      </div>
    </div>
  );
});
