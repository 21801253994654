import React from "react"
import "./index.scss"
import { useNavigate } from "react-router-dom";



export const AuthContainer = ({ children, containerMode = false }) => {
    const navigate = useNavigate()
    return (
        <>
            <div className="title" onClick={() => navigate('/')}>marketplace.com</div>
            <div className="login-container">
                {children}
                <div className={"image-container"}>
                </div>

            </div>
        </>
    )
}