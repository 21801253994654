import React  from 'react';
import './index.scss'; // Import CSS file for styling
import { useDispatch, useSelector } from 'react-redux';
import { ActiveTab } from '../../redux/authReducer';

const MTab = () => {
    const activeTab = useSelector(state => state.auth.activeTab)
    const dispatch = useDispatch()
    // const [activeTab, setActiveTab] = useState(0); // State to keep track of active tab

    // Function to handle tab changing
    const changeTab = (index) => {
        // setActiveTab(index);
        dispatch(ActiveTab(!activeTab))
    };

    return (
        <div className="tab-container">
            <div className={`tab ${!activeTab ? 'active' : ''}`} onClick={() => changeTab()}>
                <span className={`text ${!activeTab ? 'active' : ''}`}>Individual</span>
            </div>
            <div className={`tab ${activeTab ? 'active' : ''}`} onClick={() => changeTab()}>
                <span className={`text ${activeTab ? 'active' : ''}`}>Company</span>
            </div>
        </div>
    );
}

export default MTab;
