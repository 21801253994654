import * as React from "react"
const Website = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={19}
        fill="none"
        {...props}
    >
        <path
            stroke="#F56476"
            strokeLinecap="round"
            d="M9 .95v17.1M16.941 6.8H.9M16.941 12.2H.9M9 .95c-5.4 4.5-6.3 11.7 0 17.1M9 .95c5.4 4.5 6.3 11.7 0 17.1"
        />
        <circle cx={9} cy={9.5} r={8.5} stroke="#F56476" />
    </svg>
)
export default Website
