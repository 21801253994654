import * as React from "react";

const Arrow = ({ rotation = 0, ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={11}
        height={11}
        fill="none"
        viewBox="0 0 11 11"
        {...props}
    >
        <g transform={`rotate(${rotation} 5.5 5.5)`}>
            <path
                stroke="#32394A"
                strokeLinecap="round"
                strokeOpacity={0.7}
                strokeWidth={1.5}
                d="M6.5 1 2 5.5 6.5 10"
            />
        </g>
    </svg>
);

export default Arrow;
