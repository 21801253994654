import React from 'react';
import './index.scss'; // Import CSS file for styling
import { useDispatch, useSelector } from 'react-redux';
import { ActivePost } from '../../redux/authReducer';

const MPostMode = ({ title1, title2, title3, title4, count1, count2, count3, count4 }) => {
    const activeTab = useSelector(state => state.auth.activePost);
    const dispatch = useDispatch();

    // Function to handle tab changing
    const changeTab = (index) => {
        dispatch(ActivePost(index));
    };

    return (
        <div className="read-tab-container">
            <div className={`read-tab ${activeTab === 0 ? 'active' : ''}`} onClick={() => changeTab(0)}>
                <span className='post-mode-menu'>
                    <div className={activeTab === 0 ? 'post-menu-title-active' : 'post-menu-title'}>{title1}</div>
                    {count1 && <div className='post-mode-count'>{count1}</div>}
                </span>
            </div>
            <div className={`read-tab ${activeTab === 1 ? 'active' : ''}`} onClick={() => changeTab(1)}>
                <span className='post-mode-menu'>
                    <div className={activeTab === 1 ? 'post-menu-title-active' : 'post-menu-title'}> {title2}</div>
                    {count2 && <div className='post-mode-count'>{count2}</div>}
                </span>
            </div>
            <div className={`read-tab ${activeTab === 2 ? 'active' : ''}`} onClick={() => changeTab(2)}>
                <span className='post-mode-menu'>
                    <div className={activeTab === 2 ? 'post-menu-title-active' : 'post-menu-title'}> {title3}</div>
                    {count3 && <div className='post-mode-count'>{count3}</div>}
                </span>
            </div>
            {title4 && <div className={`read-tab ${activeTab === 3 ? 'active' : ''}`} onClick={() => changeTab(3)}>
                <span className='post-mode-menu'>
                    <div className={activeTab === 3 ? 'post-menu-title-active' : 'post-menu-title'}> {title4}</div>
                    <div className='post-mode-count'>{count4}</div>
                </span>
            </div>}
        </div>
    );
}

export default MPostMode;
