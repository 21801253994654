import * as React from "react"
const OpenEye = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={23}
        height={21}
        fill="none"
        {...props}
    >
        <path
            stroke="#32394A"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeOpacity={0.5}
            strokeWidth={2}
            d="m2.513 1.5 17.974 18m-3.932-3.65c-1.52.82-3.237 1.4-5.055 1.4-3.965 0-7.452-2.758-9.45-4.749-.528-.525-.792-.787-.96-1.303-.12-.368-.12-1.028 0-1.396.169-.516.433-.78.96-1.304 1.008-1.004 2.393-2.201 4.027-3.143m13.848 8.108c.374-.33.717-.654 1.024-.96l.004-.004c.526-.524.79-.787.957-1.3.12-.368.12-1.029 0-1.396-.169-.516-.431-.779-.96-1.304-1.999-1.99-5.485-4.749-9.45-4.749-.38 0-.754.025-1.123.072m2.61 8.366a2.244 2.244 0 0 1-3.735-1.693c.002-.581.228-1.14.632-1.558"
        />
    </svg>
)
export default OpenEye
