import Arrow from '../../assets/icons/arrow'
import { MContainer } from '../../components/MContainer'
import { MViewAllCard } from '../../components/MViewAllCard'
import Select from "react-select";
import './index.scss'
import { useState } from 'react';
import { MPriceInput } from '../../components/MPriceInput';
import { useLocation } from 'react-router-dom';
import { MFilterInput } from '../../components/MFilterInput';


export const ViewAllHome = () => {
    const [location, setLocation] = useState()
    const [feet, setFeet] = useState()
    const [bedrooms, setBedrooms] = useState()
    const [bathrooms, setBathrooms] = useState()
    console.log(location)
    console.log(feet, "FEET")
    console.log(bedrooms, "bedrooms")
    console.log(bathrooms, "bathrooms")

    const [services, setServices] = useState([]);
    const { state } = useLocation();
    const handleServiceChange = (event) => {
        const { value, checked } = event.target;
        setServices(prevServices =>
            checked ? [...prevServices, value] : prevServices.filter(service => service !== value)
        );
    };

    const customStyles = {
        control: (provided) => ({
            ...provided,
            border: "none",
            boxShadow: "none",
            backgroundColor: "#F3F3F6",
            borderRadius: "8px" // Background color of the control
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: "#e0e0e0" // Background color of the dropdown menu
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "#d0d0d0" : state.isFocused ? "#F3F3F6" : "#F3F3F6",
            color: state.isSelected ? "#000" : "#333"
        }),
        container: (provided) => ({
            ...provided,
        })
    };
    const options = [
        { value: 0, label: "0 degrees" },
        { value: 90, label: "90 degrees" },
        { value: 180, label: "180 degrees" },
        { value: 270, label: "270 degrees" }
    ];

    return <MContainer footerShow={true} >
        <div className='view-all-container-position'>

            <div className='view-all-filter-position'>
                <div className='view-all-navigation-button'>
                    <div>Home <Arrow rotation={180} /> </div>
                    <div className='view-all-path-name'>
                        {state?.path}
                    </div>
                </div>
                <div className='view-all-filter-container'>
                    <div className='view-all-filter-title-home'>Filters</div>
                    <div className='view-select-container'>
                        <Select
                            options={options} onChange={setLocation}
                            components={{ IndicatorSeparator: () => null }}
                            styles={customStyles}
                        />
                    </div>
                    <div className='line'></div>
                    <div className='view-all-filter-title-home'>Price</div>
                    <div className='price-inputs-container'>
                        <MPriceInput placeholder={'from'} />
                        <div className='price-line'>-</div>
                        <MPriceInput placeholder={'to'} />
                    </div>
                    <div className='line'></div>
                    <div className='view-all-filter-title-home'>Property Square Feet</div>
                    <div className='view-second-select-container'>
                        <MFilterInput placeholder="e. g. 200" onChange={setFeet} />
                    </div>
                    <div className='line'></div>
                    <div className='view-all-filter-title-home'>Bedrooms</div>
                    <div className='view-second-select-container'>
                        <MFilterInput placeholder="e. g. 2" onChange={setBedrooms} />
                    </div>
                    <div className='line'></div>
                    <div className='view-all-filter-title-home'>Bathrooms</div>
                    <div className='view-second-select-container'>
                        <MFilterInput placeholder="e. g. 2" onChange={setBathrooms} />
                    </div>
                    <div>
                        <div className='line'></div>
                        <div>
                            <div className='view-all-filter-title-home'> Property Type</div>
                            <div className='checkbox-group-pos'>
                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="Apartment"
                                        checked={services.includes('Apartment')}
                                        onChange={handleServiceChange}
                                        id="Apartment"
                                    />
                                    <label htmlFor="Apartment">Apartment</label>
                                </div>
                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="House"
                                        checked={services.includes('House')}
                                        onChange={handleServiceChange}
                                        id="House"
                                    />
                                    <label htmlFor="House">House</label>
                                </div>
                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="Townhouse"
                                        checked={services.includes('Townhouse')}
                                        onChange={handleServiceChange}
                                        id="Townhouse"
                                    />
                                    <label htmlFor="At the Salon">Townhouse</label>
                                </div>
                            </div>
                        </div>
                        <div className='line'></div>
                        <div>
                            <div className='view-all-filter-title-home'> Laundry Type</div>
                            <div className='checkbox-group-pos'>
                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="In-unit Laundry"
                                        checked={services.includes('In-unit Laundry')}
                                        onChange={handleServiceChange}
                                        id="AIn-unit Laundry"
                                    />
                                    <label htmlFor="AIn-unit Laundry">In-unit Laundry</label>
                                </div>

                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="Laundry in Building"
                                        checked={services.includes('Laundry in Building')}
                                        onChange={handleServiceChange}
                                        id="Laundry in Building"
                                    />
                                    <label htmlFor="Laundry in Building">Laundry in Building</label>
                                </div>
                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="None"
                                        checked={services.includes('None')}
                                        onChange={handleServiceChange}
                                        id="None"
                                    />
                                    <label htmlFor="None">None</label>
                                </div>
                            </div>
                        </div>
                        <div className='line'></div>
                        <div>
                            <div className='view-all-filter-title-home'> Parking Type</div>
                            <div className='checkbox-group-pos'>
                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="Garage Parking"
                                        checked={services.includes('Garage Parking')}
                                        onChange={handleServiceChange}
                                        id="Garage Parking"
                                    />
                                    <label htmlFor="Garage Parking">Garage Parking</label>
                                </div>
                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="Street Parking"
                                        checked={services.includes('Street Parking')}
                                        onChange={handleServiceChange}
                                        id="Street Parking"
                                    />
                                    <label htmlFor="Street Parking">Street Parking</label>
                                </div>
                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="Off-Street Parking"
                                        checked={services.includes('Off-Street Parking')}
                                        onChange={handleServiceChange}
                                        id="Off-Street Parking"
                                    />
                                    <label htmlFor="Off-Street Parking">Off-Street Parking</label>
                                </div>
                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="Parking Available"
                                        checked={services.includes('Parking Available')}
                                        onChange={handleServiceChange}
                                        id="Parking Available"
                                    />
                                    <label htmlFor="Parking Available">Parking Available</label>
                                </div>
                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="None"
                                        checked={services.includes('None')}
                                        onChange={handleServiceChange}
                                        id="None"
                                    />
                                    <label htmlFor="None">None</label>
                                </div>
                            </div>
                        </div>
                        <div className='line'></div>
                        <div>
                            <div className='view-all-filter-title-home'> Air Conditioning Type</div>
                            <div className='checkbox-group-pos'>
                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="Central AC"
                                        checked={services.includes('Central AC')}
                                        onChange={handleServiceChange}
                                        id="Central AC"
                                    />
                                    <label htmlFor="Central AC">Central AC</label>
                                </div>
                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="AC Available"
                                        checked={services.includes('AC Available')}
                                        onChange={handleServiceChange}
                                        id="AC Available"
                                    />
                                    <label htmlFor="AC Available">AC Available</label>
                                </div>
                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="None"
                                        checked={services.includes('None')}
                                        onChange={handleServiceChange}
                                        id="None"
                                    />
                                    <label htmlFor="None">None</label>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='line'></div>
                            <div className='view-all-filter-title-home'>Heating Type</div>
                            <div className='checkbox-group-pos'>
                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="Central Heating"
                                        checked={services.includes('Central Heating')}
                                        onChange={handleServiceChange}
                                        id="Central Heating"
                                    />
                                    <label htmlFor="Car/Truck">Central Heating</label>
                                </div>
                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="Electric Heating"
                                        checked={services.includes('Electric Heating')}
                                        onChange={handleServiceChange}
                                        id="Electric Heating"
                                    />
                                    <label htmlFor="Electric Heating">Electric Heating</label>
                                </div>
                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="Gas Heating"
                                        checked={services.includes('Gas Heating')}
                                        onChange={handleServiceChange}
                                        id="Gas Heating"
                                    />
                                    <label htmlFor="Gas Heating">Gas Heating</label>
                                </div>
                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="Radiator Heating"
                                        checked={services.includes('Radiator Heating')}
                                        onChange={handleServiceChange}
                                        id="Radiator Heating"
                                    />
                                    <label htmlFor="Radiator Heating">Radiator Heating</label>
                                </div>
                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="Heating Available"
                                        checked={services.includes('Heating Available')}
                                        onChange={handleServiceChange}
                                        id="Heating Available"
                                    />
                                    <label htmlFor="Heating Available">Heating Available</label>
                                </div>
                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="None"
                                        checked={services.includes('None')}
                                        onChange={handleServiceChange}
                                        id="None"
                                    />
                                    <label htmlFor="None">None</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className='line'></div>
                    <div className='view-all-filter-title'>Categories</div>
                    <div className='view-all-filter-second-title'>Real Estate</div>
                    <div className='view-second-select-container'>
                        <Select
                            options={options} onChange={setLocation}
                            components={{ IndicatorSeparator: () => null }}
                            styles={customStyles}
                        />
                    </div>
                    <div className='view-second-select-container'>
                        <Select
                            options={options} onChange={setLocation}
                            components={{ IndicatorSeparator: () => null }}
                            styles={customStyles}
                        />
                    </div>
                    <div className='view-second-select-container'>
                        <Select
                            options={options} onChange={setLocation}
                            components={{ IndicatorSeparator: () => null }}
                            styles={customStyles}
                        />
                    </div>
                    <div className='view-second-select-container'>
                        <Select
                            options={options} onChange={setLocation}
                            components={{ IndicatorSeparator: () => null }}
                            styles={customStyles}
                        />
                    </div> */}
                </div>

            </div>

            <div className='view-all-card-container-pos'>
                <MViewAllCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
                <MViewAllCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
                <MViewAllCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
                <MViewAllCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
                <MViewAllCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
                <MViewAllCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
            </div>
        </div>
    </MContainer>
}