import * as React from "react"
const ImgWarning = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        {...props}
    >
        <path
            fill="#F56476"
            d="M10 1.25a8.75 8.75 0 1 1 0 17.5 8.75 8.75 0 0 1 0-17.5Zm0 6.563a.938.938 0 1 0 0-1.872.938.938 0 0 0 0 1.869m.625 1.563a.625.625 0 1 0-1.25 0v3.75a.625.625 0 1 0 1.25 0v-3.75Z"
        />
    </svg>
)
export default ImgWarning
