import * as React from "react"
const FacebookIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={23}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill="#0047FF"
            d="M23 12.029C23 5.665 17.848.5 11.5.5S0 5.665 0 12.029c0 5.58 3.956 10.226 9.2 11.298v-7.84H6.9V12.03h2.3V9.147a4.034 4.034 0 0 1 4.025-4.035H16.1V8.57h-2.3c-.633 0-1.15.519-1.15 1.153v2.306h3.45v3.459h-3.45V23.5C18.457 22.924 23 18.012 23 12.029Z"
        />
    </svg>
)
export default FacebookIcon
