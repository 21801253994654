import './index.scss'
import SecondState from '../../assets/icons/like/secondState';
import FirstState from '../../assets/icons/like/firstState';
import { useState } from 'react';
import Star from '../../assets/icons/star';

export const MCard = ({ imgName, price, region, title }) => {
    const [like, setLike] = useState(false)
    return <div className='card-container' key={imgName}>
        <div className='card-image-container'>
            <div className='card-image-container-position'>
                <div className='card-img-position'>
                    <img src={require(`../../assets/images/${imgName}.png`)} className="image" alt="fireSpot" />
                </div>
                <div className='like-position'>
                    {like ? <div ><SecondState onClick={() => setLike(false)} /></div> : <div onClick={() => setLike(true)}><FirstState /></div>}
                </div>
            </div>
        </div>
        <div className='card-info-container'>
            <div className='card-price'>$ {price}</div>
            <div className='card-title'>{title}</div>
            <div className='card-region'>{region}</div>
            <div className='card-view'>
                <div className='guest-card-star-pos'><Star /></div>
                <div>4,5</div>
                <div>(750)</div>
            </div>
        </div>


    </div>
}