import * as React from "react"
const ClosedEye = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={16}
        fill="none"
        {...props}
    >
        <path
            stroke="#32394A"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeOpacity={0.5}
            strokeWidth={2}
            d="M2.1 10.076C4.193 12.14 7.845 15 12 15s7.807-2.86 9.9-4.924c.554-.545.83-.816 1.006-1.352.126-.382.126-1.065 0-1.447-.177-.534-.453-.807-1.005-1.352C19.806 3.86 16.155 1 12 1 7.846 1 4.193 3.86 2.1 5.925c-.554.545-.83.817-1.006 1.352-.125.382-.125 1.065 0 1.447.177.534.452.807 1.005 1.352Z"
        />
        <path
            stroke="#32394A"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeOpacity={0.5}
            strokeWidth={2}
            d="M9.646 8c0 .62.248 1.213.69 1.65a2.364 2.364 0 0 0 3.328 0 2.323 2.323 0 0 0 0-3.3 2.364 2.364 0 0 0-3.328 0A2.324 2.324 0 0 0 9.646 8Z"
        />
    </svg>
)
export default ClosedEye
