import React from 'react';
import './index.scss'

const MButton = ({ handleClick, className, text }) => {
    return (
        <div>
            <button onClick={handleClick} className={className}>{text}</button>
        </div>
    );
};

export default MButton;
