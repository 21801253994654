import Website from "../../assets/icons/website"
import PhoneNumber from "../../assets/phoneNumber"
import Message from "../../assets/icons/message"
import { MContainer } from "../../components/MContainer"
import { MPersonalInfoTable } from "../../components/MPersonalInfoTable"
import { MPreviewAccountInfo } from "../../components/MPreviewAccountInfo"
import { MSlick } from "../../components/MSlick"
import "./index.scss"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import ImgWarning from "../../assets/icons/imgWarning"



export const PreviewItem = () => {
    const previewData = useSelector(state => state.createPost.previewData)
    const photos = useSelector(state => state.createPost.photo)
    const navigate = useNavigate()
    return (
        <MContainer footerShow={false}>
            <div className="preview-filter-container">
                <div className="preview-step">
                    <div className="preview-title">Preview</div>
                    <div className="post-step">Step 2/2</div>
                </div>
                <div className="preview-position">
                    <div>

                        {photos && photos?.length > 0 ? (
                            photos?.length > 1 ? (
                                <MSlick photos={photos} />
                            ) : (
                                <img
                                    src={photos[0] instanceof Blob ? URL.createObjectURL(photos[0]) : require('../../assets/images/emptyImg.png')}
                                    alt={photos[0]?.name ? `Uploaded ${photos[0].name}` : 'Uploaded'}
                                    className="only-one-img"
                                />
                            )
                        ) : (
                            <img src={require('../../assets/images/emptyImg.png')} alt="No  available" />
                        )}
                        {photos && photos?.length === 0 && <div className="img-warning-message-container">
                            <div className="img-wanrnig-icon"><ImgWarning /></div>
                            <div className="img-wanrnig-title">This is a default picture. If you want to use your own images,
                                please click "Back to Form" <br />and upload the photos you prefer.</div>
                        </div>}
                        <div className="preview-post-info-container">
                            <div className="preview-post-info-price"> $ {previewData && previewData['price']}</div>
                            <div className="preview-post-info-title">{previewData && previewData['postTitle']}</div>
                            <div className="preview-post-info-description">
                                {previewData && previewData['description']}
                            </div>
                        </div>
                        <div className="detailed-information">
                            <div className="preview-detailed-title">Detailed Information</div>
                            <div className="preview-things-information">
                                <div className="preview-things-information-title">Location:</div>
                                <div className="preview-things-info">{previewData && previewData['location']}</div>
                            </div>
                            <div className="preview-things-information">
                                <div className="preview-things-information-title">Condition:</div>
                                <div className="preview-things-info">{previewData && previewData['condition']}</div>
                            </div>
                        </div>
                    </div>


                    <div className="personal-info-position">
                        <div>
                            <MPreviewAccountInfo />
                        </div>
                        <div>
                            <MPersonalInfoTable infoTitle={`+1 ${previewData && previewData['phoneNumber']}`} icon={<PhoneNumber />} />
                            {previewData && previewData['secondPhoneNumber']?.length > 0 && <MPersonalInfoTable infoTitle={`+1 ${previewData['secondPhoneNumber']}`} icon={<PhoneNumber />} />}
                            <MPersonalInfoTable infoTitle={previewData && previewData['emailAddress']} icon={<Message />} />
                            <MPersonalInfoTable infoTitle={previewData && previewData['socialMedia']} icon={<Website />} />
                            {previewData && previewData['secondSocialMediaLink']?.length > 0 && <MPersonalInfoTable infoTitle={previewData['secondSocialMediaLink']} icon={<Website />} />}
                        </div>

                    </div>
                </div>
                <div className='preview-buttons-pos'>
                    <button onClick={() => navigate('/create-post', { state: { path: 'Item' } })} type="submit" className="preview-create-button">{'<'} Back to Form</button>
                    <button type="submit" className="preview-back-to-form-button ">Create</button>
                </div>
            </div>
        </MContainer>
    )
}