import * as React from "react"
const PhoneNumber = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={17}
        height={19}
        fill="none"
        {...props}
    >
        <path
            stroke="#F56476"
            d="M2.657 1.986a2.649 2.649 0 0 0-1.477 2.18c-.167 2.427 1.033 5.221 3.772 8.4M2.657 1.985l12.595 14.577a2.752 2.752 0 0 1-2.472 1.065c-2.484-.256-5.094-1.891-7.828-5.063M2.657 1.986l1.018-.508c.74-.368 1.63-.141 2.084.508m-3.102 0h3.102m-.807 10.58-.378.326m.378-.327-.378.327m0 0c2.77 3.214 5.487 4.958 8.155 5.233L2.433 1.539A3.149 3.149 0 0 0 .681 4.132c-.18 2.62 1.118 5.54 3.893 8.76ZM5.759 1.986l1.192 1.71c.39.56.365 1.31-.072 1.854L5.28 7.54l-.165.206.076.253c.318 1.048.895 2.02 1.714 2.917a8.85 8.85 0 0 0 2.854 2.098l.222.103.217-.112 2.15-1.105-6.59-9.914ZM15.769 13.6l-1.53-1.443a1.66 1.66 0 0 0-1.89-.258l3.42 1.701Zm0 0a1.54 1.54 0 0 1 .165 2.062l-.682.9.517-2.961Z"
        />
    </svg>
)
export default PhoneNumber
