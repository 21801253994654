import * as React from "react"
const FileUpload = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={37}
        height={41}
        fill="none"
        {...props}
    >
        <path
            fill="#22242B"
            fillOpacity={0.1}
            fillRule="evenodd"
            d="M3 0a3 3 0 0 0-3 3v30a3 3 0 0 0 3 3h23a3 3 0 0 0 3-3V12L17 0H3Z"
            clipRule="evenodd"
        />
        <path fill="#22242A" fillOpacity={0.08} d="m29 21-9.5-9H29v9Z" />
        <path fill="#32394A" fillOpacity={0.5} d="M17 0v9a3 3 0 0 0 3 3h9L17 0Z" />
        <circle cx={27} cy={31} r={9.5} fill="#F56476" stroke="#fff" />
        <path
            stroke="#fff"
            strokeLinecap="round"
            d="M22.834 32.25v.5a2 2 0 0 0 2 2h4.333a2 2 0 0 0 2-2v-.5"
        />
        <path
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M27 32.5v-6m0 0-2.084 2m2.084-2 2.083 2"
        />
    </svg>
)
export default FileUpload
