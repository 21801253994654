import React from "react"
import "./index.scss"
import { MHeader } from "../MHeader";
import { MFooter } from "../MFooter/inde";
import { useSelector } from "react-redux";

export const MContainer = ({ children, headerShow = true, footerShow = true ,showDiv}) => {
    const isAuth = useSelector(state => state.auth.isAuth)
    return (
        <div>
            {headerShow && <div className="header-fix-position">
                <MHeader firstPath={'/login'} secondPath={'/create-post'} isAuth={isAuth}  showDiv={showDiv}/></div>}
            <div className="children-position">
                {children}
            </div>
            {footerShow && <div className="footer-fix-position"><MFooter /></div>}
        </div>
    )
}