import { useNavigate } from "react-router-dom"
import { MContainer } from "../../components/MContainer"
import MPostMode from "../../components/MPostMode"
//import { MSettingsCard } from "../../components/MSettingsCard"
//import { MViewAllCard } from "../../components/MViewAllCard"
import "./index.scss"
import { ActivePost } from "../../components/MPosts/Active"
import { InReviewPost } from "../../components/MPosts/InReview"
import { InActivePost } from "../../components/MPosts/InActive"
import { RejectedPost } from "../../components/MPosts/Rejected"
import { useDispatch, useSelector } from "react-redux"

export const MyPost = () => {
    const navigate = useNavigate()
    const activeTab = useSelector(state => state.auth.activePost);
    const dispatch = useDispatch()

    const handleTableClick = (path) => {
        navigate(path)
        dispatch(ActivePost(null));
    }
    const renderComponent = () => {
        switch (activeTab) {
            case 0:
                return <ActivePost />;
            case 1:
                return <InReviewPost />;
            case 2:
                return <InActivePost />;
            case 3:
                return <RejectedPost />;
            default:
                return null;
        }
    };
    return <MContainer footerShow={true}>
        <div className="my-post-container">
            <div className="my-post-table-container">
                <div className="my-post-active-table" onClick={() => handleTableClick('/my-post')}>My Post</div>
                <div className="my-post-in-active-table" onClick={() => handleTableClick('/favorite-post')}>Favorite Post</div>
                <div className="my-post-in-active-table" onClick={() => handleTableClick('/setting')}> Settings</div>

            </div>
            <div className="post-mode-container">
                <MPostMode
                    title1={'Active Posts'}
                    title2={'InReview'}
                    title3={'Inactive Posts'}
                    title4={'Rejected'}
                    count1={4}
                    count2={4}
                    count3={4}
                    count4={4}
                />
            </div>
            <div className='settings-card-container-pos'>
                {renderComponent()}
            </div>
        </div>

    </MContainer>
}