import { useNavigate } from "react-router-dom"
import Search from "../../assets/icons/search"
import './index.scss'

export const MSearch = () => {
    const navigate =useNavigate()
    return <div className="search-container" onClick={()=>navigate('/')}>
        <div className="market-place">mp.com</div>
        <div className="search-input-container">
            <div className="search-icon">
                <Search />
            </div>
            <input className="search-input" placeholder="Search" />
        </div>

    </div>
}