import React from 'react';
import './index.scss';

const PhotoUpload = ({ photos, setPhotos }) => {
    const handleFileChange = (e) => {
        const files = e.target.files;
        if (files.length + photos?.length <= 10) {
            const fileArray = Array.from(files);
            setPhotos(prevPhotos => [...prevPhotos, ...fileArray]);
        } else {
            alert('Maximum 10 photos allowed.');
        }
        e.target.value = ''; // Clear the input value to allow the same file to be selected again
    };

    const handleDelete = (index) => {
        const updatedPhotos = photos?.filter((_, i) => i !== index);
        setPhotos(updatedPhotos);
    };

    return (
        <div className="photo-upload">
            <div className='photo-container'>
                <label className="photo-button" htmlFor="file-upload">+</label>
                <div className='photo-title'>Add photo</div>
            </div>

            <input id="file-upload" type="file" accept="image/*" onChange={handleFileChange} multiple style={{ display: "none" }} />
            <div className="uploaded-photos">
                {photos.length > 0 && photos?.map((photo, index) => (
                    <div key={index} className="photo-wrapper">
                        <img src={URL.createObjectURL(photo)} alt={`Uploaded ${photo.name}`} />
                        <button className="delete-button" onClick={() => handleDelete(index)}>Delete</button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PhotoUpload;
