import { MSettingsCard } from '../../MSettingsCard'
import './index.scss'


export const RejectedPost = () => {
    return <div>
        <MSettingsCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' type='Rejected' />
        <MSettingsCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' type='Rejected' />
        <MSettingsCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' type='Rejected' />
        <MSettingsCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' type='Rejected' />
    </div>
}