import { useNavigate } from "react-router-dom"
import { MContainer } from "../../components/MContainer"
import MPostMode from "../../components/MPostMode"
import "./index.scss"
import { useDispatch, useSelector } from "react-redux"
import { ActivePost } from "../../redux/authReducer"
import { ProfileSetting } from "../../components/MSettings/Profile"
import { NotificationSetting } from "../../components/MSettings/Notifications"
import { AccountSetting } from "../../components/MSettings/Account"

export const Settings = () => {
    const navigate = useNavigate()
    const activeTab = useSelector(state => state.auth.activePost);
    const dispatch = useDispatch()

    const handleTableClick = (path) => {
        navigate(path)
        dispatch(ActivePost(null));
    }
    const renderComponent = () => {
        switch (activeTab) {
            case 0:
                return <ProfileSetting />;
            case 1:
                return <NotificationSetting />;
            case 2:
                return <AccountSetting />;
            default:
                return null;
        }
    };
    return <MContainer footerShow={true} >
        <div className="my-post-container">
            <div className="my-post-table-container">
                <div className="my-post-in-active-table" onClick={() => handleTableClick('/my-post')}>My Post</div>
                <div className="my-post-in-active-table" onClick={() => handleTableClick('/favorite-post')}>Favorite Post</div>
                <div className="my-post-active-table" onClick={() => handleTableClick('/setting')}> Settings</div>
            </div>
            <div className="post-mode-container">
                <MPostMode
                    title1={'Profile'}
                    title2={'Notifications'}
                    title3={'Account'}
                />
            </div>
            <div className='settings-card-container-pos'>
                {renderComponent()}
            </div>
        </div>

    </MContainer>
}