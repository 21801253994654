import React from 'react';
import './index.scss';
import FirstState from '../../assets/icons/checkBox/firstState';
import SecondState from '../../assets/icons/checkBox/secondState';

const MCheckBox = ({ checked, onChange }) => {
    return (
        <div className='check-box-container'>
            {!checked ? <div onClick={() => onChange(true)} className='checked'>
                <FirstState />
            </div> :
                <div onClick={() => onChange(false)} className='agree'>
                    <SecondState />
                </div>}
        </div >
    );
};

export default MCheckBox;
