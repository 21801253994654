import './index.scss'
import SecondState from '../../assets/icons/like/secondState';
import FirstState from '../../assets/icons/like/firstState';
import { useState } from 'react';
import Star from '../../assets/icons/star';
import ViewAllLetter from '../../assets/icons/viewAllLetter';
import ViewAllCall from '../../assets/icons/viewAllCall';

export const MViewAllCard = ({ imgName, price, region, title }) => {
    const [like, setLike] = useState(false)
    return <div className='view-all-card-container'>
        <div>
            <img src={require(`../../assets/images/${imgName}.png`)} className="view-all-image" alt="fireSpot" />
        </div>
        <div className='view-all-card-info-container'>
            <div className='view-all-title-container'>
                <div className='view-all-card-title'>{title}</div>
                <div className='view-all-like-position'>
                    {like ? <div ><SecondState onClick={() => setLike(false)} /></div> : <div onClick={() => setLike(true)}><FirstState /></div>}
                </div>
            </div>
            <div className='view-all-card-region'>{region}</div>
            <div className='view-all-card-view'>
                <div><Star /></div>
                <div>4,5</div>
                <div>(750)</div>
            </div>
            <div className='view-all-contact'>
                <div className='view-all-contact-couple'>
                    <div><ViewAllLetter /></div>
                    <div className='view-all-contact-couple-title'>alice.smith@volo.global</div>
                </div>
                <div className='view-all-contact-couple'>
                    <div><ViewAllCall /></div>
                    <div className='view-all-contact-couple-title'>+1 415 5552671</div>
                </div>
                <div className='view-all-card-price'>$ {price}</div>
            </div>

        </div>
    </div>
}