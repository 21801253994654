import Arrow from '../../assets/icons/arrow'
import { MContainer } from '../../components/MContainer'
import { MViewAllCard } from '../../components/MViewAllCard'
import Select from "react-select";
import './index.scss'
import { useState } from 'react';
import { MPriceInput } from '../../components/MPriceInput';
import { useLocation } from 'react-router-dom';


export const ViewAllService = () => {
    const [location, setLocation] = useState()
    console.log(location)
    const [services, setServices] = useState([]);
    const { state } = useLocation();
    const handleServiceChange = (event) => {
        const { value, checked } = event.target;
        setServices(prevServices =>
            checked ? [...prevServices, value] : prevServices.filter(service => service !== value)
        );
    };
    const customStyles = {
        control: (provided) => ({
            ...provided,
            border: "none",
            boxShadow: "none",
            backgroundColor: "#F3F3F6",
            borderRadius: "8px" // Background color of the control
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: "#e0e0e0" // Background color of the dropdown menu
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "#d0d0d0" : state.isFocused ? "#F3F3F6" : "#F3F3F6",
            color: state.isSelected ? "#000" : "#333"
        }),
        container: (provided) => ({
            ...provided,
        })
    };
    const options = [
        { value: 0, label: "0 degrees" },
        { value: 90, label: "90 degrees" },
        { value: 180, label: "180 degrees" },
        { value: 270, label: "270 degrees" }
    ];

    return <MContainer footerShow={true} >
        <div className='view-all-container-position'>

            <div className='view-all-filter-position'>
                <div className='view-all-navigation-button'>
                    <div>Service <Arrow rotation={180} /> </div>
                    <div className='view-all-path-name'>
                        {state?.path}
                    </div>
                </div>
                <div className='view-all-filter-container'>
                    <div className='view-all-filter-title-home'>Filters</div>
                    <div className='view-select-container'>
                        <Select
                            options={options} onChange={setLocation}
                            components={{ IndicatorSeparator: () => null }}
                            styles={customStyles}
                        />
                    </div>
                    <div className='line'></div>
                    <div className='view-all-filter-title-home'>Price</div>
                    <div className='price-inputs-container'>
                        <MPriceInput placeholder={'from'} />
                        <div className='price-line'>-</div>
                        <MPriceInput placeholder={'to'} />
                    </div>
                    <div>
                        <div className='line'></div>
                        <div>
                            <div className='view-all-filter-title-home'>Place of Study</div>
                            <div className='checkbox-group-pos'>
                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="Online"
                                        checked={services.includes('Online')}
                                        onChange={handleServiceChange}
                                        id="Online"
                                    />
                                    <label htmlFor="Online">Online</label>
                                </div>
                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="At Student"
                                        checked={services.includes('At Student')}
                                        onChange={handleServiceChange}
                                        id="At Student"
                                    />
                                    <label htmlFor="At Student">At Student</label>
                                </div>
                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="At Teacher"
                                        checked={services.includes('At Teacher')}
                                        onChange={handleServiceChange}
                                        id="At Teacher"
                                    />
                                    <label htmlFor="At Teacher">At Teacher</label>
                                </div>
                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="At a Learning Center"
                                        checked={services.includes('At a Learning Center')}
                                        onChange={handleServiceChange}
                                        id="At a Learning Center"
                                    />
                                    <label htmlFor="At a Learning Center">At a Learning Center</label>
                                </div>
                            </div>
                        </div>
                        <div className='line'></div>
                        <div>
                            <div className='view-all-filter-title-home'> Class Format</div>
                            <div className='checkbox-group-pos'>
                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="Individual Classes"
                                        checked={services.includes('Individual Classes')}
                                        onChange={handleServiceChange}
                                        id="Individual Classes"
                                    />
                                    <label htmlFor="Individual Classes">Individual Classes</label>
                                </div>

                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="Group Classes"
                                        checked={services.includes('Group Classes')}
                                        onChange={handleServiceChange}
                                        id="Group Classes"
                                    />
                                    <label htmlFor="Group Classes">Group Classes</label>
                                </div>
                            </div>
                        </div>
                        <div className='line'></div>
                        <div>
                            <div className='view-all-filter-title-home'> Vehicle Type</div>
                            <div className='checkbox-group-pos'>
                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="Cars"
                                        checked={services.includes('Cars')}
                                        onChange={handleServiceChange}
                                        id="Cars"
                                    />
                                    <label htmlFor="Cars">Cars</label>
                                </div>
                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="Motorcycles"
                                        checked={services.includes('Motorcycles')}
                                        onChange={handleServiceChange}
                                        id="Motorcycles"
                                    />
                                    <label htmlFor="Motorcycles">Motorcycles</label>
                                </div>
                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="Trucks"
                                        checked={services.includes('Trucks')}
                                        onChange={handleServiceChange}
                                        id="Trucks"
                                    />
                                    <label htmlFor="Trucks">Trucks</label>
                                </div>
                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="Agricultural Vehicles"
                                        checked={services.includes('Agricultural Vehicles')}
                                        onChange={handleServiceChange}
                                        id="Agricultural Vehicles"
                                    />
                                    <label htmlFor="Agricultural Vehicles">Agricultural Vehicles</label>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='line'></div>
                            <div className='view-all-filter-title-home'> Vehicle is Provided</div>
                            <div className='checkbox-group-pos'>
                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="Yes"
                                        checked={services.includes('Yes')}
                                        onChange={handleServiceChange}
                                        id="Yes"
                                    />
                                    <label htmlFor="Yes">Yes</label>
                                </div>
                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="No"
                                        checked={services.includes('No')}
                                        onChange={handleServiceChange}
                                        id="No"
                                    />
                                    <label htmlFor="No">No</label>
                                </div>
                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="Upon Request"
                                        checked={services.includes('Upon Request')}
                                        onChange={handleServiceChange}
                                        id="Upon Request"
                                    />
                                    <label htmlFor="Upon Request">Upon Request</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='line'></div>
                    <div className='view-all-filter-title'>Categories</div>
                    <div className='view-all-filter-second-title'>Jobs</div>
                    <div className='view-second-select-container'>
                        <Select
                            options={options} onChange={setLocation}
                            components={{ IndicatorSeparator: () => null }}
                            styles={customStyles}
                        />
                    </div>
                    <div className='view-second-select-container'>
                        <Select
                            options={options} onChange={setLocation}
                            components={{ IndicatorSeparator: () => null }}
                            styles={customStyles}
                        />
                    </div>
                    <div className='view-second-select-container'>
                        <Select
                            options={options} onChange={setLocation}
                            components={{ IndicatorSeparator: () => null }}
                            styles={customStyles}
                        />
                    </div>
                    <div className='view-second-select-container'>
                        <Select
                            options={options} onChange={setLocation}
                            components={{ IndicatorSeparator: () => null }}
                            styles={customStyles}
                        />
                    </div>
                    <div className='view-second-select-container'>
                        <Select
                            options={options} onChange={setLocation}
                            components={{ IndicatorSeparator: () => null }}
                            styles={customStyles}
                        />
                    </div>
                    <div className='view-second-select-container'>
                        <Select
                            options={options} onChange={setLocation}
                            components={{ IndicatorSeparator: () => null }}
                            styles={customStyles}
                        />
                    </div>
                    <div className='view-second-select-container'>
                        <Select
                            options={options} onChange={setLocation}
                            components={{ IndicatorSeparator: () => null }}
                            styles={customStyles}
                        />
                    </div>
                    <div className='view-second-select-container'>
                        <Select
                            options={options} onChange={setLocation}
                            components={{ IndicatorSeparator: () => null }}
                            styles={customStyles}
                        />
                    </div>
                    <div className='view-second-select-container'>
                        <Select
                            options={options} onChange={setLocation}
                            components={{ IndicatorSeparator: () => null }}
                            styles={customStyles}
                        />
                    </div>
                    <div className='view-second-select-container'>
                        <Select
                            options={options} onChange={setLocation}
                            components={{ IndicatorSeparator: () => null }}
                            styles={customStyles}
                        />
                    </div>
                    <div className='view-second-select-container'>
                        <Select
                            options={options} onChange={setLocation}
                            components={{ IndicatorSeparator: () => null }}
                            styles={customStyles}
                        />
                    </div>
                    <div className='view-second-select-container'>
                        <Select
                            options={options} onChange={setLocation}
                            components={{ IndicatorSeparator: () => null }}
                            styles={customStyles}
                        />
                    </div>
                    <div className='view-second-select-container'>
                        <Select
                            options={options} onChange={setLocation}
                            components={{ IndicatorSeparator: () => null }}
                            styles={customStyles}
                        />
                    </div>
                </div>

            </div>

            <div className='view-all-card-container-pos'>
                <MViewAllCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
                <MViewAllCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
                <MViewAllCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
                <MViewAllCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
                <MViewAllCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
                <MViewAllCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
            </div>
        </div>
    </MContainer>
}