import React, { useEffect, useState } from "react"
import './index.scss'
import { MSortCard } from "../../components/MSortCard"
import { MCard } from "../../components/MCard"
import RedArrow from "../../assets/icons/redArrow"
import { MContainer } from "../../components/MContainer"
import MButton from "../../components/MButton"
import { useNavigate } from "react-router-dom"
import ImgSrc from '../../assets/images/microphone.png'
import { MScrollHeader } from "../../components/MScrollHeader"

export const Guest = () => {
    const navigate = useNavigate()
    const [showDiv, setShowDiv] = useState(true);
    const fixedComponentHeight = 100

    useEffect(() => {
        const handleScroll = () => {
            // Calculate the scroll position, taking into account the fixed component height
            const scrollPosition = window.scrollY + fixedComponentHeight;
            // You can adjust the threshold as needed
            if (scrollPosition > 200) {
                setShowDiv(false);
            } else {
                setShowDiv(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const Cards = [{
        title: 'Apartments for Sale',
        card: [
            {
                price: "78000",
                title: "Posting Title",
                region: "Alabama"
            },
            {
                price: "78000",
                title: "Posting Title",
                region: "Alabama"
            }, {
                price: "78000",
                title: "Posting Title",
                region: "Alabama"
            },
            {
                price: "78000",
                title: "Posting Title",
                region: "Alabama"
            },
        ]

    },
    {
        title: 'Construction Services',
        card: [
            {
                price: "78000",
                title: "Posting Title",
                region: "Alabama"
            },
            {
                price: "78000",
                title: "Posting Title",
                region: "Alabama"
            }, {
                price: "78000",
                title: "Posting Title",
                region: "Alabama"
            },
            {
                price: "78000",
                title: "Posting Title",
                region: "Alabama"
            }
        ]

    },
    {
        title: 'Cars',
        card: [
            {
                price: "78000",
                title: "Posting Title",
                region: "Alabama"
            },
            {
                price: "78000",
                title: "Posting Title",
                region: "Alabama"
            }, {
                price: "78000",
                title: "Posting Title",
                region: "Alabama"
            },
            {
                price: "78000",
                title: "Posting Title",
                region: "Alabama"
            }
        ]

    },
    ]
    const CardsB = [{
        title: 'Apartments for Sale',
        card: [
            {
                price: "78000",
                title: "Posting Title",
                region: "Alabama"
            },
            {
                price: "78000",
                title: "Posting Title",
                region: "Alabama"
            }, {
                price: "78000",
                title: "Posting Title",
                region: "Alabama"
            },
            {
                price: "78000",
                title: "Posting Title",
                region: "Alabama"
            },
        ]

    },
    {
        title: 'Construction Services',
        card: [
            {
                price: "78000",
                title: "Posting Title",
                region: "Alabama"
            },
            {
                price: "78000",
                title: "Posting Title",
                region: "Alabama"
            }, {
                price: "78000",
                title: "Posting Title",
                region: "Alabama"
            },
            {
                price: "78000",
                title: "Posting Title",
                region: "Alabama"
            }
        ]

    },
    ]
    return (
        <MContainer>
            <div className="guest-page-position">
                <div className="guest" >
                    <div >
                        <div className="guest-title">Let’s find the best service for you</div>
                        <div className="sort-cards-position">
                            <MSortCard title={'Vehicle'} count={26} imgName='car' onClick={() => navigate('/view-vehicle')} />
                            <MSortCard title={'Home'} count={26} imgName='home' onClick={() => navigate('/view-home')} />
                            <MSortCard title={'Job'} count={26} imgName='job' onClick={() => navigate('/view-job')} />
                            <MSortCard title={'Services'} count={26} imgName='setting' onClick={() => navigate('/view-service')} />
                            <MSortCard title={'Items'} count={26} imgName='bag' onClick={() => navigate('/view-item')} />
                        </div>

                        <div className={showDiv ? "scroll-header-passive-position" : "scroll-header-active-position"}>
                            <MScrollHeader />
                        </div>
                    </div>
                    <div className="guest-page-container" >
                        <div className="guest-card-container-position">
                            {/* <div className="guest-card-container">
                                <div className="view-all-container">
                                    <div>Apartments for Sale</div>
                                    <div className="view-all-button">
                                        <div>View All</div>
                                        <div className="button-arrow"><RedArrow /></div>
                                    </div>
                                </div>
                                <div className="ad-goes-here">
                                    <div className="guest-card-position">
                                        <MCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
                                        <MCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
                                        <MCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
                                        <MCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
                                    </div>

                                </div>

                            </div> */}
                            {/* <div className="guest-card-container">
                                <div className="view-all-container">
                                    <div>Apartments for Sale</div>
                                    <div className="view-all-button">
                                        <div>View All</div>
                                        <div className="button-arrow"><RedArrow /></div>
                                    </div>
                                </div>
                                <div className="ad-goes-here">
                                    <div className="guest-card-position">
                                        <MCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
                                        <MCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
                                        <MCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
                                        <MCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
                                    </div>

                                </div>

                            </div> */}
                            {/* <div className="guest-card-container">
                                <div className="view-all-container">
                                    <div>Apartments for Sale</div>
                                    <div className="view-all-button">
                                        <div>View All</div>
                                        <div className="button-arrow"><RedArrow /></div>
                                    </div>
                                </div>
                                <div className="ad-goes-here">
                                    <div className="guest-card-position">
                                        <MCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
                                        <MCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
                                        <MCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
                                        <MCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
                                    </div>

                                </div>

                            </div> */}
                            {/* <div className="guest-card-container">
                                <div className="view-all-container">
                                    <div>Apartments for Sale</div>
                                    <div className="view-all-button">
                                        <div>View All</div>
                                        <div className="button-arrow"><RedArrow /></div>
                                    </div>
                                </div>
                                <div className="ad-goes-here">
                                    <div className="guest-card-position">
                                        <MCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
                                        <MCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
                                        <MCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
                                        <MCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
                                    </div>

                                </div>

                            </div> */}
                            {Cards?.map((item, index) => (<div className="guest-card-container" key={index}>
                                <div className="view-all-container">
                                    <div>{item.title}</div>
                                    <div className="view-all-button">
                                        <div onClick={() => navigate('/view-home', { state: { path: item.title } })}>View All</div>
                                        <div className="button-arrow"><RedArrow /></div>
                                    </div>
                                </div>
                                <div className="ad-goes-here">
                                    <div className="guest-card-position">
                                        {item.card?.map((item) => (<MCard imgName='cardImage' price={item.price} title='item.title' region={item.region} />))}
                                    </div>

                                </div>

                            </div>))}
                            <div className="contact-container">
                                <div className="contact-position">
                                    <div className="contact-with-us-title">Share Your Message with the World!</div>
                                    <div className="contact-with-us-info" >Ready to make an impact? Create your ad now and<br />let your voice be heard by our engaged audience!</div>
                                    <MButton text='Contact with Us' className='header-create-button mt-15' handleClick={() => navigate('/')} />
                                </div>
                                <div>
                                    <img src={ImgSrc} className="microphone-img" alt="fireSpot" />
                                </div>
                            </div>
                            {CardsB?.map((item, index) => (<div className="guest-card-container" key={index}>
                                <div className="view-all-container">
                                    <div>{item.title}</div>
                                    <div className="view-all-button">
                                        <div onClick={() => navigate('view-service', { state: { path: item.title } })}>View All</div>
                                        <div className="button-arrow"><RedArrow /></div>
                                    </div>
                                </div>
                                <div className="ad-goes-here">
                                    <div className="guest-card-position">
                                        {item.card?.map((item) => (<MCard imgName='cardImage' price={item.price} title='item.title' region={item.region} />))}
                                    </div>

                                </div>

                            </div>))}

                        </div>
                        <div className="advertising-container">
                            <div className="advertising-card">
                                <div className="advertising-card-title ">Add <br /> Goes<br /> Here</div>
                            </div>
                            <div className="advertising-card">
                                <div className="advertising-card-title ">Add <br /> Goes<br /> Here</div>
                            </div>
                            <div className="advertising-card">
                                <div className="advertising-card-title ">Add <br /> Goes<br /> Here</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </MContainer >
    )
}