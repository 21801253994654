import * as React from "react"
const FavoritePost = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={15}
        height={13}
        fill="none"
        {...props}
    >
        <path
            stroke="#32394A"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M10.781.5C8.583.5 7.5 2.682 7.5 2.682S6.417.5 4.219.5C2.433.5 1.019 2.005 1 3.8c-.037 3.727 2.936 6.378 6.195 8.605a.539.539 0 0 0 .61 0C11.063 10.178 14.037 7.527 14 3.8 13.98 2.005 12.567.5 10.78.5Z"
        />
    </svg>
)
export default FavoritePost
