import './index.scss'

export const MSortCard = ({ title, count, imgName, onClick }) => {
    return <div className='sort-card-container' onClick={onClick}>
        <div className='sort-info-container'>
            <div className='sort-info-title'>{title}</div>
            <div className='sort-info-count'>{count} posts</div>
        </div>
        <div className='sort-icon-position'>
            <img src={require(`../../assets/images/${imgName}.png`)} className="image" alt="fireSpot" />
        </div>
    </div>
}