import Arrow from '../../assets/icons/arrow'
import { MContainer } from '../../components/MContainer'
import { MViewAllCard } from '../../components/MViewAllCard'
import Select from "react-select";
import './index.scss'
import { useState } from 'react';
import { MPriceInput } from '../../components/MPriceInput';
import { useLocation } from 'react-router-dom';

export const ViewAllVehicle = () => {
    const [location, setLocation] = useState()
    console.log(location)
    const [services, setServices] = useState([]);
    const { state } = useLocation();
    const handleServiceChange = (event) => {
        const { value, checked } = event.target;
        setServices(prevServices =>
            checked ? [...prevServices, value] : prevServices.filter(service => service !== value)
        );
    };

    const customStyles = {
        control: (provided) => ({
            ...provided,
            border: "none",
            boxShadow: "none",
            backgroundColor: "#F3F3F6",
            borderRadius: "8px" // Background color of the control
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: "white" // Background color of the dropdown menu
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "#d0d0d0" : state.isFocused ? "#F3F3F6" : "#F3F3F6",
            color: state.isSelected ? "#000" : "#333"
        }),
        container: (provided) => ({
            ...provided,
        })
    };
    const options = [
        { value: 0, label: "0 degrees" },
        { value: 90, label: "90 degrees" },
        { value: 180, label: "180 degrees" },
        { value: 270, label: "270 degrees" }
    ];

    return <MContainer footerShow={true} >
        <div className='view-all-container-position'>

            <div className='view-all-filter-position'>
                <div className='view-all-navigation-button'>
                    <div>Vehicles <Arrow rotation={180} /> </div>
                    <div className='view-all-path-name'>
                        {state?.path}
                    </div>
                </div>
                <div className='view-all-filter-container'>
                    <div className='view-all-filter-title-home'>Filters</div>
                    <div className='view-select-container'>
                        <Select
                            options={options} onChange={setLocation}
                            components={{ IndicatorSeparator: () => null }}
                            styles={customStyles}
                        />
                    </div>
                    <div className='line'></div>
                    <div className='view-all-filter-title-home'>Price</div>
                    <div className='price-inputs-container'>
                        <MPriceInput placeholder={'from'} />
                        <div className='price-line'>-</div>
                        <MPriceInput placeholder={'to'} />
                    </div>
                    <div className='line'></div>
                    <div>
                        <div>
                            <div className='view-all-filter-title-home'>Vehicle Type</div>
                            <div className='checkbox-group-pos'>
                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="Car/Truck"
                                        checked={services.includes('Car/Truck')}
                                        onChange={handleServiceChange}
                                        id="Car/Truck"
                                    />
                                    <label htmlFor="Car/Truck">Car/Truck</label>
                                </div>
                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="Motorcycles"
                                        checked={services.includes('Motorcycles')}
                                        onChange={handleServiceChange}
                                        id="Motorcycles"
                                    />
                                    <label htmlFor="Motorcycles">Motorcycles</label>
                                </div>
                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="Powersports"
                                        checked={services.includes('Powersports')}
                                        onChange={handleServiceChange}
                                        id="Powersports"
                                    />
                                    <label htmlFor="Powersports">Powersports</label>
                                </div>
                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="Rvs & Campers"
                                        checked={services.includes('Rvs & Campers')}
                                        onChange={handleServiceChange}
                                        id="Rvs & Campers"
                                    />
                                    <label htmlFor="Rvs & Campers">Rvs & Campers</label>
                                </div>
                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="Boats"
                                        checked={services.includes('Boats')}
                                        onChange={handleServiceChange}
                                        id="Boats"
                                    />
                                    <label htmlFor="Boats">Boats</label>
                                </div>
                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="Commercial & Industrial"
                                        checked={services.includes('Commercial & Industrial')}
                                        onChange={handleServiceChange}
                                        id="Commercial & Industrial"
                                    />
                                    <label htmlFor="Commercial & Industrial">Commercial & Industrial</label>
                                </div>
                                <div className="checkbox-custom">
                                    <input
                                        type="checkbox"
                                        value="Trailers"
                                        checked={services.includes('Trailers')}
                                        onChange={handleServiceChange}
                                        id="Trailers"
                                    />
                                    <label htmlFor="Trailers">Trailers</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='line'></div>
                    <div>
                        <div className='view-all-filter-title-home'> Place of Service</div>
                        <div className='checkbox-group-pos'>
                            <div className="checkbox-custom">
                                <input
                                    type="checkbox"
                                    value="At the Client"
                                    checked={services.includes('At the Client')}
                                    onChange={handleServiceChange}
                                    id="At the Client"
                                />
                                <label htmlFor="At the Client">At the Client</label>
                            </div>
                            <div className="checkbox-custom">
                                <input
                                    type="checkbox"
                                    value="At the Specialist"
                                    checked={services.includes('At the Specialist')}
                                    onChange={handleServiceChange}
                                    id="At the Specialist"
                                />
                                <label htmlFor="At the Specialist">At the Specialist</label>
                            </div>
                            <div className="checkbox-custom">
                                <input
                                    type="checkbox"
                                    value="At the Salon"
                                    checked={services.includes('At the Salon')}
                                    onChange={handleServiceChange}
                                    id="At the Salon"
                                />
                                <label htmlFor="At the Salon">At the Salon</label>
                            </div>
                        </div>
                    </div>
                    {/* <div className='line'></div>
                    <div className='view-all-filter-title'>Categories</div>
                    <div className='view-all-filter-second-title'>Real Estate</div>
                    <div className='view-second-select-container'>
                        <Select
                            options={options} onChange={setLocation}
                            components={{ IndicatorSeparator: () => null }}
                            styles={customStyles}
                        />
                    </div>
                    <div className='view-second-select-container'>
                        <Select
                            options={options} onChange={setLocation}
                            components={{ IndicatorSeparator: () => null }}
                            styles={customStyles}
                        />
                    </div>
                    <div className='view-second-select-container'>
                        <Select
                            options={options} onChange={setLocation}
                            components={{ IndicatorSeparator: () => null }}
                            styles={customStyles}
                        />
                    </div>
                    <div className='view-second-select-container'>
                        <Select
                            options={options} onChange={setLocation}
                            components={{ IndicatorSeparator: () => null }}
                            styles={customStyles}
                        />
                    </div> */}
                </div>

            </div>

            <div className='view-all-card-container-pos'>
                <MViewAllCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
                <MViewAllCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
                <MViewAllCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
                <MViewAllCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
                <MViewAllCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
                <MViewAllCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' />
            </div>
        </div>
    </MContainer>
}