import { useNavigate } from 'react-router-dom'
import MButton from '../MButton'
import { MSearch } from '../MSearch'
import './index.scss'
import Push from '../../assets/icons/push'
import { MAvatar } from '../MAvatar'
import { useState } from 'react'
import MReadTab from '../MReadTab'
import Read from '../../assets/icons/read'
import NotificationSettings from '../../assets/icons/notificationSettings'


export const MHeader = ({ firstPath, secondPath, isAuth = false }) => {
    const [show, setShow] = useState(false)

    const navigate = useNavigate()
    return <div className='header-fix-position'>
        <div className='header-position'>
            <div className='search-position'>
                <MSearch />
            </div>
            <div className='buttons-container'>
                {isAuth ? <div className='profile-info-position'>
                    <div className='push-icon-position'>
                        <div onClick={() => setShow(!show)}> <Push /></div>
                        {show && <div className='push-table'>
                            <div className='mark-all-read-container'>
                                <div className='mark-all-notification'>Notifications</div>
                                <div className='mark-all-read'>
                                    <div><Read /></div>
                                    <div className='mark-all-read-title'>Mark all as read</div>
                                </div>
                            </div>
                            <div className='push-read-table'>
                                <div className='push-read-table-pos'> <MReadTab /></div>
                                <div className='push-read-settings'><NotificationSettings /></div>
                            </div>

                        </div>}
                    </div>
                    <div>
                        <MAvatar show={show} setShow={setShow} />
                    </div>
                </div> : <MButton text='Login' className='header-login-button' handleClick={() => navigate(firstPath)} />}
                <MButton text='+ Create a Post' className='header-create-button' handleClick={() => navigate(secondPath)} />
            </div>
        </div>
        <div className='line'></div>
    </div>
}