import React from 'react';
import './index.scss'; // Import CSS file for styling
import { useDispatch, useSelector } from 'react-redux';
import { ActiveTab } from '../../redux/authReducer';

const MReadTab = () => {
    const activeTab = useSelector(state => state.auth.activeTab)
    const dispatch = useDispatch()
    // const [activeTab, setActiveTab] = useState(0); // State to keep track of active tab

    // Function to handle tab changing
    const changeTab = (index) => {
        // setActiveTab(index);
        dispatch(ActiveTab(!activeTab))
    };

    return (
        <div className="read-tab-container">
            <div className={`read-tab ${!activeTab ? 'active' : ''}`} onClick={() => changeTab()}>
                <span className={`text ${!activeTab ? 'active' : ''}`}>All</span>
            </div>
            <div className={`read-tab ${activeTab ? 'active' : ''}`} onClick={() => changeTab()}>
                <span className={`read-text ${activeTab ? 'active' : ''}`}>Unread</span>
            </div>
        </div>
    );
}

export default MReadTab;
