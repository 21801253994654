import * as React from "react"
const Instagram = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    fill="none"
    {...props}
  >
    <path
      fill="#32394A"
      d="M13.785.002c.912-.004 1.824.005 2.736.027l.243.009c.28.01.556.022.89.037 1.33.063 2.237.273 3.033.582.825.317 1.52.747 2.215 1.442a6.133 6.133 0 0 1 1.441 2.214c.309.796.519 1.705.581 3.035.015.332.028.61.038.89l.007.242c.023.911.032 1.823.03 2.735v2.57c.004.912-.006 1.824-.028 2.736l-.008.242c-.01.28-.022.557-.037.89-.063 1.33-.275 2.238-.583 3.034a6.103 6.103 0 0 1-1.441 2.215 6.142 6.142 0 0 1-2.215 1.441c-.796.309-1.704.519-3.033.581-.334.015-.61.028-.89.038l-.243.007c-.912.023-1.824.032-2.736.03h-2.569a98.645 98.645 0 0 1-2.736-.028l-.242-.008c-.297-.01-.594-.023-.89-.037-1.33-.063-2.238-.275-3.035-.583a6.112 6.112 0 0 1-2.214-1.441 6.13 6.13 0 0 1-1.442-2.215c-.309-.796-.519-1.704-.581-3.034a92.967 92.967 0 0 1-.038-.89l-.006-.242a98.817 98.817 0 0 1-.031-2.736v-2.57c-.004-.912.005-1.824.027-2.735l.009-.242c.01-.28.022-.558.037-.89.063-1.332.273-2.238.582-3.035A6.1 6.1 0 0 1 2.1 2.1 6.12 6.12 0 0 1 4.313.657C5.11.347 6.017.137 7.348.075l.89-.037.242-.006A98.807 98.807 0 0 1 11.215 0l2.57.002ZM12.5 6.252a6.25 6.25 0 1 0 0 12.498 6.25 6.25 0 0 0 0-12.499Zm0 2.5a3.75 3.75 0 1 1 .001 7.498 3.75 3.75 0 1 1 0-7.5v.001Zm6.562-4.376a1.562 1.562 0 0 0 0 3.123 1.562 1.562 0 1 0 0-3.125v.002Z"
    />
  </svg>
)
export default Instagram
