import { createSlice } from '@reduxjs/toolkit';


// Define slice
const createPostSlice = createSlice({
    name: 'createPost',
    initialState: {
        previewData: null,
        photo: [],
        employmentType: [],
        Jobshedule: [],
        requiredExperience: []
    },

    reducers: {
        PreviewDataHandler(state, action) {
            state.previewData = action.payload
        },
        Photos(state, action) {
            state.photo = action.payload
        },
        employmentAction(state, action) {
            state.employmentType = action.payload
        },
        JobsheduleAction(state, action) {
            state.Jobshedule = action.payload
        },
        requiredExpAction(state, action) {
            state.requiredExperience = action.payload
        },
    },
});

// Export actions
export const { PreviewDataHandler, Photos, employmentAction, JobsheduleAction, requiredExpAction } = createPostSlice.actions;

// Export reducer
export default createPostSlice.reducer;

