
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from './api';
import axios from 'axios';

export const registerUser = (body) => {
    return api.post('auth/register', body)
        .then(response => response.data)
        .catch(error => {
            console.error('Error registering user:', error);
            throw error;
        });
};
export const resetPassword = (body) => {
    return api.post('auth/reset-password', body)
        .then(response => response.data)
        .catch(error => {
            console.error('Error registering user:', error);
            throw error;
        });
};
export const forgotPassword = (body) => {
    return api.post('auth/forgot-password', body)
        .then(response => response.data)
        .catch(error => {
            console.error('Error forgot pass:', error);
            throw error;
        });
};
export const profileInfo = () => {
    return api.get('api/profile')
        .then(response => response.data)
        .catch(error => {
            console.error('Error profile user:', error);
            throw error;
        });
};
export const deactivate = () => {
    return api.get('api/profile')
        .then(response => response.data)
        .catch(error => {
            console.error('Error profile user:', error);
            throw error;
        });
};


export const registerCompany = (body) => {
    return api.post('auth/register-company', body,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        },)
        .then(response => response.data)
        .catch(error => {
            console.error('Error registering user:', error);
            throw error;
        });
};
export const updatedCompanyInfo = (body) => {
    return api.post('api/users/update-company-profile', body,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        },)
        .then(response => response.data)
        .catch(error => {
            console.error('Error update user:', error);
            throw error;
        });
};
export const updatedPersonalInfo = (body) => {
    return api.post('api/users/update-profile', body)
        .then(response => response.data)
        .catch(error => {
            console.error('Error personal user:', error);
            throw error;
        });
};
export const changePassword = (body) => {
    return api.post('api/change-password', body)
        .then(response => response.data)
        .catch(error => {
            console.error('Error registering user:', error);
            throw error;
        });
};
export const loginUser = (body) => {
    return api.post('oauth/token', body)
        .then(response => response.data)
        .catch(error => {
            console.error('Error registering user:', error);
            throw error;
        });
};
export const fetchUserData = createAsyncThunk(
    'user/fetchUserData',
    async () => {
        const response = await axios.get('https://api.example.com/userdata');
        return response.data;
    }
);
