import * as React from "react"
const Effect = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={45}
    height={44}
    fill="none"
    {...props}
  >
    <rect width={45} height={44} fill="#F3F3F6" rx={10} />
    <path
      fill="#32394A"
      d="M23.376 19.807c.147-.413.768-.413.915 0l.86 2.421c.189.532.508 1.016.931 1.413.423.396.94.695 1.507.872l2.581.806c.44.138.44.72 0 .858l-2.582.806a3.903 3.903 0 0 0-1.507.873c-.423.397-.741.881-.93 1.413l-.86 2.42a.455.455 0 0 1-.175.225.503.503 0 0 1-.565 0 .454.454 0 0 1-.175-.225l-.86-2.42a3.584 3.584 0 0 0-.93-1.413 3.9 3.9 0 0 0-1.507-.873l-2.582-.806a.48.48 0 0 1-.24-.164.432.432 0 0 1 0-.53.481.481 0 0 1 .24-.164l2.582-.806a3.903 3.903 0 0 0 1.506-.873c.423-.396.742-.88.931-1.412l.86-2.421Zm-5.15-6.374a.273.273 0 0 1 .105-.134.303.303 0 0 1 .339 0c.05.033.086.08.105.134l.516 1.453c.23.647.772 1.155 1.463 1.37l1.549.485a.29.29 0 0 1 .143.098.26.26 0 0 1 0 .318.29.29 0 0 1-.143.099l-1.55.483a2.34 2.34 0 0 0-.903.524 2.15 2.15 0 0 0-.559.848l-.516 1.452a.274.274 0 0 1-.105.135.302.302 0 0 1-.339 0 .273.273 0 0 1-.105-.135l-.516-1.452a2.15 2.15 0 0 0-.559-.848 2.342 2.342 0 0 0-.904-.524l-1.549-.483a.29.29 0 0 1-.143-.099.26.26 0 0 1 0-.318.29.29 0 0 1 .143-.098l1.55-.484c.34-.107.65-.286.903-.524a2.15 2.15 0 0 0 .559-.847l.516-1.453Zm9.424-1.311a.183.183 0 0 1 .07-.088.202.202 0 0 1 .225 0 .183.183 0 0 1 .07.088l.345.967c.153.433.514.772.976.915l1.031.323c.038.012.071.035.095.066a.174.174 0 0 1 0 .21.193.193 0 0 1-.095.066l-1.032.323a1.56 1.56 0 0 0-.603.35c-.17.158-.297.352-.372.565l-.344.967a.183.183 0 0 1-.071.089.202.202 0 0 1-.224 0 .183.183 0 0 1-.07-.089l-.345-.967a1.434 1.434 0 0 0-.372-.566 1.56 1.56 0 0 0-.604-.35l-1.03-.322a.193.193 0 0 1-.094-.066.174.174 0 0 1 0-.21.193.193 0 0 1 .094-.066l1.032-.323c.461-.143.822-.482.976-.915l.342-.967Z"
    />
  </svg>
)
export default Effect