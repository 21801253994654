import React, { forwardRef } from 'react';
import './index.scss';
import SocialMedia from '../../assets/icons/socialmedia';


export const MCoupleInput = forwardRef(({ currency, title, placeholder, onChange, errorMessage, value,icon }, ref) => {
  return (
    <div className='couple-input-container' >
      <div className='pay-size'>
        <div className='pay-title-position'>
          <div> {title} </div>
          <div className='pay-title-required'>*</div>
        </div>
        <div >
          <div className='pay-position' >
       {icon ? <div className='inupt-icon'><SocialMedia/></div>:
       <div className='select-currency'>{currency}</div>}  
            <input
              className={`pay-container`}
              onChange={onChange}
              placeholder={placeholder}
              ref={ref} // Pass ref to input element
              value={value}
            />
            <div className='message-container-form'>
              <p className='error-message'>{errorMessage}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
})
