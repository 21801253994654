import * as React from "react"
const JobTable = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={23}
        height={19}
        fill="none"
        {...props}
    >
        <path
            stroke="#32394A"
            strokeWidth={1.4}
            d="M2.7 12.387h17.6v4.3a1.3 1.3 0 0 1-1.3 1.3H4a1.3 1.3 0 0 1-1.3-1.3v-4.3Z"
        />
        <rect
            width={19.693}
            height={7.994}
            x={0.7}
            y={-0.7}
            stroke="#32394A"
            strokeWidth={1.4}
            rx={1.3}
            transform="matrix(1 0 0 -1 .954 11.64)"
        />
        <path
            stroke="#32394A"
            strokeWidth={1.4}
            d="M8.398 4.404v-1.05c0-.8.62-2.042 1.861-2.042h2.482c1.24 0 1.861 1.156 1.861 2.041v1.051"
        />
        <path
            fill="#fff"
            stroke="#fff"
            strokeWidth={0.2}
            d="M9.64 13.314v-3.078c0-.615.54-.859.878-.859h1.83c.734 0 1.014.442 1.014.859v3.078c0 .836-.676 1.032-1.014 1.026h-1.805c-.737 0-.91-.684-.903-1.026Z"
        />
        <path
            fill="#32394A"
            d="M10 13.173v-2.481c0-.495.436-.692.708-.692h1.475c.592 0 .817.356.817.692v2.48c0 .675-.544.833-.817.828h-1.455c-.594 0-.733-.551-.728-.827Z"
        />
    </svg>
)
export default JobTable
