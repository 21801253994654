import React, { useCallback, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import "./index.scss"
import { Home } from '../pages/home';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import { Login } from '../pages/login';
import { CreateAccount } from '../pages/createAccount';
import { ForgotPassword } from '../pages/forgotPassword';
import { ResetPassword } from '../pages/resetPassword';
import { Guest } from '../pages/guest';
import { CreatePost } from '../pages/createPost';
import { Preview } from '../pages/preview';
import { ViewAll } from '../pages/viewAllGuest';
import { MyPost } from '../pages/myPost';
import { Settings } from '../pages/Settings';
import { FavoritePost } from '../pages/favoritePost'
import { useDispatch, useSelector } from 'react-redux';
import { adminAction, isAuthAction, profileInformation } from '../redux/authReducer';
import { profileInfo } from '../services/user';
import { PreviewHome } from '../pages/previewHome';
import { Admin } from '../pages/admin';
import { PreviewJob } from '../pages/previewJob';
import { PreviewService } from '../pages/previewService';
import { PreviewItem } from '../pages/previewItem';
import { ViewAllVehicle } from '../pages/viewAllVehicle';
import { ViewAllHome } from '../pages/viewAllHome';
import { ViewAllJob } from '../pages/viewAllJob';
import { ViewAllService } from '../pages/viewAllService';
import { ViewAllItem } from '../pages/viewAllItem';


const Navigation = () => {
    const dispatch = useDispatch();
    const isAuth = useSelector(state => state.auth.isAuth)
    const admin = useSelector(state => state.auth.admin)
    const [status, setStatus] = useState('idl')
    const fetchStoredAuth = useCallback(async () => {
        const storedAuth = localStorage.getItem('isAuth');
        try {
            storedAuth !== null ? dispatch(isAuthAction(JSON.parse(storedAuth))) : dispatch(isAuthAction(JSON.parse(false)))
            const infoResponse = await profileInfo();
            infoResponse.data.id === 41 && dispatch(adminAction(true))
            setStatus('success')
            dispatch(profileInformation(infoResponse.data));
        } catch (error) {
            console.error('Failed to fetch profile info:', error.response?.data?.message);
            //  error.response?.data?.message === "Unauthenticated." && setStatus('success')
            dispatch(isAuthAction(JSON.parse(false)))
            setStatus('success')
        }

    }, [dispatch]);

    useEffect(() => {
        fetchStoredAuth();
    }, [fetchStoredAuth]);

    if (status === 'idl') {
        return <div className="modal-loading"></div>
    }

    return (
        <Router>
            {!admin ? <Routes>
                <Route path="/" element={<PublicRoute element={<Guest />} isLoggedIn={false} />} />
                <Route path="/home" element={<PrivateRoute element={<Home />} isLoggedIn={isAuth} />} />
                <Route path="/login" element={<PublicRoute element={<Login />} isLoggedIn={false} />} />
                <Route path="/view-all" element={<PublicRoute element={<ViewAll />} isLoggedIn={false} />} />
                <Route path="/view-vehicle" element={<PublicRoute element={<ViewAllVehicle />} isLoggedIn={false} />} />
                <Route path="/view-home" element={<PublicRoute element={<ViewAllHome />} isLoggedIn={false} />} />
                <Route path="/view-item" element={<PublicRoute element={<ViewAllItem />} isLoggedIn={false} />} />
                <Route path="/view-service" element={<PublicRoute element={<ViewAllService />} isLoggedIn={false} />} />
                <Route path="/view-job" element={<PublicRoute element={<ViewAllJob />} isLoggedIn={false} />} />
                <Route path="/create-account" element={<PublicRoute element={<CreateAccount />} isLoggedIn={false} />} />
                <Route path="/forgot-password" element={<PublicRoute element={<ForgotPassword />} isLoggedIn={false} />} />
                <Route path="/reset-password" element={<PublicRoute element={<ResetPassword />} isLoggedIn={false} />} />
                <Route path="/create-post" element={<PrivateRoute element={<CreatePost />} isLoggedIn={isAuth} />} />
                <Route path="/preview-vehicle" element={<PrivateRoute element={<Preview />} isLoggedIn={isAuth} />} />
                <Route path="/admin" element={<PrivateRoute element={<Admin />} isLoggedIn={isAuth} />} />
                <Route path="/preview-home" element={<PrivateRoute element={<PreviewHome />} isLoggedIn={isAuth} />} />
                <Route path="/preview-job" element={<PrivateRoute element={<PreviewJob />} isLoggedIn={isAuth} />} />
                <Route path="/preview-service" element={<PrivateRoute element={<PreviewService />} isLoggedIn={isAuth} />} />
                <Route path="/preview-item" element={<PrivateRoute element={<PreviewItem />} isLoggedIn={isAuth} />} />
                <Route path="/my-post" element={<PrivateRoute element={<MyPost />} isLoggedIn={isAuth} />} />
                <Route path="/setting" element={<PrivateRoute element={<Settings />} isLoggedIn={isAuth} />} />
                <Route path="/favorite-post" element={<PrivateRoute element={<FavoritePost />} isLoggedIn={isAuth} />} />
            </Routes>
                : <Routes>
                    <Route path="/" element={<PrivateRoute element={<Admin />} isLoggedIn={isAuth} />} />
                </Routes>}
        </Router>
    );
}

export default Navigation;
