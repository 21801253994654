import React from 'react';
import './index.scss';
import FacebookIcon from '../../assets/icons/facebook';

const MFacebookButton = () => {
    return (
        <div className="button-with-icon ml-15" onClick={() => console.log('hello')}>
            <div className="icon-facebook"><FacebookIcon /></div>
            <div className="text-facebook">Facebook</div>
        </div>
    );
};

export default MFacebookButton;
