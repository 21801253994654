
import { MSettingsCard } from '../../MSettingsCard'
import './index.scss'


export const InReviewPost = () => {
    return <div>
        <MSettingsCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' type='inReview' />
        <MSettingsCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' type='inReview' />
        <MSettingsCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' type='inReview' />
        <MSettingsCard imgName='cardImage' price={78000} title='Posting Title' region='Alabama' type='inReview' />
    </div>
}