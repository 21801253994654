import * as React from "react"
const LogOut = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={17}
        height={15}
        fill="none"
        {...props}
    >
        <mask id="a" fill="#fff">
            <path d="M10.681 2.325c.208-.292.14-.7-.17-.88a7 7 0 1 0 .002 12.11c.31-.18.377-.589.17-.881-.209-.292-.613-.357-.927-.184a5.703 5.703 0 0 1-7.624-2.023A5.7 5.7 0 0 1 9.755 2.51c.314.173.718.109.926-.184Z" />
        </mask>
        <path
            fill="#32394A"
            stroke="#32394A"
            strokeWidth={2.4}
            d="M10.681 2.325c.208-.292.14-.7-.17-.88a7 7 0 1 0 .002 12.11c.31-.18.377-.589.17-.881-.209-.292-.613-.357-.927-.184a5.703 5.703 0 0 1-7.624-2.023A5.7 5.7 0 0 1 9.755 2.51c.314.173.718.109.926-.184Z"
            mask="url(#a)"
        />
        <path
            stroke="#32394A"
            strokeLinecap="round"
            strokeWidth={1.2}
            d="M6 7.5h10m0 0L13.5 5M16 7.5 13.5 10"
        />
    </svg>
)
export default LogOut
