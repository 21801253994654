import * as React from "react"
const Deactivate = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={45}
    height={44}
    fill="none"
    {...props}
  >
    <rect width={45} height={44} fill="#FEECEE" rx={10} />
    <circle cx={22.5} cy={22} r={9.4} stroke="#F56476" strokeWidth={1.2} />
    <path
      stroke="#F56476"
      strokeLinecap="round"
      strokeWidth={1.2}
      d="m26.035 18.464-7.07 7.071M26.035 25.536l-7.07-7.071"
    />
  </svg>
)
export default Deactivate
