import { useState } from 'react';
import './index.scss'
import { useDispatch } from 'react-redux';
import { PreviewDataHandler } from '../../redux/createPostReducer';
export const MTable = ({ text, handleButtonClick, isActive }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();
    const toggleDropdown = () => {
        dispatch(PreviewDataHandler(null))
        //dispatch(Photos({}))
        !isOpen ? handleButtonClick(text) : handleButtonClick(null)
        setIsOpen(!isOpen);
    };

    return <div className={`table-container ${isActive ? 'active-table-container' : ''}`} onClick={toggleDropdown}>
        <div>  {text}</div>
    </div>
}