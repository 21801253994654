import * as React from "react"
const SocialMedia = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      stroke="#32394A"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeOpacity={0.7}
      strokeWidth={1.6}
      d="m6 12.001 6-6m-4-3 .463-.536a5 5 0 1 1 7.071 7.072l-.534.464m-5 5-.397.534a5.068 5.068 0 0 1-7.127 0 4.972 4.972 0 0 1 0-7.07L3 8"
    />
  </svg>
)
export default SocialMedia
